import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import cx from "classnames";
import filesize from "filesize";
import {Button, FormGroup, Icon, Input, Label} from "spectre-react";
import {YMaps, Map, SearchControl, GeolocationControl, Placemark} from 'react-yandex-maps';
import DayPickerInput from "./DayPickerInput";
import {fetchFile, uploadFile} from "../../api";
import {isMobile, FilePropType, basename, dirname, date1to2} from './sharedImport';


export class FileRenderProp extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        file: FilePropType.isRequired,
        onChange: PropTypes.func.isRequired,
        cancelOnUnmount: PropTypes.bool,
        children: PropTypes.func
    };

    static defaultProps = {
        cancelOnUnmount: false
    };

    originalFile = this.props.file;

    state = {
        loading: false,
        loaded: 0,
        total: 1,
        cancel: null
    };

    componentWillUnmount() {
        if (this.props.cancelOnUnmount && this.state.cancel) this.state.cancel.cancel();
    }

    onUploadProgress = (ev) => this.setState({loaded: ev.loaded, total: ev.total});

    startUpload = async (ev) => {
        const data = new FormData(), file = ev.target.files[0], cancel = axios.CancelToken.source();
        data.append('file', file);
        const options = {onUploadProgress: this.onUploadProgress, cancelToken: cancel.token};
        this.setState({loading: true, loaded: 0, total: 1, cancel, originalname: file.name});
        try {
            const data = await uploadFile(data, options);
            this.props.onChange({target: {name: this.props.name, value: data}});
        } catch (error) {
            if (!axios.isCancel(error))
                alert(error.message);
            else
                console.warn('[File] cancelled');
        } finally {
            this.setState({loading: false, cancel: null});
        }
    };

    cancelUpload = () => {
        if (this.state.cancel) this.state.cancel.cancel();
        this.props.onChange({target: {name: this.props.name, value: this.originalFile}});
    };

    render() {
        return this.props.children({startUpload: this.startUpload, cancelUpload: this.cancelUpload})
    }
}


export class File extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        file: FilePropType.isRequired,
        onChange: PropTypes.func.isRequired
    };

    originalFile = this.props.file;

    state = {
        loading: false,
        loaded: 0,
        total: 1,
        cancel: null
    };

    componentWillUnmount() {
        if (this.state.cancel) this.state.cancel.cancel();
    }

    onUploadProgress = (ev) => this.setState({loaded: ev.loaded, total: ev.total});

    startUpload = async (ev) => {
        const data = new FormData(), file = ev.target.files[0], cancel = axios.CancelToken.source();
        data.append('file', file);
        const options = {onUploadProgress: this.onUploadProgress, cancelToken: cancel.token};
        this.setState({loading: true, loaded: 0, total: 1, cancel, originalname: file.name});
        try {
            const data = await uploadFile(data, options);
            this.props.onChange({target: {name: this.props.name, value: data}});
        } catch (error) {
            if (!axios.isCancel(error))
                alert(error.message);
            else
                console.warn('[File] cancelled');
        } finally {
            this.setState({loading: false, cancel: null});
        }
    };

    cancelUpload = () => {
        if (this.state.cancel) this.state.cancel.cancel();
        this.props.onChange({target: {name: this.props.name, value: this.originalFile}});
    };

    removeUploadedFile = this.cancelUpload;

    renderUploadButton(label, className) {
        const name = this.props.name;
        return (
            <FormGroup>
                <input type="file" id={name} name={name} onChange={this.startUpload}/>
                <label htmlFor={name} className={`btn ${className}`}>
                    <Icon icon="upload"/> {label}
                </label>
            </FormGroup>
        );
    }

    renderUploadingFile() {
        const {loaded, total, originalname} = this.state;
        const progress = Math.round(loaded / total * 100);
        return (
            <div className="tile tile-centered file-tile">
                <div className="tile-icon loading"/>
                <div className="tile-content">
                    <div className="tile-title text-bold">{originalname}</div>
                    <div className="tile-subtitle">
                        <progress className="progress" value={progress} max="100"/>
                    </div>
                </div>
                <div className="tile-action">
                    <Button link onClick={this.cancelUpload}>
                        <Icon icon="stop"/>
                    </Button>
                </div>
            </div>
        )
    }

    renderUploadedFile() {
        const {path, originalname, size, mimetype} = this.props.file;
        const filename = basename(path);
        const dir = basename(dirname(path));
        const uploadedAt = date1to2(filename.slice(0, 10));
        const isNewFile = (dir === "tmp");

        return (
            <div className="tile tile-centered file-tile">
                <a href={`/files?path=${dir}/${filename}`} download={originalname} className="tile-icon" onClick={fetchFile}>
                    <Icon icon="download"/>
                </a>
                <div className="tile-content">
                    <div className={cx("tile-title", {'text-bold': isNewFile})}>{originalname}</div>
                    <small className={cx("tile-subtitle text-gray", {'text-bold': isNewFile})}>{filesize(size)} · {mimetype} · {uploadedAt}</small>
                </div>
                <div className="tile-action">
                    {isNewFile
                        ? <Button link onClick={this.removeUploadedFile}>
                            <Icon icon="cross"/>
                        </Button>
                        : this.renderUploadButton("", "btn-link")
                    }
                </div>
            </div>
        )
    }

    render() {
        if (this.state.loading) {
            return this.renderUploadingFile();
        } else {
            if (!this.props.file) {
                const label = this.props.label || this.props.name;
                return this.renderUploadButton(label, "btn-primary");
            }

            return this.renderUploadedFile();
        }
    }
}


export class DateInput extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
    };

    static defaultProps = {
        className: 'form-input',
        disabled: false,
        readOnly: false,
    };

    isMobile = isMobile();

    render() {
        const {id, name, value, onChange, className, placeholder, disabled, readOnly} = this.props;

        if (this.isMobile)
            return <input type="date" id={name} className={className} name={name} disabled={disabled} readOnly={readOnly}
                          value={value} placeholder={placeholder} onChange={onChange}
                          pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" />;
        else {
            const inputProps = {name, id: id || name, className, disabled, readOnly};
            return <DayPickerInput inputProps={inputProps} placeholder={placeholder} onChange={onChange} value={value}/>;
        }
    }
}

export class TimeInput extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        className: PropTypes.string,
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        className: 'form-input'
    };

    static isValidValue(string) {
        return /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(string);
    }

    isMobile = isMobile();

    render() {
        const {id, name, value, onChange, className, placeholder} = this.props;
        const type = this.isMobile ? "time" : "text";

        return <input type={type} id={id || name} className={className} name={name} value={value}
                      placeholder={placeholder} onChange={onChange} pattern="[0-9]{2}:[0-9]{2}"/>;
    }
}


export class ArrayField extends React.Component {
    static EVENT_TYPES = {
        ADD: 'add',
        REMOVE: 'remove',
        CHANGE: 'change'
    };

    static propTypes = {
        name: PropTypes.string.isRequired,
        array: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        buildNewItem: PropTypes.func.isRequired,
        onInsertAfterItemCopy: PropTypes.func,
    };

    static key() {
        return Math.random().toString(16).slice(2);
    }

    static getDerivedStateFromProps(props, state) {
        // for the case when item is added not by clicking on 'add' button, but externally,
        // for example when adding the first item in a 'new' form in a componentDidMount
        if (state.keys.length < props.array.length) {
            const keys = [...state.keys];
            for (let i = keys.length; i < props.array.length; ++i)
                keys.push(ArrayField.key());
            return {keys};
        }
        return null;
    }

    state = {
        keys: this.props.array.map(ArrayField.key)
    }

    shouldComponentUpdate(nextProps) {
        return (nextProps.array !== this.props.array);
    }

    _onChange = (array, type) => {
        const ev = {target: {name: this.props.name, value: array}, type};
        this.props.onChange(ev);
    };

    onChange = (ev) => {
        const name = ev.target.name;
        const index = parseInt(name.slice(name.lastIndexOf('.') + 1), 10);
        const array = this.props.array.slice();
        array[index] = ev.target.value;
        this._onChange(array, ArrayField.EVENT_TYPES.CHANGE);
    };

    onRemove = (ev) => {
        const index = parseInt(ev.currentTarget.dataset.index, 10);
        const array = this.props.array.slice();
        array.splice(index, 1);

        const keys = [...this.state.keys];
        keys.splice(index, 1);
        this.setState({keys});

        this._onChange(array, ArrayField.EVENT_TYPES.REMOVE);
    };

    onInsert = (ev) => {
        const indexBefore = parseInt(ev.currentTarget.dataset.indexBefore, 10);
        let array = [
            ...this.props.array.slice(0, indexBefore),
            this.props.array[indexBefore],
            ...this.props.array.slice(indexBefore)
        ];

        if (this.props.onInsertAfterItemCopy)
            array = this.props.onInsertAfterItemCopy(array, indexBefore);

        const keys = [
            ...this.state.keys.slice(0, indexBefore),
            ArrayField.key(),
            ...this.state.keys.slice(indexBefore),
        ];

        this.setState({keys});
        this._onChange(array, ArrayField.EVENT_TYPES.REMOVE);
    }

    onAdd = () => {
        const array = this.props.array.slice();
        array.push(this.props.buildNewItem());

        const keys = [...this.state.keys];
        keys.push(ArrayField.key());
        this.setState({keys});

        this._onChange(array, ArrayField.EVENT_TYPES.ADD);
    };

    render() {
        // console.log('ArrayField render');
        const props = {
            name: this.props.name,
            array: this.props.array,
            keys: this.state.keys,
            onChange: this.onChange,
            onAdd: this.onAdd,
            onRemove: this.onRemove,
            onInsert: this.onInsert,
        };
        return this.props.children(props);
    }
}

export class ObjectField extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        object: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        dummy: PropTypes.any
    };

    shouldComponentUpdate(nextProps) {
        // dummy field is required for proper rerendering when using inside ArrayField:
        // with the check only for 'object' it wouldn't rerender children when some item in array was removed and
        // therefore 'index' inside children was changed
        //
        // on the other hand, we may not specify dummy, which equals the check only for object - this is convenient
        // when using the component on its own

        return (nextProps.object !== this.props.object || nextProps.dummy !== this.props.dummy)
    }

    onChange = (ev) => {
        const {name, type, checked, value} = ev.target;
        const p = name.lastIndexOf('.');
        this.props.onChange({
            target: {
                name: this.props.name || name.slice(0, p),
                value: {
                    ...this.props.object,
                    [name.slice(p + 1)]: (type === "checkbox" ? checked : value)
                }
            }
        });
    };

    render() {
        // console.log('ObjectField render');
        const props = {onChange: this.onChange, object: this.props.object};
        return this.props.children(props);
    }
}


export class FormLocation extends React.PureComponent {
    static propTypes = {
        location: PropTypes.shape({
            address: PropTypes.string,
            lat: PropTypes.string,
            lon: PropTypes.string
        }),
        onChange: PropTypes.func
    };
    static LAT = 59.93;
    static LON = 30.33;

    state = {message: null};
    notify = (message) => this.setState({message});

    componentDidMount() { this.notify('Загрузка карты...'); }

    _onChange(location) {
        const ev = {target: {name: 'location', value: location}};
        this.props.onChange(ev);
    }

    onChange = (ev) => {
        const name = ev.target.name;
        const field = name.slice(name.lastIndexOf('.') + 1);
        const location = {...this.props.location, [field]: ev.target.value};
        this._onChange(location);
    };

    getFullAddress(Address) {
        const components = Address.Components.map(c => c.name);
        if (Address.postal_code)
            components.unshift(Address.postal_code);
        return components.join(', ');
    }

    onLoad = (api) => {
        this.ymaps = api;
        this.notify(`Карта загружена. Четыре способа: 1) поиск по организациям 2) геолокация 3) клик на карту и/или перемещение фиолетовой точки 4) вбить вручную`);
    };
    onError = (err) => {
        this.notify(`Ошибка при загрузке карты: ${err}`)
    };

    onGeolocate = (ev) => {
        const [lat, lon] = ev.get('position');
        const location = {...this.props.location, lat: lat.toString(), lon: lon.toString()};

        const geoObjects = ev.get('geoObjects');
        const geoObjectsLength = geoObjects.getLength();
        if (geoObjectsLength === 0)
            this.notify('Не найдено ни одного объекта, координаты вставлены в поля, адрес оставлен неизменным.');
        else {
            const geoObject = geoObjects.get(0);
            const Address = geoObject.properties.get('metaDataProperty.GeocoderMetaData.Address');
            const fullAddress = this.getFullAddress(Address);
            this.notify(`Геолокация: в поля вставлены новые координаты и адрес. Полный адрес: ${fullAddress}`);
            location.address = Address.formatted;
        }

        this._onChange(location);
    };

    onResultSelect = (ev) => {
        const result = this.search.getResultsArray()[ev.get('index')];
        const [lat, lon] = result.geometry.getCoordinates();
        const Address = result.properties.get('responseMetaData.Address');
        const location = {
            ...this.props.location,
            lat: lat.toString(),
            lon: lon.toString(),
            address: Address.formatted
        };
        this.notify(`Поиск: в поля вставлены новые координаты и адрес. Полный адрес: ${this.getFullAddress(Address)}`);
        this._onChange(location);
    };

    onDragEnd = () => {
        this.placemark.properties.set('iconCaption', 'Поиск...');
        const coords = this.placemark.geometry.getCoordinates();
        this.ymaps.geocode(coords).then(res => {
            const firstGeoObject = res.geoObjects.get(0);
            this.placemark.properties.set({iconCaption: firstGeoObject.getAddressLine()});

            const Address = firstGeoObject.properties.get('metaDataProperty.GeocoderMetaData.Address');
            const fullAddress = this.getFullAddress(Address);
            this.notify(`Точка: в поля вставлены новые координаты и адрес. Полный адрес: ${fullAddress}`);

            const location = {
                ...this.props.location,
                lat: coords[0].toString(),
                lon: coords[1].toString(),
                address: Address.formatted
            };
            this._onChange(location);
        }).catch(err => {console.error(err); this.notify(`Точка: ошибка (скорее всего, слишком много запросов с IP-адреса)`)});
    };

    onMapClick = (ev) => {
        const coords = ev.get('coords');
        this.placemark.geometry.setCoordinates(coords);
        this.onDragEnd();
    };

    render() {
        const {location} = this.props;
        const lat = location.lat || FormLocation.LAT;
        const lon = location.lon || FormLocation.LON;

        return (
            <fieldset className="mb-6">
                <legend className="h2">Местоположение</legend>

                <em>{this.state.message}</em>
                <YMaps>
                    <Map defaultState={{center: [lat, lon], zoom: 12}} modules={['geocode', 'geoObject.addon.balloon', 'geoObject.addon.hint']}
                         onLoad={this.onLoad} onError={this.onError} onClick={this.onMapClick} width="100%" height="350px">
                        <GeolocationControl options={{float: 'left'}} onLocationChange={this.onGeolocate} />
                        <SearchControl options={{provider: 'yandex#search', placeholderContent: 'Постер-Принт, Модерн-Керамика, ...',}}
                                       onResultSelect={this.onResultSelect} instanceRef={api => this.search = api} />
                        <Placemark defaultGeometry={[lat, lon]} defaultProperties={{iconCaption: 'Меня можно двигать!'}}
                                   defaultOptions={{preset: 'islands#violetDotIconWithCaption', draggable: true, hasBalloon: false}}
                                   onDragEnd={this.onDragEnd} instanceRef={pm => this.placemark = pm} />
                    </Map>
                </YMaps>

                <div className='columns'>
                    <div className='column col-sm-12 col-8'>
                        <FormGroup>
                            <Label form htmlFor="location.address">Адрес</Label>
                            <Input type="text" id="location.address" name="location.address" value={location.address} onChange={this.onChange} placeholder="Пример: Россия, Санкт-Петербург, Железнодорожный пр. 45 лит. Д"/>
                        </FormGroup>
                    </div>

                    <div className='column col-sm-12 col-2'>
                        <FormGroup>
                            <Label form htmlFor="location.lat">Широта</Label>
                            <Input type="text" id="location.lat" name="location.lat" value={location.lat} onChange={this.onChange} />
                        </FormGroup>
                    </div>

                    <div className='column col-sm-12 col-2'>
                        <FormGroup>
                            <Label form htmlFor="location.lon">Долгота</Label>
                            <Input type="text" id="location.lon" name="location.lon" value={location.lon} onChange={this.onChange} />
                        </FormGroup>
                    </div>
                </div>
            </fieldset>
        );
    }
}