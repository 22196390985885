import React from "react";
import PropTypes from "prop-types";
import {Button, Checkbox, Icon, Input, Loading, Select, Toast} from "spectre-react";
import {fetchPayments, hideOrDeletePayment, restorePayment, fetchPaymentsDescriptions} from '../../api';
import {fetchCSV, uploadPaymentsDbf} from "../../api";
import {pouch} from "../../actions";
import {SingleSelect} from "../shared/Select";
import PaymentNewModal from "./PaymentNewModal";
import PaymentEditModal from "./PaymentEditModal";
import SetClientModal from './SetClientModal';
import rubFmt from "../shared/summaFormatter";
import {PAYMENT_STATE, PAYMENT_STATE_LABEL, formatDate1, formatDate2} from "../shared/sharedImport";


const DBF_MIME_TYPES = [
    'application/dbase',
    'application/dbf',
    'application/x-dbase',
    'application/x-dbf',
];

const PAYMENT_COLOR_CLASS = {
    [PAYMENT_STATE.HIDDEN]: 'text-gray',
    [PAYMENT_STATE.ERROR]: 'text-primary',
    [PAYMENT_STATE.NO_LINK]: 'text-error',
    [PAYMENT_STATE.PART_LINK]: 'text-warning',
    [PAYMENT_STATE.FULL_LINK]: 'text-success',
};

const FILE_BUTTON_ID = "payments-dbf-upload";


class PaymentsList extends React.PureComponent {
    state = {
        isLoading: true, error: false,
        payments: null, clients: null,

        descLoadState: 0, descLoadError: undefined,  // 0 = not loaded, 1 = loading, 2 = loaded
        clientsLoadState: 0, clientsLoadError: undefined,  // 0 = not loaded, 1 = loading, 2 = loaded

        filters: {client: null, NUM_DOC: '', DATE_PL: '', SUMMA: '', sortType: 'smart', showNPLAT: false, NPLAT: ''},

        displayedNum: 30,
    };

    SORT_FUNCTIONS = {
        'smart': (a, b) => a.state.localeCompare(b.state),
        'dateAsc': (a, b) => a.DATE_PL - b.DATE_PL,
        'dateDesc': (a, b) => b.DATE_PL - a.DATE_PL,
    };

    fetchPayments = async () =>{
        this.setState({isLoading: true, error: '', payments: null, clients: null, descLoadState: 0, clientsLoadState: 0});

        try {
            const docs = await fetchPayments();

            const payments = docs.map(item => {
                const {_id, numDoc: NUM_DOC, datePl: DATE_PL, summa: SUMMA, clientId, innPl: INN_PL, plName: PL_NAME, isFromDBF, state} = item;

                const payment = {_id, NUM_DOC, SUMMA, clientId, INN_PL, PL_NAME, isFromDBF, state};
                payment.DATE_PL = new Date(DATE_PL);

                return payment;
            });

            this.setState({isLoading: false, payments}, this.fetchClients);
        } catch (error) {
            console.error(error);
            alert(error.message);
            this.setState({isLoading: false, error: error.message})
        }
    };

    async fetchClients() {
        if (this.state.clientsLoadState === 2)
            return;

        this.setState({clientsLoadState: 1});

        try {
            let clients = (await pouch.query('clients/names')).rows;
            clients = clients.map(({key, value}) => [key, {_id: key, label: value, name: value}]); // 'label' is for SingleSelect without itemToString
            clients = new Map(clients);

            const clientIds = new Set();

            const payments = [...this.state.payments];
            for (const payment of payments) {
                if (payment.clientId) {
                    clientIds.add(payment.clientId);
                    payment.client = clients.get(payment.clientId);
                }
            }

            const selectItems = Array.from(clientIds)
                .map(clientId => clients.get(clientId))
                .sort((a, b) => a.label.localeCompare(b.label));
            selectItems.unshift({_id: null, label: "<отсутствует>"});

            this.setState({clientsLoadState: 2, payments, clients: selectItems});
        } catch (error) {
            console.error(error);
            alert(error.message);
            this.setState({clientsLoadState: 0, clientsLoadError: error.message});
        }
    }

    async fetchPaymentsDescriptions() {
        if (this.state.descLoadState === 2)
            return;

        this.setState({descLoadState: 1});

        try {
            const descriptionByPaymentId = await fetchPaymentsDescriptions();

            const payments = [...this.state.payments];
            for (const payment of payments)
                payment.NPLAT = descriptionByPaymentId[payment._id];

            this.setState({descLoadState: 2, payments});
        } catch (error) {
            this.setState({descLoadState: 0, descLoadError: error.message});
        }
    }

    componentDidMount() {
        // [document.body.scrollHeight - window.innerHeight, window.scrollY]
        window.addEventListener('scroll', this.onScroll);

        this.fetchPayments();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        if (!this.state.payments)
            return;

        if (
            document.body.scrollHeight - window.innerHeight - 400 < window.scrollY &&
            this.state.displayedNum < this.state.payments.length
        )
            this.setState({displayedNum: this.state.displayedNum + 30});
    };

    toggleDescriptions = (ev) => {
        const showNPLAT = ev.target.checked;
        const callback = showNPLAT ? this.fetchPaymentsDescriptions : undefined;
        const filters = {...this.state.filters, showNPLAT, NPLAT: ""};
        this.setState({filters}, callback);
    }

    onFilterChange = (ev) => {
        const name = ev.target.name.slice(8);
        const value = ev.target.type === "checkbox" ? ev.target.checked : ev.target.value;

        const filters = {...this.state.filters, [name]: value};

        this.setState({filters, displayedNum: 30});
    };

    filter(payments, filters) {
        if (filters.client)
            payments = payments.filter(
                filters.client._id === null
                    ? payment => !payment.client
                    : payment => (payment.client && payment.client._id === filters.client._id)
            );
        if (filters.NPLAT) {
            const re = new RegExp(filters.NPLAT, 'i');
            payments = payments.filter(item => re.test(item.NPLAT));
        }
        if (filters.NUM_DOC) {
            const re = new RegExp(filters.NUM_DOC, 'i');
            payments = payments.filter(item => re.test(item.NUM_DOC));
        }
        if (filters.DATE_PL) {
            const re = new RegExp(filters.DATE_PL, 'i');
            payments = payments.filter(item => re.test(formatDate2(item.DATE_PL)));
        }
        if (filters.SUMMA) {
            const re = new RegExp(filters.SUMMA, 'i');
            payments = payments.filter(item => re.test(item.SUMMA.toString()));
        }
        if (filters.state)
            payments = payments.filter(item => item.state === filters.state);
        return payments;
    }

    // ------------------------

    selectClient = (ev) => {
        const _id = ev.target.dataset.clientId;
        const client = this.state.clients.find(c => c._id === _id);
        this.onFilterChange({target: {name: "filters.client", value: client}});
    };

    downloadCSV = () => {
        let output = "data:text/csv;charset=utf-8," + "Клиент;№ пп;Дата;Сумма;Статус;Комментарий\n";
        for (const payment of this.state.payments) {
            const {NUM_DOC, DATE_PL, SUMMA, NPLAT, state} = payment;
            const client = payment.client ? payment.client.name : `${payment.INN_PL}\n${payment.PL_NAME}`;
            output += `${client};${NUM_DOC};${formatDate1(DATE_PL)};${SUMMA};${PAYMENT_STATE_LABEL[state]};${NPLAT}\n`;
        }
        fetchCSV(output, `payments-${new Date().toISOString()}.csv`);
    };

    uploadDbf = (ev) => {
        const file = ev.target.files[0];
        if (!file) return;
        if (!DBF_MIME_TYPES.includes(file.type) && file.name.slice(file.name.lastIndexOf('.') + 1) !== 'dbf') {
            alert('Требуется выписка В ФОРМАТЕ DBF!');
            return;
        }

        const data = new FormData();
        data.append('file', file);

        document.getElementById(FILE_BUTTON_ID).value = "";

        uploadPaymentsDbf(data)
            .then(this.fetchPayments)
            .catch(error => alert(error.message));
    };

    hidePayment = (ev) => {
        const msg = "Платеж будет не удален, а спрятан, т.к. в первом случае при следующем импорте dbf он появится снова. " +
            "При этом все привязки к счетам будут удалены.";

        if (window.confirm(msg))
            this.hideOrDeletePayment(ev.currentTarget.dataset.id)
    };

    destroyPayment = (ev) => {
        const msg = "Платеж будет удален, восстановить будет нельзя. Продолжаем?";

        if (window.confirm(msg))
            this.hideOrDeletePayment(ev.currentTarget.dataset.id)
    };

    hideOrDeletePayment = (_id) => {
        hideOrDeletePayment(_id)
            .then(this.fetchPayments)
            .catch(error => alert(error.message));
    };

    restorePayment = (ev) => {
        restorePayment(ev.currentTarget.dataset.id)
            .then(this.fetchPayments)
            .catch(error => alert(error.message));
    };

    // ------------------------

    render() {
        if (this.state.isLoading)
            return <Loading large />;

        if (this.state.error)
            return <Toast error>{this.state.error}</Toast>;

        const {filters, clients, displayedNum} = this.state;
        const payments = this.filter(this.state.payments.slice(), filters)
            .sort(this.SORT_FUNCTIONS[filters.sortType])
            .slice(0, displayedNum);

        const isLoadingDescriptions = (this.state.descLoadState === 1);
        const isLoadingClients = (this.state.clientsLoadState === 1);

        return <Table payments={payments} clients={clients}
                      isLoadingDescriptions={isLoadingDescriptions} toggleDescriptions={this.toggleDescriptions}
                      isLoadingClients={isLoadingClients}
                      hidePayment={this.hidePayment} destroyPayment={this.destroyPayment} restorePayment={this.restorePayment}
                      reloadData={this.fetchPayments} uploadDbf={this.uploadDbf} downloadCSV={this.downloadCSV}
                      filters={filters} selectClient={this.selectClient} onFilterChange={this.onFilterChange} />
    }
}

class Table extends React.PureComponent {
    static propTypes = {
        payments: PropTypes.array,
        clients: PropTypes.array,
        hidePayment: PropTypes.func,
        restorePayment: PropTypes.func,
        destroyPayment: PropTypes.func,
        uploadDbf: PropTypes.func,
        reloadData: PropTypes.func,
        filters: PropTypes.object,
        selectClient: PropTypes.func,
        onFilterChange: PropTypes.func,
        toggleDescriptions: PropTypes.func,
        isLoadingDescriptions: PropTypes.bool,
        isLoadingClients: PropTypes.bool,
    };

    setClientModal = React.createRef();
    newModal = React.createRef();
    editModal = React.createRef();

    openSetClientModal = (ev) => {
        const INN_PL = ev.currentTarget.children[0].innerText;
        const PL_NAME = ev.currentTarget.children[2].innerText;
        const paymentId = ev.currentTarget.dataset.paymentId;
        this.setClientModal.current.open(INN_PL, PL_NAME, paymentId);
    };
    openNewModal = () => this.newModal.current.open();
    openEditModal = (ev) => this.editModal.current.open(ev.currentTarget.dataset.id);

    renderClientInfo = (payment) => {
        if (payment.client) {
            const isFromDBF = payment.isFromDBF;
            const cls = isFromDBF ? undefined : 'text-gray tooltip';
            const tooltip = isFromDBF ? null : "Запись не из банковской выписки";
            const onClick = this.props.selectClient;

            return (
                <span onClick={onClick} data-client-id={payment.client._id} className={cls} data-tooltip={tooltip}>
                    {payment.client.name}
                </span>
            );
        } else { /* if (payment.INN_PL || payment.PL_NAME) */
            if (this.props.isLoadingClients) {
                return <Loading />;
            } else {
                return (
                    <span onClick={this.openSetClientModal} data-payment-id={payment._id} className="text-primary">
                        <span>{payment.INN_PL}</span>
                        <br/>
                        <span>{payment.PL_NAME}</span>
                    </span>
                );
            }
        }
    };

    renderHideButton = (payment) => {
        const isHidden = payment.state === PAYMENT_STATE.HIDDEN;
        const isFromDBF = payment.isFromDBF;
        const icon = isFromDBF ? (isHidden ? 'icon-refresh' : 'icon-shutdown') : 'icon-cross';
        const cb = isFromDBF ? (isHidden ? this.props.restorePayment : this.props.hidePayment) : this.props.destroyPayment;
        return (
            <Button primary small className="float-right" onClick={cb} data-id={payment._id}>
                <i className={`icon ${icon}`}/>
            </Button>
        );
    };

    renderRow = (payment) => {
        const {_id, NUM_DOC, NPLAT, DATE_PL, SUMMA, state} = payment;
        const showNPLAT = this.props.filters.showNPLAT;
        const isLoadingDescriptions = this.props.isLoadingDescriptions;
        const showEditButton = !(state === PAYMENT_STATE.HIDDEN);

        return (
            <div className="table-row" key={_id}>
                <div>
                    {this.renderClientInfo(payment)}
                    {showNPLAT && (
                        isLoadingDescriptions
                            ? <Loading />
                            : <div className="form-input-hint">{NPLAT}</div>
                        )
                    }
                </div>
                <div>{NUM_DOC}</div>
                <div>{formatDate2(DATE_PL)}</div>
                <div className="text-right">{rubFmt(SUMMA)}</div>
                <div>
                    <span className={PAYMENT_COLOR_CLASS[state]}>{PAYMENT_STATE_LABEL[state]}</span>
                    {this.renderHideButton(payment)}
                    {showEditButton && <Button primary small className="mr-1 float-right" data-id={_id} onClick={this.openEditModal}>
                        <Icon icon="edit"/> Edit
                    </Button>}
                </div>
            </div>
        )
    };

    render() {
        const {payments, filters, clients, uploadDbf, reloadData, onFilterChange, toggleDescriptions} = this.props;

        const clientFilter = (
            <SingleSelect items={clients} selectedItem={filters.client} onChange={onFilterChange}
                          name="filters.client" className="form-input input-sm"
                          clearable={true} searchable={true}/>
        );

        return (
            <div id="payments-list">
                <SetClientModal ref={this.setClientModal} reloadData={reloadData}/>
                <PaymentNewModal ref={this.newModal} onSubmit={reloadData}/>
                <PaymentEditModal ref={this.editModal} onSubmit={reloadData}/>

                <div id="list-actions">
                    <input type="file" id={FILE_BUTTON_ID} onChange={uploadDbf}/>
                    <label htmlFor={FILE_BUTTON_ID} className="btn btn-sm btn-primary mr-2">
                        <Icon icon="upload"/> DBF
                    </label>

                    <Button primary small className="mr-2" onClick={this.props.downloadCSV}>
                        <Icon icon="download"/> CSV
                    </Button>

                    <Button primary small className="mr-2" onClick={reloadData}>
                        <Icon icon="refresh"/> Обновить
                    </Button>

                    <Button primary small className="mr-2" onClick={this.openNewModal}>
                        <Icon icon="plus"/> Создать
                    </Button>

                    <span className="chip mr-2">
                        Сортировать по <Select small name="filters.sortType" value={filters.sortType} onChange={onFilterChange}>
                            <option value="smart">smart</option>
                            <option value="dateAsc">датам по возрастанию</option>
                            <option value="dateDesc">датам по убыванию</option>
                        </Select>
                    </span>

                    <span className="chip mr-2">
                        <Checkbox inline name="filters.showNPLAT" checked={filters.showNPLAT} onChange={toggleDescriptions}>Показывать комментарий</Checkbox>
                    </span>

                    {filters.showNPLAT && <span className="chip">Клиент: {clientFilter}</span>}
                </div>

                <div className="custom-table">
                    <div className="table-row table-head" key={0}>
                        <div>
                            <div>{filters.showNPLAT ? "Комментарий" : "Клиент"}</div>
                            {filters.showNPLAT
                                ? <Input small type="text" name="filters.NPLAT"
                                         value={filters.NPLAT} onChange={onFilterChange}/>
                                : clientFilter
                            }
                        </div>
                        <div>
                            <div>№ п/п</div>
                            <Input small type="text" name="filters.NUM_DOC"
                                   value={filters.NUM_DOC} onChange={onFilterChange}/>
                        </div>
                        <div>
                            <div>Дата</div>
                            <Input small type="text" name="filters.DATE_PL"
                                   value={filters.DATE_PL} onChange={onFilterChange}/>
                        </div>
                        <div>
                            <div>Сумма</div>
                            <Input small type="text" name="filters.SUMMA"
                                   value={filters.SUMMA} onChange={onFilterChange}/>
                        </div>
                        <div>
                            <div>Статус</div>
                            <Select small name="filters.state" value={filters.state} onChange={onFilterChange}>
                                <option value="">Любой</option>,
                                {Object.keys(PAYMENT_STATE).map(
                                    key => <option key={key} value={PAYMENT_STATE[key]}>{PAYMENT_STATE_LABEL[key]}</option>
                                )}
                            </Select>
                        </div>
                    </div>

                    {payments.map(this.renderRow)}
                </div>
            </div>
        )
    }
}


export default PaymentsList;