import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import cx from "classnames";
import {Link} from 'react-router-dom';
import {Button, Checkbox, Icon, Input, Loading, Select, Toast} from "spectre-react";

import ModalNotes from './ReportsList/ModalNotes';
import ModalPayment from './ReportsList/ModalPayment';
import SlotsTable from './ReportsList/SlotsTable'
import {pouch, filterChanged} from "../actions";
import {fetchReportsXLSX, fetchFile, fetchBill, getBillStates} from "../api";
import {NEW_REPORT_PATH, editReportPath} from "../paths";
import {MultiSelect, SingleSelect} from "./shared/Select";
import rubFmt from "./shared/summaFormatter";


import {
    date1to2s, parseDate, formatDate2s,
    groupBy, FilePropType,
    PAYMENT_TYPE, PAYMENT_TYPE_LABEL,
    BILL_STATE, BILL_STATE_LABEL,
    STATUS, STATUS_LABEL,
    WORK_TYPE, WORK_TYPE_LABEL,
    REPLACEMENT, ENGINEER_CATEGORY, WORK_TYPE_CHIP_CLASS, WORK_TYPE_REPORTS_LIST_LABEL
} from './shared/sharedImport';
import {areIntervalsOverlapping, differenceInMinutes, lightFormat} from "date-fns";


// https://gist.github.com/clecuona/2945438
function dateDiff(dt1, dt2) {
    const ret = {days: 0, months: 0, years: 0};

    // If the dates are equal, return the 'empty' object
    // if (dt1 === dt2)
    //     return ret;

    // Ensure dt2 > dt1
    if (dt1 > dt2) {
        const dtmp = dt2;
        dt2 = dt1;
        dt1 = dtmp;
    }

    // First get the number of full years
    const year1 = dt1.getFullYear();
    const year2 = dt2.getFullYear();

    const month1 = dt1.getMonth();
    const month2 = dt2.getMonth();

    const day1 = dt1.getDate();
    const day2 = dt2.getDate();

    // Set initial values bearing in mind the months or days may be negative
    ret['years'] = year2 - year1;
    ret['months'] = month2 - month1;
    ret['days'] = day2 - day1;

    // Now deal with the negatives
    // If the day difference is negative, e.g. dt2 = 13 oct, dt1 = 25 sept
    if (ret['days'] < 0) {
        // Use temporary dates to get the number of days remaining in the month
        const dtmp = new Date(dt1.getFullYear(), dt1.getMonth() + 1, 1, 0, 0, -1);
        const numDays = dtmp.getDate();
        ret['months'] -= 1;
        ret['days'] += numDays;
    }

    // If the month difference is negative
    if (ret['months'] < 0) {
        ret['months'] += 12;
        ret['years'] -= 1;
    }

    return ret;
}

// https://gist.github.com/paulvales/113b08bdf3d4fc3beb2a5e0045d9729d
// ACHTUNG: one importtant 'if' was removed
function ageToStr(age) {
    let txt;
    let count = age % 100;
    if (count >= 5 && count <= 20)
        txt = 'л.';
    else {
        count %= 10;
        if (count >= 1 && count <= 4)
            txt = 'г.';
        else
            txt = 'л.';
    }
    return age + txt;
}

function dateDiffString(diff) {
    const {years, months, days} = diff;
    const res = [];
    if (years)
        res.push(ageToStr(years));
    if (months)
        res.push(months + 'м.');
    if (days)
        res.push(days + 'д.');
    return res.join(' ');
}


const ReportPropType = PropTypes.shape({
    _id: PropTypes.string,
    _rev: PropTypes.string,
    type: PropTypes.oneOf(["report"]), // "report"

    clientId: PropTypes.string.isRequired,
    machineId: PropTypes.string.isRequired,
    engineerIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    problem: PropTypes.string.isRequired,
    solution: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    workType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    watchers: PropTypes.string,
    mileage: PropTypes.string,
    tickets: PropTypes.string,
    logsheet: PropTypes.string,

    workDate: PropTypes.string.isRequired,
    workHours: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            start: PropTypes.string,
            end: PropTypes.string,
            break: PropTypes.number
        }))
    ]).isRequired,

    billId: PropTypes.string,
    paymentType: PropTypes.string,

    notes: {
        comment: PropTypes.string,   //
        manScore: PropTypes.string,  // manager score, '1'/'2'/'3'/'4'/'5'
        clScore: PropTypes.string    // client score, '1'/'2'/'3'/'4'/'5'
    },

    travelHours: PropTypes.number,
    travelCost: PropTypes.number,
    housingCost: PropTypes.number,
    expenses: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string,
        type: PropTypes.string,
        amount: PropTypes.string,
        desc: PropTypes.string,
        files: PropTypes.arrayOf(FilePropType)
    })),

    printheads: PropTypes.objectOf(PropTypes.shape({
        serialNum: PropTypes.string,
        status: PropTypes.number,
        slots: PropTypes.objectOf(PropTypes.shape({
            serialNum: PropTypes.string,
            status: PropTypes.number
        }))
    })),
    replacements: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            name: PropTypes.string,
            ref: PropTypes.string,
            old: PropTypes.string,
            new: PropTypes.string,
            num: PropTypes.string,
            pos: PropTypes.string
        })
    ])),
    slotReplacements: PropTypes.arrayOf(PropTypes.shape({
        phSerial: PropTypes.string,
        phPosition: PropTypes.string,
        slotPosition: PropTypes.string,
        slotMounted: PropTypes.string,
        slotUnmounted: PropTypes.string,
        mountedSlotState: PropTypes.string
    })),
});


class ReportsListWrapper extends React.Component {
    state = {
        loading: true, error: null,
        entities: null,

        billStatesLoadState: 0, billStatesLoadError: undefined,
    };

    componentDidMount() {
        this.fetchEntities();
        this.changesHandler = pouch.changes({since: 'now', live: true})
            .on('change', this.fetchEntities)
            .on('complete', () => console.log('[ReportsList] pouch.changes complete'))
            .on('error', console.error);
    }

    componentWillUnmount() {
        if (this.changesHandler)
            this.changesHandler.cancel();
    }

    uniteIntervals(intervalX, intervalY) {
        // you can leave out the new Date() constructor, because later differenceInMinutes can accept numbers
        return {
            start: new Date(Math.min(intervalX.start, intervalY.start)),
            end: new Date(Math.max(intervalX.end, intervalY.end))
        };
    }

    clampIntervals(intervals) {
        while (true) {
            let areOverlapping = false;
            let x, y;

            for (let i = 0; i < intervals.length - 1; ++i) {
                for (let j = i + 1; j < intervals.length; ++j) {
                    if (areIntervalsOverlapping(intervals[i], intervals[j])) {
                        areOverlapping = true;
                        x = i;
                        y = j;
                        break;
                    }
                }
            }

            if (!areOverlapping)
                break;

            let clampedIntervals = intervals.filter((item, index) => index !== x && index !== y);
            clampedIntervals.push(this.uniteIntervals(intervals[x], intervals[y]));

            intervals = clampedIntervals;
        }

        return intervals;
    }

    makeWorkTimetableForClient(workHours) {
        const intervalsByDate = {};
        const engineersByDate = {};
        // this is a compromise for a case with multiple engineers in one day
        // a compromise between ignoring breakMinutes altogether and subtracting breakMinutes of every engineer
        const maxBreakMinutesByDate = {};

        for (const {startDate, start, end, engineerPresence, breakMinutes} of workHours) {
            if (!intervalsByDate[startDate])
                intervalsByDate[startDate] = [];
            if (!engineersByDate[startDate])
                engineersByDate[startDate] = new Set();
            if (!maxBreakMinutesByDate[startDate])
                maxBreakMinutesByDate[startDate] = 0;

            intervalsByDate[startDate].push({start, end});
            for (const engineerId in engineerPresence)
                if (engineerPresence[engineerId])
                    engineersByDate[startDate].add(engineerId);
            if (breakMinutes > maxBreakMinutesByDate[startDate])
                maxBreakMinutesByDate[startDate] = breakMinutes;
        }

        const workHoursMerged = [];
        for (const date in intervalsByDate) {
            let intervals = intervalsByDate[date];

            if (intervals.length !== 1)
                intervals = this.clampIntervals(intervals);

            let minutes = -maxBreakMinutesByDate[date];
            for (const interval of intervals)
                minutes += differenceInMinutes(interval.end, interval.start);

            let hours = minutes / 60;
            let wholeHours = Math.trunc(minutes / 60);
            let wholeMinutes = minutes % 60;

            workHoursMerged.push({date, hours, minutes, wholeHours, wholeMinutes});
        }

        return workHoursMerged.sort((a, b) => a.date.localeCompare(b.date));
    }

    calculatePersonalWorkTime(engineerIds, workHours) {
        const byEngineerId = {};

        for (const engineerId of engineerIds) {
            byEngineerId[engineerId] = {
                days: new Set(),
                daysNum: 0,
                minutes: 0,
                hours: 0,
                wholeHours: 0,
                wholeMinutes: 0,
                hoursDisplayed: ''
            };
        }

        for (const item of workHours) {
            for (const engineerId in item.engineerPresence) {
                if (!item.engineerPresence[engineerId])
                    continue;

                byEngineerId[engineerId].days.add(item.startDate);
                byEngineerId[engineerId].minutes += item.minutes - item.breakMinutes;
            }
        }

        for (const engineerId of engineerIds) {
            byEngineerId[engineerId].daysNum = byEngineerId[engineerId].days.size;
            byEngineerId[engineerId].hours = byEngineerId[engineerId].minutes / 60;
            byEngineerId[engineerId].wholeHours = Math.trunc(byEngineerId[engineerId].hours);
            byEngineerId[engineerId].wholeMinutes = byEngineerId[engineerId].minutes % 60;
            byEngineerId[engineerId].hoursDisplayed = this.getDisplayedRoundedWorkHoursSum(byEngineerId[engineerId].minutes);
        }

        return byEngineerId;
    }

    getDisplayedRoundedWorkHoursSum(workMinutesSum) {
        const workMinutesRounded = Math.ceil(workMinutesSum / 15) * 15;
        const wholeHours = Math.trunc(workMinutesRounded / 60);
        const wholeMinutes = workMinutesRounded % 60;

        let wholeMinutesMark = "";
        switch (wholeMinutes) {
            case 15: wholeMinutesMark = "¼"; break;
            case 30: wholeMinutesMark = "½"; break;
            case 45: wholeMinutesMark = "¾"; break;
        }

        return `${wholeHours}${wholeMinutesMark}`;
    }

    makeFancyWorkHours(workHours) {
        return workHours.map(item => {
            const start = new Date(`${item.startDate}T${item.startTime}`);
            const end = new Date(`${item.endDate}T${item.endTime}`);
            const minutes = differenceInMinutes(end, start);
            const hours = minutes / 60;
            const wholeHours = Math.trunc(hours);
            const wholeMinutes = minutes % 60;

            return {
                ...item,
                start, end, minutes, hours, wholeHours, wholeMinutes
            }
        });
    }

    fetchEntities = async () => {
        const engineers = new Map(this.props.engineers.map(engineer => [engineer._id, engineer]));

        try {
            let response = await pouch.allDocs({include_docs: true});
            let clients = [], machines = [], reports = [];

            for (const row of response.rows) {
                switch (row.doc.type) {
                    case 'report': reports.push(row.doc); break;
                    case 'machine': machines.push([row.id, row.doc]); break;
                    case 'client': clients.push([row.id, row.doc]); break;
                }
            }

            reports.sort((a, b) => a.workDate.localeCompare(b.workDate));

            const maintenanceDates = {}, visitDates = {};
            for (const [_id,] of machines) {
                maintenanceDates[_id] = [];
                visitDates[_id] = [];
            }

            for (const report of reports) {
                report.engineerNames = report.engineerIds.map(_id => engineers.get(_id).nameLfp0);

                if (Array.isArray(report.workHours)) {
                    report.workHours = this.makeFancyWorkHours(report.workHours);

                    report.workHoursMerged = this.makeWorkTimetableForClient(report.workHours);
                    const workHoursMergedMinutes = report.workHoursMerged.reduce((acc, val) => acc + val.minutes, 0);
                    report.workHoursMergedSummary = {
                        days: report.workHoursMerged.length,
                        hours: this.getDisplayedRoundedWorkHoursSum(workHoursMergedMinutes)
                    };

                    report.workHoursPersonalSummary = this.calculatePersonalWorkTime(report.engineerIds, report.workHours);
                }

                const {machineId, workDate, workType} = report;

                let len = visitDates[machineId].length;
                if (len > 0) {
                    const prevDate = visitDates[machineId][len-1];
                    report.daysV = dateDiffString(dateDiff(new Date(prevDate), new Date(workDate))); // // daysV is daysWithoutVisits
                }
                visitDates[machineId].push(workDate);

                len = maintenanceDates[machineId].length;
                if (len > 0) {
                    const prevDate = maintenanceDates[machineId][len-1];
                    report.daysM = dateDiffString(dateDiff(new Date(prevDate), new Date(workDate))); // daysM is daysWithoutMaintenance
                }
                if (workType.includes(WORK_TYPE.MAINTENANCE) || workType.includes(WORK_TYPE.INSTALL))
                    maintenanceDates[machineId].push(workDate);
            }

            const entities = {
                engineers,
                reports: reports.reverse(),
                clients: new Map(clients),
                machines: new Map(machines),
            };
            this.setState({loading: false, entities}, this.fetchBillStates);
        } catch (error) {
            console.error(error);
            this.setState({loading: false, error: error.message});
        }
    };

    async fetchBillStates() {
        if (this.state.billStatesLoadState === 2)
            return;

        if (this.props.currentUser.isManagement) {
            this.setState({billStatesLoadState: 1});

            try {
                const states = await getBillStates();
                const reports = [...this.state.entities.reports];

                for (const report of reports)
                    if (report.billId)
                        report.paymentType = states[report.billId];

                const entities = {...this.state.entities, reports};

                this.setState({billStatesLoadState: 2, entities});
            } catch (error) {
                console.error(error);
                alert(error.message);
                this.setState({billStatesLoadState: 0, billStatesLoadError: error.message});
            }
        }
    }

    getBorderDates(reports) {
        if (reports.length > 0)
            return {from: reports[reports.length-1].workDate, to: reports[0].workDate};
        else {
            const date = '2001-01-01'; // just random date
            return {from: date, to: date};
        }
    }

    getReportsAndFilterValues = ({reports, clients, machines, engineers}, filters) => {
        const options = {
            clients: new Set(),
            machines: new Set(),
            engineers: new Set(),
            status: new Set(),
            workType: new Set(),
            timespan: this.getBorderDates(reports)
        };

        for (const filter of filters) {
            if (filter.label === "clients") {
                const selected = new Set(filter.value.map(o => o._id));
                options.clients = new Set(reports.map(report => report.clientId));
                reports = reports.filter(report => selected.has(report.clientId));
            } else if (filter.label === "machines") {
                const selected = new Set(filter.value.map(o => o._id));
                options.machines = new Set(reports.map(report => report.machineId));
                reports = reports.filter(report => selected.has(report.machineId));
            } else if (filter.label === "status") {
                const status = filter.value._id;
                options.status = new Set(reports.map(report => report.status));
                reports = reports.filter(report => report.status === status);
            } else if (filter.label === "workType") {
                const workType = filter.value._id;
                options.workType = new Set(reports.map(report => report.workType).join(''));
                reports = reports.filter(report => report.workType.indexOf(workType) !== -1);
            } else if (filter.label === "payment") {
                const status = filter.value._id;
                options.payment = new Set(reports.map(report => report.paymentType));
                reports = reports.filter(report => report.paymentType === status);
            } else if (filter.label === "timespan") {
                const from = filter.value.from || options.timespan.from;
                const to = filter.value.to || options.timespan.to;
                options.timespan = this.getBorderDates(reports);
                reports = reports.filter(report => from <= report.workDate && report.workDate <= to);
            } else if (filter.label === "replacements") {
                // filter.value === true, otherwise would be absent
                reports = reports.filter(report =>
                    ('replacements' in report && report.replacements.length > 0) ||
                    ('slotReplacements' in report && report.slotReplacements.length > 0)
                );
            } else if (filter.label === "engineers") {
                const selected = new Set(filter.value.map(o => o._id));
                reports = reports.filter(report => {
                    let take = false;
                    for (const _id of report.engineerIds) {
                        options.engineers.add(_id);
                        take = take || selected.has(_id);
                    }
                    return take;
                });
            } else if (filter.label === "words") {
                const re = new RegExp(filter.value.toLowerCase(), 'i');
                reports = reports.filter(report =>
                    re.test(report.problem) || re.test(report.solution) ||
                    (!!report.replacements && report.replacements.some(item =>
                        (typeof item === "string")
                            ? re.test(item)
                            : re.test(item.name) || re.test(item.ref) || re.test(item.old) || re.test(item.new) || re.test(item.pos)
                    )) ||
                    (report.slotReplacements && report.slotReplacements.some(item => re.test(item.slotMounted) || re.test(item.slotUnmounted) || re.test(item.phSerial)))
                );
            }
        }

        const used = new Set(filters.map(filter => filter.label));
        if (!used.has('clients'))
            options.clients = new Set(reports.map(report => report.clientId));
        if (!used.has('machines'))
            options.machines = new Set(reports.map(report => report.machineId));
        if (!used.has('status'))
            options.status = new Set(reports.map(report => report.status));
        if (!used.has('workType'))
            options.workType = new Set(reports.map(report => report.workType).join(""));
        if (!used.has('payment'))
            options.payment = new Set(reports.map(report => report.paymentType));
        if (!used.has('timespan'))
            options.timespan = this.getBorderDates(reports);
        if (!used.has('engineers'))
            for (const report of reports)
                for (const _id of report.engineerIds)
                    options.engineers.add(_id);


        options.clients = [...options.clients].map(_id => ({_id, label: clients.get(_id).name}));
        options.clients.sort((a, b) => a.label.localeCompare(b.label));

        options.machines = [...options.machines].map(_id => {
            const {serial, model, manufacturer} = machines.get(_id);
            return {_id, label: `${serial} (${model})`, manufacturer};
        });
        options.machines.sort((a, b) => a.label.localeCompare(b.label));
        options.machines = groupBy(options.machines, item => item.manufacturer);
        options.machines = options.machines.map(([label, items]) => ({label, items}));
        options.machines = options.machines.sort((a, b) => a.label.localeCompare(b.label));

        const engineerzByCategory = Object.fromEntries(Object.keys(ENGINEER_CATEGORY).map(category => [category, []]));
        for (const _id of options.engineers.values()) {
            const {nameLF, category} = engineers.get(_id);
            engineerzByCategory[category].push({_id, label: nameLF});
        }
        options.engineers = [
            {label: 'SignART', items: engineerzByCategory[ENGINEER_CATEGORY.ACTIVE].sort((a, b) => a.label.localeCompare(b.label))},
            {label: 'Когда-то работали', items: engineerzByCategory[ENGINEER_CATEGORY.INACTIVE].sort((a, b) => a.label.localeCompare(b.label))},
            {label: 'Прочие', items: engineerzByCategory[ENGINEER_CATEGORY.FOREIGN].sort((a, b) => a.label.localeCompare(b.label))},
        ];

        options.status = [...options.status].sort((a, b) => a - b);
        options.status = options.status.map(key => ({_id: key, label: STATUS_LABEL[key]}));

        options.workType = [...options.workType].sort((a, b) => a.charCodeAt(0) - b.charCodeAt(0));
        options.workType = options.workType.map(key => ({_id: key, label: WORK_TYPE_LABEL[key]}));

        options.payment = [...options.payment].sort((a, b) => a - b);
        options.payment = options.payment.map(key => ({_id: key, label: PAYMENT_TYPE_LABEL[key] || BILL_STATE_LABEL[key]}));

        return {reports, options};
    };

    render() {
        const {currentUser, filters, filterChanged} = this.props;
        const {loading, error} = this.state;

        if (loading)
            return <Loading large />;

        if (error)
            return <Toast error>{error}</Toast>;

        const {reports, options} = this.getReportsAndFilterValues(this.state.entities, this.props.filters);
        const entities = {...this.state.entities, reports};

        return <ReportsList currentUser={currentUser} entities={entities} filters={filters} options={options} filterChanged={filterChanged}/>;
    }
}


class ReportsList extends React.Component {
    static propTypes = {
        currentUser: PropTypes.object.isRequired,
        entities: PropTypes.shape({
            reports: PropTypes.arrayOf(PropTypes.shape({
                _id: PropTypes.string,
                clientId: PropTypes.string,
                machineId: PropTypes.string,
                engineerIds: PropTypes.arrayOf(PropTypes.string),
                workDate: PropTypes.string,
                workHours: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.arrayOf(PropTypes.object)
                ]),
                problem: PropTypes.string,
                solution: PropTypes.string,
            })),
            clients: PropTypes.instanceOf(Map),
            machines: PropTypes.instanceOf(Map),
            engineers: PropTypes.instanceOf(Map),
        }),
        filters: PropTypes.array,
        filterChanged: PropTypes.func,
        options: PropTypes.object,
    };

    N = 15;
    state = {shownNum: this.N, sortAsc: false};

    modals = {
        notes: React.createRef(),
        payment: React.createRef()
    };

    openNotesModal = (ev) => this.modals.notes.current.open(ev.currentTarget.dataset.id);
    openPaymentModal = (ev) => this.modals.payment.current.open(ev.currentTarget.dataset.id);

    componentDidMount() {
        // [document.body.scrollHeight - window.innerHeight, window.scrollY]
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        if (document.body.scrollHeight - window.innerHeight - 400 < window.scrollY &&
            this.state.shownNum < this.props.entities.reports.length)
            this.setState({shownNum: this.state.shownNum + this.N});
    };

    onSortChange = () => this.setState(({sortAsc}) => ({sortAsc: !sortAsc, shownNum: this.N}));

    onFilterChange = (ev) => {
        const label = ev.target.name.slice(8);
        const value = (ev.target.type && ev.target.type === "checkbox") ? ev.target.checked : ev.target.value;
        this.props.filterChanged({label, value});
    };

    setFilter = (ev) => {
        const dataset = ev.target.dataset;
        if (dataset.clientId) {
            const value = [this.props.options.clients.find(c => c._id === dataset.clientId)];
            this.props.filterChanged({label: 'clients', value});
        } else {
            let value = null;
            for (const group of this.props.options.machines) {
                if (value) break;
                value = group.items.find(m => m._id === dataset.machineId);
            }
            this.props.filterChanged({label: 'machines', value: [value]});
        }
    };

    downloadXLSX = () => {
        const ids = this.props.entities.reports.map(report => report._id);
        fetchReportsXLSX(ids);
    };

    render() {
        const {currentUser, filters, options, entities} = this.props;
        const {sortAsc, shownNum} = this.state;

        const reports = sortAsc
            ? entities.reports.slice(-shownNum).reverse()
            : entities.reports.slice(0, shownNum);

        // const entities = {...this.props.entities, reports};
        const {clients, machines} = entities;
        const showPayment = currentUser.isManagement;
        const replacementsFilterOn = filters.find(({label}) => label === 'replacements');

        const countText = `${reports.length}/${entities.reports.length}`;

        return (
            <div>
                <Header currentUser={currentUser} text={countText} downloadXLSX={this.downloadXLSX}/>

                <Filters options={options} filters={filters} sortAsc={sortAsc} onSortChange={this.onSortChange}
                         showPayment={showPayment} onFilterChange={this.onFilterChange}/>

                <ModalNotes ref={this.modals.notes}/>
                <ModalPayment ref={this.modals.payment}/>

                <div id="reports-table" className="custom-table">
                    {reports.map(report =>
                        <ReportsTableRow key={report._id} currentUser={currentUser} report={report}
                                         client={clients.get(report.clientId)} machine={machines.get(report.machineId)}
                                         openNotesModal={this.openNotesModal} openPaymentModal={this.openPaymentModal}
                                         setFilter={this.setFilter} replacementsFilterOn={replacementsFilterOn}
                        />
                    )}
                </div>
            </div>
        );
    }
}


class Header extends React.PureComponent {
    static propTypes = {
        currentUser: PropTypes.object.isRequired,
        text: PropTypes.string,
        downloadXLSX: PropTypes.func,
    };

    render() {
        const {text, downloadXLSX} = this.props;

        return (
            <div id="reports-table-header" className="mb-4">
                <div className="h3 text-bold">{text}</div>

                <div>
                    <Link className="btn btn-primary mr-2" to={NEW_REPORT_PATH}>Создать репорт</Link>

                    <Button primary className="mr-2" onClick={downloadXLSX}>
                        <Icon icon="download"/> XLSX
                    </Button>

                    <Button primary href="/api/files?path=blank.pdf" download="blank.pdf" onClick={fetchFile}>
                        <Icon icon="copy"/> Бланк
                    </Button>
                </div>
            </div>
        )
    }
}

class ReportsTableRow extends React.PureComponent {
    state = {bill: null, payments: null, loading: false};

    showPaymentInfo = (ev) => {
        const billId = ev.target.dataset.billId;
        if (!billId) return;

        this.setState({loading: true});
        fetchBill(billId)
            .then(({bill, payments}) => {
                bill.DATE = new Date(bill.DATE);
                payments.forEach(payment => payment.DATE_PL = new Date(payment.DATE_PL));
                this.setState({loading: false, bill, payments});
            })
            .catch(error => this.setState({loading: false, error: error.message}));
    };

    payIcon(paymentType) {
        switch (paymentType) {
            case PAYMENT_TYPE.CONTRACT: return 'format_list_numbered';
            case PAYMENT_TYPE.WARRANTY: return 'done';
            case PAYMENT_TYPE.GOODWILL: return 'card_giftcard';
            default: return 'euro_symbol';
        }
    }

    payColor(paymentType) {
        switch (paymentType) {
            case BILL_STATE.ERROR: return 'text-primary';
            case BILL_STATE.NOT_ISSUED: return 'text-primary';
            case BILL_STATE.ISSUED: return 'text-error';
            case BILL_STATE.PART_PAY: return 'text-warning';
            default: return 'text-success';
        }
    }

    statusColor(workStatus) {
        switch (workStatus) {
            case STATUS.FIXED: return 'text-success';
            case STATUS.PART_FIXED: return 'text-error';
            default: return 'text-primary';
        }
    }

    dayWorkTimeString(minutes) {
        let hours = Math.trunc(minutes / 60);
        minutes = minutes % 60;
        return minutes > 0 ? `${hours}:${minutes}` : hours.toString();
    }

    renderButtons = (_id, paymentType, notes = {}) => {
        const currentUser = this.props.currentUser;

        const buttons = [];

        if (currentUser.isEngineer || currentUser.isManagement) {
            buttons.push(
                <Link key="edit" className="btn btn-link" to={editReportPath(_id)} title='Редактировать репорт'>
                <Icon icon="edit"/>
            </Link>
            );
        }

        if (currentUser.isManagement) {
            const cls = `btn btn-link ${this.payColor(paymentType)}`;
            const title = BILL_STATE_LABEL[paymentType] || PAYMENT_TYPE_LABEL[paymentType];
            buttons.push(
                <button key="payment" type="button" className={cls} title={title} data-id={_id} onClick={this.props.openPaymentModal}>
                    <i className="material-icons">{this.payIcon(paymentType)}</i>
                </button>
            );

            const cls1 = cx("btn btn-link", {"badge": !!notes.comment});
            buttons.push(
                <button key="notes" type="button" className={cls1} title="Заметки" data-id={_id} onClick={this.props.openNotesModal}>
                    {notes.clScore || '-'}/{notes.manScore || '-'}
                </button>
            );
        }

        return buttons;
    };

    renderTypeHours = (report, workTypes, workHoursMerged, workDate) => {
        let column = [];

        if (workTypes) {
            const workTypesSection = [];

            for (const workType of workTypes.split('')) {
                workTypesSection.push(
                    <span key={workType} className={`report__work-type chip ${WORK_TYPE_CHIP_CLASS[workType]}`}>
                        {WORK_TYPE_REPORTS_LIST_LABEL[workType]}
                    </span>
                )
            }

            workTypesSection.push(<hr key="hr-workType"/>);

            column.push(workTypesSection);
        }

        const workTimetableSection = [];
        if (Array.isArray(workHoursMerged)) {
            for (const {date, wholeHours, wholeMinutes} of workHoursMerged) {
                workTimetableSection.push(
                    <div className="report__work-timetable-row" key={date}>
                        <div>{lightFormat(new Date(date), 'dd.MM.yy')}</div>
                        <b>{wholeMinutes > 0 ? `${wholeHours}:${wholeMinutes.toString().padStart(2, '0')}` : wholeHours}</b>
                    </div>
                );
            }

            workTimetableSection.push(<hr key="hr-workHours"/>);

            workTimetableSection.push(
                <div className="report__work-timetable-row" key={-1}>
                    <div>{report.workHoursMergedSummary.days} д.</div>
                    <b>{report.workHoursMergedSummary.hours}</b>
                </div>
            )
        } else {
            workTimetableSection.push(
                <div className="report__work-timetable-row" key={workDate}>
                    <div>{date1to2s(workDate)}</div>
                    <b>{workHoursMerged}</b>
                </div>
            );
        }

        column.push(workTimetableSection);

        return column;
    };

    renderProblemTicketsStatusPayment = (problem, tickets, status, paymentType, billId) => {
        const items = [<div key="problem">{problem}</div>];
        if (tickets) {
            tickets = tickets.split(',').map((ticket, i) => [
                i > 0 && ", ",
                <a key={ticket} className="text-bold" target='_blank' rel="noopener noreferrer"
                   href={`https://service-portal.durst-group.com/en/tickets/edit/${ticket}`}
                >
                    {ticket}
                </a>
            ]);
            tickets.unshift('Тикеты: ');
            items.push(<div key="tickets">{tickets}</div>);
        }
        items.push(<hr key="hr"/>);
        items.push(<div key="status" className={this.statusColor(status)}>{STATUS_LABEL[status]}</div>);

        if (this.props.currentUser.isManagement) {
            const cls = this.payColor(paymentType);
            const label = PAYMENT_TYPE_LABEL[paymentType] || BILL_STATE_LABEL[paymentType];
            const {loading, bill, payments} = this.state;
            items.push(
                <div key="paymentType" data-bill-id={billId} onClick={loading ? undefined : this.showPaymentInfo} className={cls}>
                    {loading ? <Loading /> : label}
                </div>
            );

            if (bill && payments) {
                const {bill, payments} = this.state;
                items.push(<div key="bill" className={cls}>Счет № {bill.NUM_DOC} от {formatDate2s(bill.DATE)} на {rubFmt(bill.SUMMA)}</div>);
                for (const payment of payments)
                    items.push(<div key={payment.NUM_DOC} className={cls}>Пп № {payment.NUM_DOC} от {formatDate2s(payment.DATE_PL)} на {rubFmt(payment.SUMMA)}</div>);
            }
        }

        return items;
    };

    getContractsInfo() {
        const {report, client, machine} = this.props;
        const items = [];

        if (machine.warrantyEnd && report.workDate <= machine.warrantyEnd)
            items.push(`Гарантия до ${date1to2s(machine.warrantyEnd)}`);

        if (machine.manufacturer === "Durst") {
            if (machine.durst.service.end && report.workDate <= machine.durst.service.end)
                items.push(`Сервисный денежный до ${date1to2s(machine.durst.service.end)}`);
            if (machine.durst.spares.end && report.workDate <= machine.durst.spares.end)
                items.push(`Доп. гарантия до ${date1to2s(machine.durst.spares.end)}`);
            if (machine.durst.inspection.end && report.workDate <= machine.durst.inspection.end)
                items.push(`Инспекция голов до ${date1to2s(machine.durst.inspection.end)}`);
            if (client.durstService.active)
                items.push(`Сервисный рамочный ${client.durstService.comment}`);
            if (client.durstRepair.active)
                items.push(`Восстановление голов ${client.durstRepair.comment}`);
        }

        if (machine.manufacturer === "Aristo" && client.aristoService.active)
            items.push(`Сервисный рамочный ${date1to2s(client.aristoService.comment)}`);

        return items.join('\n');
    }

    renderClientMachine() {
        const {report, client, machine} = this.props;

        const title = this.getContractsInfo();
        const green = title.length > 0;

        return (
            <div>
                <div className="client-name" data-client-id={report.clientId} onClick={this.props.setFilter}>
                    {client.name}
                </div>
                <hr/>
                <div className={cx("machine-name", {'text-success': green})} title={title}>
                    {machine.manufacturer} {machine.model}
                </div>
                <div className={cx("machine-serial", {'text-success': green})} title={title} data-machine-id={report.machineId} onClick={this.props.setFilter}>
                    {machine.serial}
                </div>
                {machine.manufacturer === "Durst" &&
                    <div className='visit-info'>
                        {report.mileage ? <div>{report.mileage} м²</div> : <div className="text-primary">- м²</div>}
                        {report.daysM && <div>{report.daysM} без ТО</div>}
                        {report.daysV && <div>{report.daysV} с посл. виз.</div>}
                    </div>}
                <hr/>
                <div>{date1to2s(report.workDate)}</div>
            </div>
        );
    };

    renderSolutionReplacements(solution, replacements, slotReplacements) {
        const renderSeparator = (!!replacements && replacements.length > 0) || (!!slotReplacements && slotReplacements.length > 0);

        if (replacements)
            replacements = replacements.map(item => {
                if (typeof item === "string")
                    return item;
                switch (item.type) {
                    case REPLACEMENT.SP_WO_SN: return `${item.ref || ""} (${item.name || ""}): ${item.num} шт.`;
                    case REPLACEMENT.SP_W_SN: return `${item.ref || ""} (${item.name || ""}): ${item.old} → ${item.new}`;
                    case REPLACEMENT.PH_ADD: {
                        if (item.isAfterLab)
                            return `Из лаб. ${item.pos}: ${item.old || "?"} → ${item.new}`;
                        else
                            return `Установка ${item.pos}: ${item.old || "?"} → ${item.new}`;
                    }
                    case REPLACEMENT.PH_REMOVE: {
                        if (item.isForLab)
                            return `В лаб. ${item.pos}: ${item.old}`;
                        else
                            return `Демонтаж ${item.pos}: ${item.old}`;
                    }
                    case REPLACEMENT.PH_SHIFT: {
                        return `Перестановка: ${item.serial1} ${item.pos2} → ${item.pos1}\n` +
                               `Перестановка: ${item.serial2} ${item.pos1} → ${item.pos2}`;
                    }
                }
                return null;
            })

        if (slotReplacements)
            slotReplacements = <SlotsTable slotReplacements={slotReplacements}/>;

        return (
            <div>
                {solution}
                {renderSeparator && <hr />}
                {replacements && <span className={cx({'text-bold': this.props.replacementsFilterOn})}>
                    {replacements.join('\n')}
                </span>}
                {slotReplacements}
            </div>
        );
    }

    renderEngineers(engineerIds, engineerNames, workHoursPersonalSummary) {
        return engineerIds.map((engineerId, index) => {
            if (workHoursPersonalSummary) {
                const {daysNum, hoursDisplayed} = workHoursPersonalSummary[engineerId];
                const tooltip = `${daysNum} чд. — ${hoursDisplayed} чч.`;
                return <div key={engineerId} className="tooltip" data-tooltip={tooltip}>
                    {engineerNames[index]}
                </div>;
            } else {
                return <div key={engineerId}>
                    {engineerNames[index]}
                </div>;
            }
        });
    }

    render() {
        const report = this.props.report;

        return (
            <div className="table-row" key={report._id}>
                <div>{this.renderButtons(report._id, report.paymentType, report.notes)}</div>
                <div>{this.renderClientMachine()}</div>
                <div>{this.renderProblemTicketsStatusPayment(report.problem, report.tickets, report.status, report.paymentType, report.billId)}</div>
                <div>{this.renderSolutionReplacements(report.solution, report.replacements, report.slotReplacements)}</div>
                <div>{this.renderTypeHours(report,  report.workType, report.workHoursMerged, report.workDate)}</div>
                <div>{this.renderEngineers(report.engineerIds, report.engineerNames, report.workHoursPersonalSummary)}</div>
            </div>
        );
    };
}



class WordsFilter extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func
    };

    static defaultProps = {value: ''};

    timer = null;

    _onChange = (value) => this.props.onChange({target: {name: 'filters.words', value}});

    onChange = (ev) => {
        if (this.timer !== null)
            clearTimeout(this.timer);
        const value = ev.target.value;
        this.timer = setTimeout(() => {
            this.timer = null;
            this._onChange(value);
        }, 500);
    };

    clear = () => {
        if (this.timer !== null) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this._onChange('');
        this.input.value = '';
    };

    render() {
        return (
            <div className="has-icon-right">
                <Input type="text" ref={input => this.input = input}
                       placeholder='Поиск по словам' onChange={this.onChange}
                />
                <Icon formIcon icon="cross" onClick={this.clear}/>
            </div>
        );
    }
}

class TimespanFilter extends React.PureComponent {
    static propTypes = {
        borders: PropTypes.shape({from: PropTypes.string, to: PropTypes.string}),
        value: PropTypes.shape({from: PropTypes.string, to: PropTypes.string}),
        onChange: PropTypes.func
    };

    static defaultProps = {
        value: {from: "", to: ""}
    };

    state = {...this.props.value};

    _onChange = (value) => this.props.onChange({target: {name: 'filters.timespan', value}});

    onChange = (ev) => {
        const name = ev.target.name.slice(17);
        this.setState({[name]: ev.target.value || ""});

        const value = parseDate(ev.target.value) ? ev.target.value : "";
        if (value !== this.props.value[name])
            this._onChange({...this.props.value, [name]: value});
    };

    clear = () => {
        this.setState({from: '', to: ''});
        this._onChange({from: "", to: ""});
    };

    render() {
        const borders = this.props.borders;

        return (
            <div className="form-autocomplete custom-multiselect">
                <div className="form-autocomplete-input form-input">
                    <div className="chip">C</div>

                    <Input type="date" min={borders.from} max={borders.to} name="filters.timespan.from"
                           placeholder={borders.from} onChange={this.onChange} value={this.state.from}/>

                    <div className="chip">по</div>

                    <Input type="date" min={borders.from} max={borders.to} name="filters.timespan.to"
                           placeholder={borders.to} onChange={this.onChange} value={this.state.to}/>

                    <Icon formIcon icon="cross" onClick={this.clear}/>
                </div>
            </div>
        );
    }
}

class Filters extends React.PureComponent {
    static plainItems = PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string,
        label: PropTypes.string
    }));

    static groupedItems = PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        items: Filters.plainItems
    }));

    static propTypes = {
        filters: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any
        })),
        options: PropTypes.shape({
            clients: Filters.plainItems,
            machines: Filters.groupedItems,
            engineers: Filters.groupedItems,
            status: Filters.plainItems,
            payment: Filters.plainItems,
            timespan: PropTypes.shape({
                from: PropTypes.string,
                to: PropTypes.string
            })
        }),
        filterChanged: PropTypes.func,
        sortAsc: PropTypes.bool,
        onSortChange: PropTypes.func,
        showPayment: PropTypes.bool,
    };

    render() {
        const {options, filters, sortAsc, onSortChange, showPayment, onFilterChange} = this.props;

        const indices = {}, values = {};
        filters.forEach((filter, index) => {
            values[filter.label] = filter.value;
            indices[filter.label] = (index + 1).toString();
        });

        return (
            <div id="reports-table-filters" className="accordion mb-4">
                <div className="columns">
                    <div className="column col-sm-12 col-4 badge" data-badge={indices.clients || ""}>
                        <MultiSelect items={options.clients} selectedItems={values.clients}
                                     clearable={true} searchable={true}
                                     id="filters.clients" name="filters.clients"
                                     placeholder="Клиент" onChange={onFilterChange}
                        />
                    </div>

                    <div className="column col-sm-12 col-4 badge" data-badge={indices.machines || ""}>
                        <MultiSelect items={options.machines} selectedItems={values.machines}
                                     clearable={true} searchable={true} grouped={true}
                                     id="filters.machines" name="filters.machines"
                                     placeholder="Системный номер" onChange={onFilterChange}
                        />
                    </div>

                    <div className="column col-sm-12 col-4 badge" data-badge={indices.engineers || ""}>
                        <MultiSelect items={options.engineers} selectedItems={values.engineers}
                                     clearable={true} searchable={true} grouped={true}
                                     id="filters.engineers" name="filters.engineers"
                                     placeholder="Специалисты" onChange={onFilterChange}
                        />
                    </div>

                    <div id="timespan-filter" className="column col-sm-12 col-4 badge" data-badge={indices.timespan || ""}>
                        <TimespanFilter borders={options.timespan} value={values.timespan} onChange={onFilterChange}/>
                    </div>

                    <div className="column col-sm-12 col-2 badge" data-badge={indices.status || ""}>
                        <SingleSelect items={options.status} selectedItem={values.status}
                                      clearable={true} id="filters.status" name="filters.status"
                                      placeholder="Статус" onChange={onFilterChange}
                        />
                    </div>

                    <div className="column col-sm-12 col-2 badge" data-badge={indices.workType || ""}>
                        <SingleSelect items={options.workType} selectedItem={values.workType}
                                      clearable={true} id="filters.workType" name="filters.workType"
                                      placeholder="Вид работ" onChange={onFilterChange}
                        />
                    </div>

                    <div id="replacements-filter" className="column col-sm-12 col-2 badge" data-badge={indices.replacements || ""}>
                        <div>
                            <Checkbox name="filters.replacements" checked={values.replacements || false} onChange={onFilterChange}>
                                С заменами
                            </Checkbox>
                        </div>
                    </div>

                    <div className="column col-sm-12 col-2 badge" data-badge={indices.words || ""}>
                        <WordsFilter value={values.words} onChange={onFilterChange}/>
                    </div>

                    {showPayment && <div className="column col-sm-12 col-2 badge" data-badge={indices.payment || ""}>
                        <SingleSelect items={options.payment} selectedItem={values.payment}
                                      clearable={true} id="filters.payment" name="filters.payment"
                                      placeholder="Счёт" onChange={onFilterChange}
                        />
                    </div>}

                    <div id="sort-filter" className="column col-sm-12 col-3">
                        Сортировать по
                        <Select small id="sort-dir-select" value={+sortAsc} onChange={onSortChange}>
                            <option value="0">убыванию</option>
                            <option value="1">возрастанию</option>
                        </Select> дат
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(({engineers, filters}) => ({engineers, filters}), {filterChanged})(ReportsListWrapper);