import React from 'react';
import {Accordion, AccordionBody, AccordionHeader, Button, Icon, Loading, Toast} from "spectre-react";
import {deleteNextWagesParams, fetchWagesParamsAll} from "../api";


class WagesParamsHistoryPage extends React.Component {
    state = {
        fetchState: 0, fetchError: undefined,
        deleteState: 0, deleteError: undefined,
        docs: undefined,
    };

    componentDidMount() {
        this.fetchDocs();
    }

    fetchDocs = async () => {
        this.setState({fetchState: 1, fetchError: undefined, docs: undefined});

        try {
            const docs = await fetchWagesParamsAll();

            this.setState({fetchState: 2, docs});
        } catch (error) {
            console.error(error);
            this.setState({fetchState: 0, fetchError: error.message, docs: undefined});
        }
    }

    deleteNextDoc = async () => {
        if (!window.confirm('Точно удалить?'))
            return;

        this.setState({deleteState: 1, deleteError: undefined});

        try {
            await deleteNextWagesParams();

            this.setState({deleteState: 0, deleteError: undefined}, this.fetchDocs);
        } catch (error) {
            console.error(error);
            this.setState({deleteState: 0, deleteError: error.message});
        }
    }

    renderDoc(doc, index) {
        return (
            <Accordion open={index === 0}>
                <AccordionHeader className="mb-2 mt-4 c-hand">
                    <Icon icon="arrow-right" className="mr-1"/> {doc.startDate} — {doc.endDate ?? 'и до скончания времен'}
                </AccordionHeader>
                <AccordionBody>
                    {doc.isNext && (
                        <div className="mb-2">
                            Поскольку эти коэффициенты еще не начали действовать, их можно <span className="c-hand text-bold text-primary" onClick={this.deleteNextDoc}>удалить</span>.
                        </div>
                    )}
                    <textarea className="form-input" rows={20} readOnly>
                        {JSON.stringify(doc.params, null, 4)}
                    </textarea>
                </AccordionBody>
            </Accordion>
        )
    }

    render() {
        const {docs, fetchState, fetchError} = this.state;

        if (fetchError)
            return <Toast error>{fetchError}</Toast>;

        if (fetchState !== 2)
            return <Loading large />;

        return (
            <div className="container grid-xl" id="wages-params-history-page">
                <h1>История коэффициентов для расчета оплаты труда</h1>
                {docs.map((doc, index) => this.renderDoc(doc, index))}
            </div>
        )
    }
}


export default WagesParamsHistoryPage;