import React, { Component } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from "memoize-one";
import {eachDayOfInterval, format, isWithinInterval, lightFormat, parseISO, setDay, setISODay} from "date-fns";
import { ru } from 'date-fns/locale'
import Month from './Month';
import { range } from './utils';

const propTypes = {
  year: PropTypes.number.isRequired,
  forceFullWeeks: PropTypes.bool,
  showDaysOfWeek: PropTypes.bool,
  showWeekSeparators: PropTypes.bool,
  firstDayOfWeek: PropTypes.number,
  useIsoWeekday: PropTypes.bool,
  selectRange: PropTypes.bool,
  onPickDate: PropTypes.func,
  onPickRange: PropTypes.func,
  selectedDay: PropTypes.instanceOf(Date),
  customClasses: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  titles: PropTypes.func
};

const defaultProps = {
  forceFullWeeks: false,
  showDaysOfWeek: true,
  showWeekSeparators: true,
  firstDayOfWeek: 1, // Monday
  useIsoWeekday: false,
  selectRange: false,
  onPickDate: null,
  onPickRange: null,
  selectedDay: new Date(),
  customClasses: null,
  titles: null
};

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectingRange: undefined,
    };

    Calendar.computeClassesForDates = memoizeOne(Calendar.computeClassesForDates);
  }

  static computeClassesForDates(customClasses, year) {
    if (!customClasses || customClasses instanceof Function)
      return customClasses;

    customClasses = {...customClasses};
    Object.keys(customClasses).forEach(klass => {
      const obj = customClasses[klass];

      if (obj instanceof Array) {
        customClasses[klass] = new Set(customClasses[klass]);
      } else if (obj.start && obj.end) {
        customClasses[klass] = {...customClasses[klass]};
        customClasses[klass].start = parseISO(customClasses[klass].start);
        customClasses[klass].end = parseISO(customClasses[klass].end);
      }
    });

    const classesByDate = {};

    const interval = {
      start: new Date(year, 0, 1),
      end: new Date(year, 11, 31)
    };

    for (const date of eachDayOfInterval(interval)) {
      const dayOfWeek = format(date, 'E');
      const dateISO = lightFormat(date, 'yyyy-MM-dd');

      classesByDate[dateISO] = [];

      Object.keys(customClasses).forEach(klass => {
        // Order here is important! Everything is instance of Object in js
        const obj = customClasses[klass];

        if (typeof obj === 'string') {
          if (obj.indexOf(dayOfWeek) !== -1)
            classesByDate[dateISO].push(klass);
        } else if (obj instanceof Set) {
          if (obj.has(dateISO))
            classesByDate[dateISO].push(klass);
        } else if (customClasses[klass] instanceof Function) {
          if (obj(date))
              classesByDate[dateISO].push(klass);
        } else if (customClasses[klass].start && customClasses[klass].end) {
          if (isWithinInterval(date, customClasses[klass]))
              classesByDate[dateISO].push(klass);
        }
      });

      classesByDate[dateISO] = classesByDate[dateISO].join(' ');
    }

    return classesByDate;
  }

  dayClicked(date, classes) {
    if (!date) {
      // clicked on prev or next month
      return;
    }

    let { selectingRange } = this.state;
    const { selectRange, onPickRange, onPickDate } = this.props;

    if (!selectRange) {
      if (onPickDate instanceof Function) {
        onPickDate(date, classes);
      }
      return;
    }

    if (!selectingRange) {
      selectingRange = [date, date];
    } else {
      if (onPickRange instanceof Function) {
        if (selectingRange[0] > date) {
          onPickRange(date, selectingRange[0]);
        } else {
          onPickRange(selectingRange[0], date);
        }
      }
      selectingRange = undefined;
    }

    this.setState({ selectingRange });
  }

  dayHovered(hoveredDay) {
    if (!hoveredDay) {
      // clicked on prev or next month
      return;
    }

    const { selectingRange } = this.state;

    if (selectingRange) {
      selectingRange[1] = hoveredDay;

      this.setState({
        selectingRange
      });
    }
  }

  renderDaysOfWeek() {
    const { useIsoWeekday, firstDayOfWeek, forceFullWeeks, showWeekSeparators } = this.props;
    const totalDays = forceFullWeeks ? 42 : 37;

    const days = [];
    range(firstDayOfWeek, totalDays + firstDayOfWeek).forEach(i => {;
      const day = useIsoWeekday ? setISODay(new Date(), i) : setDay(new Date(), i);
      const dayName = format(day, 'E');
      const dayNameRU = format(day, 'EEEEEE', {locale: ru});

      if (showWeekSeparators) {
        if (i % 7 === firstDayOfWeek && days.length) {
          // push week separator
          days.push(<th className="week-separator" key={`seperator-${i}`} />);
        }
      }
      days.push(
        <th key={`weekday-${i}`} className={dayName.toLowerCase()}>
          {dayNameRU.slice(0, 2)}
        </th>
      );
    });

    return (
      <tr>
        <th>&nbsp;</th>
        {days}
      </tr>
    );
  }

  render() {
    const { selectingRange } = this.state;
    const { customClasses, year } = this.props;

    const months = range(0, 12).map(month => (
      <Month
        key={`month-${month}`}
        month={month}
        dayClicked={(d, classes) => this.dayClicked(d, classes)}
        dayHovered={d => this.dayHovered(d)}
        {...this.props}
        customClasses={Calendar.computeClassesForDates(customClasses, year)}
        selectingRange={selectingRange}
      />
    ));

    const header = this.props.showDaysOfWeek ? this.renderDaysOfWeek() : null;

    return (
      <table className="y-calendar">
        <thead className="day-headers">{header}</thead>
        <tbody>{months}</tbody>
      </table>
    );
  }
}

Calendar.propTypes = propTypes;
Calendar.defaultProps = defaultProps;

export default Calendar;
