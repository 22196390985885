import React from "react";
import animations from './animations';
import PropTypes from "prop-types";


const fills = [
    'fill-navy', 'fill-blue', 'fill-aqua', 'fill-teal',
    'fill-olive', 'fill-green', 'fill-lime', 'fill-yellow',
    'fill-orange', 'fill-red', 'fill-fuchsia', 'fill-purple',
    'fill-maroon', 'fill-gray', 'fill-silver', 'fill-black',
];


class AppLoadingScreen extends React.PureComponent {
    static propTypes = {
        text: PropTypes.string,
    }

    state = {
        animation: animations[Math.floor(Math.random() * animations.length)],
        className: fills[Math.floor(Math.random() * fills.length)],
    }

    render() {
        const Animation = this.state.animation;
        const className = this.state.className;

        const text = this.props.text;

        return (
            <div id="app-loading-screen" className="d-flex flex-centered">
                <Animation className={className}/>
                {text && <div id="app-loading-screen-text">{text}</div>}
            </div>
        );
    }
}


export default AppLoadingScreen;