import React from 'react';
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import {FormGroup, Label, Radio, Loading, Checkbox, Input, Toast, Button, Select} from "spectre-react";
import {SimpleAutocomplete, SingleSelect} from "./shared/Select";
import {ObjectField, FormLocation, DateInput} from "./shared/Forms";
import DayPickerInputRange from './shared/DayPickerInputRange';
import {postMachine, deleteMachine} from "../api";
import {pouch} from "../actions";
import {MACHINES_PATH} from '../paths';
import {PRINTER_CATEGORIES} from './shared/sharedImport';


export class NewMachine extends React.Component {
    state = {
        submitState: 0, submitError: null,
        machine: {
            type: "machine",

            manufacturer: "Durst",
            model: "",
            serial: "",
            clientId: this.props.location.state?.c || null,
            notes: "",

            location: {address: "", lat: "", lon: ""},

            yearOfIssue: "",
            installation: "",
            warrantyEnd: "",

            durst: {
                category: "0", // see PRINTER_CATEGORIES
                inkTypes: "",
                options: "",
                spotColors: "",
                bulb: {batch: "", type: "",},
                service: {start: "", end: "", refund: false, comment: ""},
                spares: {start: "", end: "", with: "", comment: ""},
                inspection: {start: "", end: "", times: "", comment: ""},
            }
        }
    };

    onChange = (ev) => {
        const machine = {...this.state.machine};

        if (Array.isArray(ev.target))
            for (const target of ev.target)
                machine[target.name] = target.value;
        else
            machine[ev.target.name] = ev.target.value;

        this.setState({submitError: '', machine});
    };

    onSubmit = async (machine) => {
        this.setState({submitError: '', submitSate: 1});

        try {
            await postMachine(machine);
            this.setState({submitError: null, submitState: 2});
        } catch (error) {
            this.setState({submitError: error.message, submitState: 0});
        }
    };

    render() {
        if (this.state.submitState === 2) {
            alert("Новое оборудование успешно создано.");
            return <Redirect to={MACHINES_PATH} />;
        }

        const {submitState, submitError, machine} = this.state;

        return (
            <div>
                <h1>Новое оборудование</h1>

                <MachineForm isSubmitting={submitState === 1} submitError={submitError}
                             machine={machine} onChange={this.onChange} onSubmit={this.onSubmit}
                />
            </div>
        )
    }
}

export class EditMachine extends React.Component {
    _state = {
        fetchState: 0, fetchError: null,
        submitState: 0, submitError: null,
        destroyState: 0, destroyError: null,
        machine: null
    };

    state = {...this._state};

    resetState(callback) {
        this.setState(this._state, callback);
    }

    componentDidMount() {
        this.fetchMachine();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.machineId !== prevProps.match.params.machineId)
            this.resetState(this.componentDidMount);
    }

    async fetchMachine() {
        const machineId = this.props.match.params.machineId;

        try {
            const machine = await pouch.get(machineId);

            this.setState({fetchState: 2, fetchError: null, machine});
        } catch (error) {
            console.error(error);
            this.setState({fetchState: 0, fetchError: error.message, machine: null});
        }
    }

    onChange = (ev) => {
        const machine = {...this.state.machine};

        if (Array.isArray(ev.target))
            for (const target of ev.target)
                machine[target.name] = target.value;
        else
            machine[ev.target.name] = ev.target.value;

        this.setState({submitError: null, machine});
    };

    onSubmit = async (machine) => {
        this.setState({submitError: null, submitState: 1});

        try {
            await postMachine(machine);

            this.setState({submitError: null, submitState: 2});
        } catch (error) {
            this.setState({submitError: error.message, submitState: 0})
        }
    };

    onDestroy = async () => {
        if (!window.confirm('Точно удалить?'))
            return;

        this.setState({destroyError: null, destroyState: 1});

        const {_id, _rev} = this.state.machine;

        try {
            await deleteMachine(_id, _rev);

            this.setState({destroyError: null, destroyState: 2});
        } catch (error) {
            this.setState({destroyError: error.message, destroyState: 0})
        }
    };

    render() {
        if (this.state.submitState === 2) {
            alert("Изменения успешно внесены.");
            return <Redirect to={MACHINES_PATH} />;
        }

        if (this.state.destroyState === 2) {
            alert("Оборудование успешно удалено.");
            return <Redirect to={MACHINES_PATH} />;
        }

        const {machine, fetchState, fetchError, submitState, submitError, destroyState, destroyError} = this.state;

        if (fetchState !== 2)
            return <Loading large />;

        if (fetchError)
            return <Toast error>{fetchError}</Toast>;

        return (
            <div>
                <h1>Редактирование оборудования</h1>

                <MachineForm key={machine?._id || 'whatever'}
                             isSubmitting={submitState === 1} submitError={submitError}
                             isDestroying={destroyState === 1} destroyError={destroyError}
                             onSubmit={this.onSubmit} onDestroy={this.onDestroy}
                             machine={machine} onChange={this.onChange}
                />
            </div>
        )
    }
}


class MachineForm extends React.Component {
    static propTypes = {
        machine: PropTypes.shape({
            type: PropTypes.oneOf(['machine']).isRequired,
            _id: PropTypes.string,
            _rev: PropTypes.string,

            clientId: PropTypes.string.isRequired,
            manufacturer: PropTypes.string.isRequired,
            model: PropTypes.string.isRequired,
            serial: PropTypes.string.isRequired,
            yearOfIssue: PropTypes.string,
            installation: PropTypes.string,
            warrantyEnd: PropTypes.string,
            notes: PropTypes.string,

            durst: PropTypes.shape({
                service: PropTypes.shape({
                    start: PropTypes.string,
                    end: PropTypes.string,
                    refund: PropTypes.bool,
                    comment: PropTypes.string
                }),
                spares: PropTypes.shape({
                    start: PropTypes.string,
                    end: PropTypes.string,
                    with: PropTypes.string,
                    comment: PropTypes.string
                }),
                inspection: PropTypes.shape({
                    start: PropTypes.string,
                    end: PropTypes.string,
                    times: PropTypes.string,
                    comment: PropTypes.string,
                }),
                category: PropTypes.string,
                options: PropTypes.string,
                spotColors: PropTypes.string,
                inkTypes: PropTypes.string,
                bulb: PropTypes.shape({
                    batch: PropTypes.string,
                    type: PropTypes.string,
                }),
                phInit: PropTypes.objectOf(PropTypes.shape({
                    serialNum: PropTypes.string,
                    status: PropTypes.number,
                    slots: PropTypes.objectOf(PropTypes.shape({
                        serialNum: PropTypes.string,
                        status: PropTypes.number
                    }))
                }))
            }),

            location: PropTypes.shape({
                address: PropTypes.string,
                lat: PropTypes.string,
                lon: PropTypes.string
            }),
        }),

        isSubmitting: PropTypes.bool,
        submitError: PropTypes.string,
        isDestroying: PropTypes.bool,
        destroyError: PropTypes.string,
        onChange: PropTypes.func,
        onDestroy: PropTypes.func,
        onSubmit: PropTypes.func
    };

    clients = [];
    manufacturers = [];
    models = new Map();

    state = {
        selectedClient: null,
    }

    async fetchClients() {
        const response = await pouch.query('clients/names');

        this.clients = response.rows
            .map(row => ({_id: row.key, label: row.value}))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    async fetchMachines() {
        const response = await pouch.query('machines/byManufacturer');
        this.manufacturers = Array.from(new Set(response.rows.map(row => row.key))).sort();

        this.models = new Map();
        for (const row of response.rows) {
            const set = this.models.get(row.key);
            set ? set.add(row.value) : this.models.set(row.key, new Set([row.value]))
        }
        for (const key of this.models.keys())
            this.models.set(key, Array.from(this.models.get(key)).sort());
    }

    async componentDidMount() {
        if (window.location.hash)
            setTimeout(() => document.getElementById(window.location.hash.slice(1)).scrollIntoView(), 100);

        await this.fetchClients();
        await this.fetchMachines();

        const clientId = this.props.machine.clientId;
        if (clientId) {
            const selectedClient = this.clients.find(client => client._id === clientId) || null;
            this.setState({selectedClient});
        } else {
            this.forceUpdate();
        }
    }

    onClientChange = (ev) => {
        const selectedClient = ev.target.value;
        const clientId = selectedClient?._id || null;
        const upperEvent = {target: {name: ev.target.name, value: clientId}};
        this.setState({selectedClient}, () => this.props.onChange(upperEvent));
    }

    onSubmit = () => {
        const machine = {...this.props.machine};

        if (machine.manufacturer !== "Durst")
            delete machine.durst;

        return this.props.onSubmit(machine);
    };

    render() {
        const {machine, isSubmitting, isDestroying, submitError, destroyError, onChange, onDestroy} = this.props;
        const selectedClient = this.state.selectedClient;

        const isLoading = isSubmitting || isDestroying;
        const error = submitError || destroyError;

        const models = this.models.get(machine.manufacturer) || [];

        return (
            <form id='machine-form'>
                <fieldset className="mb-6">
                    <legend className="h2">Общее</legend>

                    <div className='columns'>
                        <div className="column col-3 col-sm-12">
                            <FormGroup>
                                <Label form htmlFor="clientId">Клиент</Label>
                                <SingleSelect items={this.clients} selectedItem={selectedClient}
                                              clearable={true} searchable={true}
                                              name="clientId" placeholder="Клиент" onChange={this.onClientChange}
                                />
                            </FormGroup>
                        </div>
                        <div className="column col-3 col-sm-12">
                            <FormGroup>
                                <Label form htmlFor="manufacturer">Производитель</Label>
                                <SimpleAutocomplete items={this.manufacturers} name="manufacturer" placeholder="Пример: Durst"
                                                    value={machine.manufacturer} onChange={onChange}/>
                            </FormGroup>
                        </div>
                        <div className="column col-3 col-sm-12">
                            <FormGroup>
                                <Label form htmlFor="model">Модель</Label>
                                <SimpleAutocomplete items={models} name="model" placeholder="Пример: Rho1312AF"
                                                    value={machine.model} onChange={onChange}/>
                            </FormGroup>
                        </div>

                        <div className="column col-3 col-sm-12">
                            <FormGroup>
                                <Label form htmlFor="serial">Серийный номер</Label>
                                <Input type="text" id="serial" name="serial" value={machine.serial} onChange={onChange} placeholder="Пример: 9001237"/>
                            </FormGroup>
                        </div>

                        <div className="column col-3 col-sm-12">
                            <FormGroup>
                                <Label form htmlFor="serial">Год производства</Label>
                                <Input type="number" id="yearOfIssue" name="yearOfIssue" value={machine.yearOfIssue} onChange={onChange} placeholder="Пример: 2005" min="0" step="1"/>
                            </FormGroup>
                        </div>

                        <div className="column col-3 col-sm-12">
                            <Label form htmlFor="installation">Дата пусконаладки</Label>
                            <DateInput id="installation" className="form-input" name="installation" value={machine.installation} placeholder="Пример: 26.03.2015" onChange={onChange}/>
                        </div>

                        <div className="column col-3 col-sm-12">
                            <Label form htmlFor="warrantyEnd">Дата конца гарантии</Label>
                            <DateInput id="warrantyEnd" className="form-input" name="warrantyEnd" value={machine.warrantyEnd} placeholder="Пример: 26.03.2015" onChange={onChange}/>
                        </div>

                        <div className="column col-3 col-sm-12">
                            <FormGroup>
                                <Label form htmlFor="notes">Заметки</Label>
                                <Input type="text" id="notes" name="notes" value={machine.notes} onChange={onChange}/>
                            </FormGroup>
                        </div>
                    </div>
                </fieldset>

                {machine.durst &&
                    <ObjectField object={machine.durst} onChange={onChange}>
                        {({object: durst, onChange}) =>
                            <fieldset className="mb-6">
                                <legend className="h2">Durst</legend>

                                <div className='columns mb-6'>
                                    <ObjectField object={durst.service} onChange={onChange}>
                                        {({object, onChange}) =>
                                            <div className='column col-sm-12 col-4'>
                                                <h4>Сервисный денежный контракт</h4>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.service.start">Период действия</Label>
                                                    <DayPickerInputRange idFrom="durst.service.start" idTo="sdurst.ervice.end" className="form-input" onChange={onChange}
                                                                         nameFrom="durst.service.start" nameTo="durst.service.end" from={object.start} to={object.end} />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Checkbox name="durst.service.refund" checked={object.refund} onChange={onChange}>
                                                        Возмещение проезда и проживания
                                                    </Checkbox>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.service.comment">Комментарий</Label>
                                                    <Input type="text" id="durst.service.comment" name="durst.service.comment" value={object.comment} onChange={onChange}/>
                                                </FormGroup>
                                            </div>}
                                    </ObjectField>

                                    <ObjectField object={durst.spares} onChange={onChange}>
                                        {({object, onChange}) =>
                                            <div id="spares-container" className='column col-sm-12 col-4'>
                                                <h4>Контракт на доп. гарантию (з/ч)</h4>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.spares.start">Период действия</Label>
                                                    <DayPickerInputRange idFrom="durst.spares.start" idTo="durst.pares.end" className="form-input" onChange={onChange}
                                                                         nameFrom="durst.spares.start" nameTo="durst.spares.end" from={object.start} to={object.end} />
                                                </FormGroup>

                                                <FormGroup>
                                                    Поддерживается&nbsp;&nbsp;
                                                    <Radio inline name="durst.spares.with" value="D" checked={object.with === "D"} onChange={onChange}>Durst</Radio>
                                                    <Radio inline name="durst.spares.with" value="SA" checked={object.with === "SA"} onChange={onChange}>SignArt</Radio>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.spares.comment">Комментарий</Label>
                                                    <Input type="text" id="durst.spares.comment" name="durst.spares.comment" value={object.comment} onChange={onChange}/>
                                                </FormGroup>
                                            </div>}
                                    </ObjectField>

                                    <ObjectField object={durst.inspection} onChange={onChange}>
                                        {({object, onChange}) =>
                                            <div className='column col-sm-12 col-4'>
                                                <h4>Инспекция голов</h4>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.inspection.start">Период действия</Label>
                                                    <DayPickerInputRange idFrom="durst.inspection.start" idTo="durst.inspection.end" className="form-input" onChange={onChange}
                                                                         nameFrom="durst.inspection.start" nameTo="durst.inspection.end" from={object.start} to={object.end} />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.inspection.times">Количество визитов</Label>
                                                    <Input type="number" id="durst.inspection.times" name="durst.inspection.times" value={object.times} onChange={onChange} min="0" step="1"/>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.inspection.comment">Комментарий</Label>
                                                    <Input type="text" id="durst.inspection.comment" name="durst.inspection.comment" value={object.comment} onChange={onChange}/>
                                                </FormGroup>
                                            </div>}
                                    </ObjectField>
                                </div>

                                <fieldset>
                                    <legend className="h4">Другое</legend>

                                    <div className='columns'>
                                        <div className='column col-sm-12 col-4'>
                                            <FormGroup>
                                                <Label form htmlFor="durst.category">Категория</Label>
                                                <Select name="durst.category" id="durst.category" onChange={onChange} value={durst.category}>
                                                    {PRINTER_CATEGORIES.map(({label, value}) => <option key={value} value={value}>{label}</option>)}
                                                </Select>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label form htmlFor="durst.options">Опции</Label>
                                                <Input type="text" id="durst.options" name="durst.options" value={durst.options} onChange={onChange} placeholder="Пример: automatic feeding"/>
                                            </FormGroup>
                                        </div>
                                        <div className='column col-sm-12 col-4'>
                                            <FormGroup>
                                                <Label form htmlFor="durst.spotColors">Цвета</Label>
                                                <Input type="text" id="durst.spotColors" name="durst.spotColors" value={durst.spotColors} onChange={onChange} placeholder="Пример: lc,lm"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label form htmlFor="durst.inkTypes">Чернила</Label>
                                                <Input type="text" id="durst.inkTypes" name="durst.inkTypes" value={durst.inkTypes} onChange={onChange} placeholder="Пример: PopHS(CMYKLclM), FP.Sol"/>
                                            </FormGroup>
                                        </div>
                                        <ObjectField object={durst.bulb} onChange={onChange}>
                                            {({object, onChange}) =>
                                                <div className='column col-sm-12 col-4'>
                                                    <FormGroup>
                                                        <Label form htmlFor="durst.bulb.batch">УФ: партия</Label>
                                                        <Input type="text" id="durst.bulb.batch" name="durst.bulb.batch" value={object.batch} onChange={onChange} placeholder="Пример: 1891688"/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label form htmlFor="durst.bulb.type">УФ: тип</Label>
                                                        <Input type="text" id="durst.bulb.type" name="durst.bulb.type" value={object.type} onChange={onChange} placeholder="Пример: 1380mm, 21kW"/>
                                                    </FormGroup>
                                                </div>}
                                        </ObjectField>
                                    </div>
                                </fieldset>
                            </fieldset>
                        }
                    </ObjectField>}

                <FormLocation location={machine.location} onChange={onChange} />

                <div className="form-actions">
                    {error && <Toast error className="mb-2">{error}</Toast>}

                    <div className="action-buttons">
                        {onDestroy && <Button error loading={isLoading} className="mr-2" onClick={onDestroy}>Удалить</Button>}
                        <Button success loading={isLoading} onClick={this.onSubmit}>Отправить</Button>
                    </div>
                </div>
            </form>
        );
    }
}