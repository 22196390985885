import PouchDB from 'pouchdb-browser';

export const pouch = new PouchDB('service-app');
const remote = new PouchDB(`${window.location.origin}/api/db/service`);
let replicationHandler = null;

// ----- SIGN_IN/SIGN_OUT

export const SIGNED_IN = 'SIGNED_IN';
export const SIGNED_OUT = 'SIGNED_OUT';

export const signedIn = (payload) => ({type: SIGNED_IN, payload});
export const signedOut = () => ({type: SIGNED_OUT});

// ----- REPLICATION

export const REPL_PROGRESS = 'REPL_PROGRESS';
const replProgress = (progress) => ({type: REPL_PROGRESS, payload: progress});

export function startReplication(dispatch) {
    console.log('[actions] startReplication');

    let totalSize = 0, progress = 1, batchSize = 250;

    pouch.replicate.from(remote, {batch_size: batchSize})
        .on('change', function({pending}) {
            if (totalSize < pending)
                totalSize = pending + batchSize;
            progress = Math.round(100 - pending/totalSize * 100);
            dispatch(replProgress(progress));
        })
        .on('complete', function() {
            console.log('[actions] startReplication complete');
            listenToChanges(dispatch);
            dispatch(replProgress(0));
        })
        .on('error', function(err) {
            console.error('[actions] startReplication error', err);
            dispatch(replProgress(err));
        });
}

function listenToChanges(dispatch) {
    console.log('[actions] listenToChanges');
    replicationHandler = pouch.replicate.from(remote, {live: true, retry: true})
    // .on('change', info => console.log(`[actions] replicate change`, info))
        .on('complete', info => console.log(`[actions] listenToChanges complete`, info))
        .on('error', error => dispatch(replProgress(error)));
}

export function stopReplication() {
    if (replicationHandler)
        replicationHandler.cancel();
}

// ----- WIPE DATA

export function wipeData() {
    console.log('[actions] wipeData');
    return pouch.destroy();
}

// ----- REPORTS LIST FILTERS

export const FILTER_CHANGED = 'FILTER_CHANGED';
export const filterChanged = (payload) => ({type: FILTER_CHANGED, payload});

// ----- WagesEngineersTablePage

export const SET_WAGES_ENGINEERS_TABLE_PAGE_FILTER = 'SET_WAGES_ENGINEERS_TABLE_PAGE_FILTER';
export const setWagesEngineersTablePageFilter = (state) => ({type: SET_WAGES_ENGINEERS_TABLE_PAGE_FILTER, payload: state});