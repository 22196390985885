import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {format, getDay, getDaysInMonth, isBefore, isSameDay, isWithinInterval, getMonth} from "date-fns";
import { ru } from 'date-fns/locale';
import Day from './Day';
import { range } from './utils';

const propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  forceFullWeeks: PropTypes.bool.isRequired,
  showWeekSeparators: PropTypes.bool.isRequired,
  selectedDay: PropTypes.instanceOf(Date).isRequired,
  firstDayOfWeek: PropTypes.number.isRequired,
  selectingRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  selectRange: PropTypes.bool.isRequired,
  selectedRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  customClasses: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  titles: PropTypes.func,
  dayClicked: PropTypes.func.isRequired,
  dayHovered: PropTypes.func.isRequired
};

const defaultProps = {
  selectingRange: undefined,
  selectedRange: undefined,
  customClasses: undefined,
  titles: undefined
};

class Month extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    const { month, selectingRange, selectedRange } = this.props;
    const { selectingRangeStart, selectingRangeEnd } = this.state;

    // full repaint for some global-affecting rendering props
    if (
      this.props.year !== nextProps.year ||
      this.props.forceFullWeeks !== nextProps.forceFullWeeks ||
      this.props.showWeekSeparators !== nextProps.showWeekSeparators ||
      this.props.firstDayOfWeek !== nextProps.firstDayOfWeek ||
      this.props.selectRange !== nextProps.selectRange ||
      this.props.customClasses !== nextProps.customClasses ||
      (this.props.selectRange && selectingRange === undefined && nextProps.selectingRange === undefined)
    ) {
      return true;
    }

    // if we get to this point and we are in 'selectRange' mode then it's likely that we have a change in selectingRange
    if (this.props.selectRange) {
      if (selectingRange === undefined) {
        let oldRangeStart = getMonth(selectedRange[0]);
        let oldRangeEnd = getMonth(selectedRange[1]);
        if (oldRangeStart > oldRangeEnd) {
          [oldRangeStart, oldRangeEnd] = [oldRangeEnd, oldRangeStart];
        }

        let newRangeStart = getMonth(nextProps.selectingRange[0]);
        let newRangeEnd = getMonth(nextProps.selectingRange[1]);
        if (newRangeStart > newRangeEnd) {
          [newRangeStart, newRangeEnd] = [newRangeEnd, newRangeStart];
        }

        // first time it's called, repaint months in old selectedRange and next selectingRange
        return (oldRangeStart <= month && month <= oldRangeEnd) || (newRangeStart <= month && month <= newRangeEnd);
      } else if (nextProps.selectingRange === undefined) {
        // last time it's called, repaint months in previous selectingRange
        let oldRangeStart = selectingRangeStart;
        let oldRangeEnd = selectingRangeEnd;
        if (oldRangeStart > oldRangeEnd) {
          [oldRangeStart, oldRangeEnd] = [oldRangeEnd, oldRangeStart];
        }

        let newRangeStart = getMonth(nextProps.selectedRange[0]);
        let newRangeEnd = getMonth(nextProps.selectedRange[1]);
        if (newRangeStart > newRangeEnd) {
          [newRangeStart, newRangeEnd] = [newRangeEnd, newRangeStart];
        }

        // called on day hovering changed
        return (oldRangeStart <= month && month <= oldRangeEnd) || (newRangeStart <= month && month <= newRangeEnd);
      }
      // called on day hovering changed
      let oldRangeStart = selectingRangeStart;
      let oldRangeEnd = selectingRangeEnd;
      if (oldRangeStart > oldRangeEnd) [oldRangeStart, oldRangeEnd] = [oldRangeEnd, oldRangeStart];

      let newRangeStart = getMonth(nextProps.selectingRange[0]);
      let newRangeEnd = getMonth(nextProps.selectingRange[1]);
      if (newRangeStart > newRangeEnd) {
        [newRangeStart, newRangeEnd] = [newRangeEnd, newRangeStart];
      }

      return (oldRangeStart <= month && month <= oldRangeEnd) || (newRangeStart <= month && month <= newRangeEnd);
    } else if (getMonth(this.props.selectedDay) === month || getMonth(nextProps.selectedDay) === month) {
      // single selectedDay changed: repaint months where selectedDay was and where will be
      return true;
    }

    return false;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectingRange !== undefined) {
      this.setState({
        selectingRangeStart: getMonth(nextProps.selectingRange[0]),
        selectingRangeEnd: getMonth(nextProps.selectingRange[1])
      });
    }
  }

  dayClicked(day, classes) {
    const { dayClicked } = this.props;
    dayClicked(day, classes);
  }

  dayHovered(day) {
    const { selectRange, dayHovered } = this.props;
    if (selectRange) {
      dayHovered(day);
    }
  }

  renderMonthDays() {
    const {
      year,
      month,
      forceFullWeeks,
      showWeekSeparators,
      selectedDay,
      firstDayOfWeek,
      selectingRange,
      selectRange,
      selectedRange,
      customClasses,
      titles
    } = this.props;
    // const monthStart = moment([year, month, 1]); // current day
    const monthStart = new Date(year, month, 1);

    // number of days to insert before the first of the month to correctly align the weekdays
    // let prevMonthDaysCount = monthStart.weekday();
    let prevMonthDaysCount = getDay(monthStart);
    while (prevMonthDaysCount < firstDayOfWeek) {
      prevMonthDaysCount += 7;
    }
    // days in month
    // const numberOfDays = monthStart.daysInMonth();
    const numberOfDays = getDaysInMonth(monthStart);
    // insert days at the end to match up 37 (max number of days in a month + 6)
    // or 42 (if user prefers seeing the week closing with Sunday)
    const totalDays = forceFullWeeks ? 42 : 37;

    // day-generating loop
    const days = [];
    range(firstDayOfWeek + 1, totalDays + firstDayOfWeek + 1).forEach(i => {
      if (showWeekSeparators) {
        if ((i - 1) % 7 === firstDayOfWeek && days.length) {
          // push week separator
          days.push(<td className="week-separator" key={`seperator-${i}`} />);
        }
      }

      if (i <= prevMonthDaysCount) {
        days.push(
          <Day
            key={`day-${i}`}
            day={null}
            classes="prev-month"
            dayClicked={d => this.dayClicked(d, "prev-month")}
            dayHovered={d => this.dayHovered(d)}
          />
        );
        return;
      }

      if (i > numberOfDays + prevMonthDaysCount) {
        days.push(
          <Day
            key={`day-${i}`}
            day={null}
            classes="next-month"
            dayClicked={d => this.dayClicked(d, "next-month")}
            dayHovered={d => this.dayHovered(d)}
          />
        );
        return;
      }

      const day = new Date(year, month, i - prevMonthDaysCount);  // always valid

      // pick appropriate classes
      const classes = [];
      if (selectRange) {
        // selectingRange is used while user is selecting a range
        // (has clicked on start day, and is hovering end day - but not yet clicked)
        let start = (selectingRange || selectedRange)[0];
        let end = (selectingRange || selectedRange)[1];

        // validate range
        if (isBefore(end, start)) {
          [end, start] = selectingRange || selectedRange;
        }

        if (isWithinInterval(day, {start, end})) {  // ends inclusive
          classes.push('range');
        }

        if (isSameDay(day, start)) {
          classes.push('range-left');
        }

        if (isSameDay(day, end)) {
          classes.push('range-right');
        }
      } else if (isSameDay(day, selectedDay)) {
        classes.push('selected');
      }

      // call here customClasses function to avoid giving improper classes to prev/next month
      if (customClasses) {
        if (customClasses instanceof Function) {
          classes.push(customClasses(day));
        } else {
          const dateISO = format(day, 'yyyy-MM-dd');
          if (customClasses[dateISO])
            classes.push(customClasses[dateISO]);
        }
      }

      const title = titles instanceof Function ? titles(day) : undefined;

      days.push(
        <Day
          key={`day-${i}`}
          day={day}
          classes={classes.join(' ')}
          dayClicked={d => this.dayClicked(d, classes.join(' '))}
          dayHovered={d => this.dayHovered(d)}
          title={title}
        />
      );
    });

    return days;
  }

  render() {
    const { month, year } = this.props;

    return (
      <tr>
        <td className="month-name">
          {format(new Date(year, month, 1), 'LLL', {locale: ru}).slice(0, 3)}
        </td>
        {this.renderMonthDays()}
      </tr>
    );
  }
}

Month.propTypes = propTypes;
Month.defaultProps = defaultProps;

export default Month;
