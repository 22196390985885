import React from "react";
import PropTypes from "prop-types";
import OverridesLog from "./OverridesLog";
import {areIntervalsOverlapping, isWithinInterval} from "date-fns";

class AffectingOverridesLog extends React.PureComponent {
    static propTypes = {
        selectedDay: PropTypes.instanceOf(Date).isRequired,
        selectedRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
        selectRange: PropTypes.bool.isRequired,
        overrides: PropTypes.arrayOf(PropTypes.shape({
            _id: PropTypes.string,
            _rev: PropTypes.string,
            authorId: PropTypes.string,
            affectedEngineerId: PropTypes.string,
            affectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
            statusOverride: PropTypes.string,
            createdAt: PropTypes.instanceOf(Date),

            iconClassName: PropTypes.string,
        })).isRequired,
        onItemRemoved: PropTypes.func,
        showItemRemoveButtons: PropTypes.bool,
    }

    filterLogItemsForSelectedDay(selectedDay) {
        // console.log('filterLogItemsForSelectedDay', selectedDay, this.props.overrides);
        return this.props.overrides.filter(({affectedDates}) => {
            const interval = {start: affectedDates[0], end: affectedDates[affectedDates.length - 1]};
            return isWithinInterval(selectedDay, interval);
        });
    }

    filterLogItemsForSelectedRange(selectedRange) {
        // console.log('filterLogItemsForSelectedRange', selectedRange, this.props.overrides);
        const selectedInterval = {start: selectedRange[0], end: selectedRange[1]};

        return this.props.overrides.filter(({affectedDates}) => {
            const overrideInterval = {start: affectedDates[0], end: affectedDates[affectedDates.length - 1]};
            return areIntervalsOverlapping(selectedInterval, overrideInterval, {inclusive: true});
        });
    }

    render() {
        const affectingLogItems = this.props.selectRange
            ? this.filterLogItemsForSelectedRange(this.props.selectedRange)
            : this.filterLogItemsForSelectedDay(this.props.selectedDay);

        if (affectingLogItems.length === 0)
            return null;

        return (
            <OverridesLog overrides={affectingLogItems}
                          className="bg-gray text-bold"
                          onItemRemoved={this.props.onItemRemoved}
                          showItemRemoveButtons={this.props.showItemRemoveButtons}
            />
        )
    }
}

export default AffectingOverridesLog;