import React from "react";
import propTypes from 'prop-types';
import memoize from "memoize-one";
import {Button, Checkbox, Icon, Input, Label, Radio, Select} from "spectre-react";
import {Autocomplete, SimpleAutocomplete} from "../shared/Select";
import {ArrayField, ObjectField} from "../shared/Forms";
import {getSparePartsByRef, getSparePartsByDescription} from "../../api";
import {pouch} from '../../actions';
import {REPLACEMENT_LABEL, REPLACEMENT, SLOT_STATE} from "../shared/sharedImport";


class WithAutocomplete extends React.PureComponent {
    static propTypes = {
        item: propTypes.object.isRequired,
        onChange: propTypes.func.isRequired,
        children: propTypes.func.isRequired
    };

    onChange = (ev) => {
        const item = ev.target.value;

        if ('ref' in item && item.ref instanceof Object) {
            const {ref, name} = item.ref;
            ev.target.value = {...ev.target.value, ref, name};
        }

        if ('name' in item && item.name instanceof Object) {
            const {ref, name} = item.name;
            ev.target.value = {...ev.target.value, ref, name};
        }

        this.props.onChange(ev);
    };

    render() {
        return this.props.children(this.onChange);
    }
}

export class Replacements extends React.PureComponent {
    static propTypes = {
        machineId: propTypes.oneOfType([propTypes.string, propTypes.object]),
        replacements: propTypes.array.isRequired,
        onChange: propTypes.func
    };

    static defaultProps = {
        replacements: []
    };

    info = undefined;
    machineModels = {};

    async componentDidMount() {
        await this.fetchMachineModels();
        await this.fetchDurstInfoDoc();
    }

    async fetchMachineModels() {
        const response = await pouch.query('machines/byManufacturer');
        const entries = response.rows.map(row => [row.id /* machine _id */, row.value /* machine model */])
        this.machineModels = Object.fromEntries(entries);
    }

    async fetchDurstInfoDoc() {
        this.info = await pouch.get('durstInfo');

        for (const key of Object.keys(this.info.printheads))
            this.info.printheads[key] = this.info.printheads[key].map(({ref, name}) => ({ref, name}));
    }

    getPrintheadsAndPositions = memoize(
        (model) => {
            if (!model || !this.info)
                return { printheads: [], positions: [] };

            return {
                printheads: this.info.printheads[model] || [],
                positions: (model in this.info.configurations)
                    ? this.info.tables[this.info.configurations[model].table]
                        .reduce((acc, val) => acc.concat(val), [])
                        .filter(Boolean)
                    : []
            }
        }
    );

    addNewReplacementsItem = () => {
        return { type: null };
    }

    render() {
        const {replacements, onChange, machineId} = this.props;

        const machineModel = this.machineModels[machineId] || null;
        const {printheads, positions} = this.getPrintheadsAndPositions(machineModel);

        return (
            <ArrayField name="replacements" array={replacements} onChange={onChange} buildNewItem={this.addNewReplacementsItem}>
                {({array, keys, onAdd, onRemove, onChange}) =>
                    <fieldset className="mb-6">
                        <legend className="h2">
                            Замены запчастей/комплектующих&nbsp;
                            <Button action link small onClick={onAdd}>
                                <Icon icon="plus"/>
                            </Button>
                        </legend>

                        <p className="text-italic mb-1">
                            При выборе "ПГ перестановка" нужно писать с/н и позиции ПОСЛЕ замены.
                        </p>

                        <div id="replacements-table">
                            {array.map((item, index) => this.renderItem(item, index, onRemove, onChange, keys[index], printheads, positions))}
                        </div>
                        <Button primary className="mt-2" onClick={onAdd}>Добавить</Button>
                    </fieldset>
                }
            </ArrayField>
        )
    }

    renderItem = (item, index, onRemove, onChange, key, printheads, positions) => {
        const removeButton =
            <Button action onClick={onRemove} data-index={index}>
                <Icon icon="minus"/>
            </Button>;

        if (typeof item === "string")
            return <div className="replacement-item" key={key}>
                <Input type="text" name={`replacements.${index}`} placeholder="Описание" value={item} onChange={onChange}/>
                {removeButton}
            </div>;

        switch (item.type) {
            case null:
                return <ObjectField object={item} onChange={onChange} dummy={index} key={key}>
                    {({onChange}) => <div className="replacement-item">
                        <Label primary>Выберите тип:</Label>
                        {Object.keys(REPLACEMENT).map(type =>
                            <Radio key={type} name={`replacements.${index}.type`} value={type} checked={false} onChange={onChange}>
                                {REPLACEMENT_LABEL[type]}
                            </Radio>
                        )}
                        {removeButton}
                    </div>}
                </ObjectField>;

            case REPLACEMENT.SP_WO_SN:
                return <WithAutocomplete item={item} onChange={onChange} key={key}>
                    {(onChange) =>
                        <ObjectField object={item} onChange={onChange} dummy={index}>
                            {({onChange, object}) => <div className="replacement-item">
                                <Label>{REPLACEMENT_LABEL.SP_WO_SN}</Label>
                                <Autocomplete
                                    placeholder="Артикул" onChange={onChange} onSelect={onChange}
                                    itemsGetter={getSparePartsByRef} itemToString={_item => _item ? _item.ref : ''}
                                    name={`replacements.${index}.ref`} value={object.ref}
                                />
                                <Autocomplete
                                    placeholder="Наименование" onChange={onChange} onSelect={onChange}
                                    itemsGetter={getSparePartsByDescription} itemToString={_item => _item ? _item.name : ''}
                                    name={`replacements.${index}.name`} value={object.name}
                                />
                                <Input type="number" name={`replacements.${index}.num`} placeholder="Кол-во" step="1" value={object.num} onChange={onChange}/>
                                {removeButton}
                            </div>}
                        </ObjectField>
                    }
                </WithAutocomplete>;

            case REPLACEMENT.SP_W_SN:
                return <WithAutocomplete item={item} onChange={onChange} key={key}>
                    {(onChange) =>
                        <ObjectField object={item} onChange={onChange} dummy={index}>
                            {({onChange, object}) =><div className="replacement-item">
                                <Label>{REPLACEMENT_LABEL.SP_W_SN}</Label>
                                <Autocomplete
                                    placeholder="Артикул" onChange={onChange} onSelect={onChange}
                                    itemsGetter={getSparePartsByRef} itemToString={_item => _item ? _item.ref : ''}
                                    name={`replacements.${index}.ref`} value={object.ref}
                                />
                                <Autocomplete
                                    placeholder="Наименование" onChange={onChange} onSelect={onChange}
                                    itemsGetter={getSparePartsByDescription} itemToString={_item => _item ? _item.name : ''}
                                    name={`replacements.${index}.name`} value={object.name}
                                />
                                <Input type="text" name={`replacements.${index}.old`} placeholder="Старый с/н" value={object.old} onChange={onChange}/>
                                <Input type="text" name={`replacements.${index}.new`} placeholder="Новый с/н" value={object.new} onChange={onChange}/>
                                {removeButton}
                            </div>}
                        </ObjectField>
                    }
                </WithAutocomplete>;

            case REPLACEMENT.PH_ADD:
                return <WithAutocomplete item={item} onChange={onChange} key={key}>
                    {(onChange) =>
                        <ObjectField object={item} onChange={onChange} dummy={index}>
                            {({onChange, object}) => <div className="replacement-item">
                                <Label>{REPLACEMENT_LABEL.PH_ADD}</Label>
                                {/*<SimpleAutocomplete items={printheads} name={`replacements.${index}.ref`}*/}
                                {/*                    value={object.ref} placeholder="Артикул" onChange={onChange}/>*/}
                                <Checkbox name={`replacements.${index}.isAfterLab`} checked={object.isAfterLab} onChange={onChange}>после ремонта</Checkbox>
                                <Autocomplete
                                    placeholder="Артикул" onChange={onChange} onSelect={onChange}
                                    items={printheads} itemToString={_item => _item ? _item.ref : ''}
                                    name={`replacements.${index}.ref`} value={object.ref}
                                />
                                <Input type="text" name={`replacements.${index}.name`} placeholder="Наименование" value={object.name} onChange={onChange}/>
                                <SimpleAutocomplete items={positions} name={`replacements.${index}.pos`} value={object.pos} placeholder="Позиция" onChange={onChange}/>
                                <Input type="text" name={`replacements.${index}.old`} placeholder="Старый с/н" value={object.old} onChange={onChange}/>
                                <Input type="text" name={`replacements.${index}.new`} value={object.new} placeholder="Новый с/н" onChange={onChange}/>
                                {removeButton}
                            </div>}
                        </ObjectField>}
                </WithAutocomplete>;

            case REPLACEMENT.PH_REMOVE:
                return <ObjectField object={item} onChange={onChange} dummy={index} key={key}>
                    {({onChange, object}) => <div className="replacement-item">
                        <Label>{REPLACEMENT_LABEL.PH_REMOVE}</Label>
                        <Checkbox name={`replacements.${index}.isForLab`} checked={object.isForLab} onChange={onChange}>для ремонта</Checkbox>
                        <SimpleAutocomplete items={positions} name={`replacements.${index}.pos`} value={object.pos} placeholder="Позиция" onChange={onChange}/>
                        <Input type="text" name={`replacements.${index}.old`} placeholder="C/н головы" value={object.old} onChange={onChange}/>
                        {removeButton}
                    </div>}
                </ObjectField>;

            case REPLACEMENT.PH_SHIFT:
                return <ObjectField object={item} onChange={onChange} dummy={index} key={key}>
                    {({onChange, object}) => <div className="replacement-item">
                        <Label>{REPLACEMENT_LABEL.PH_SHIFT}</Label>
                        <Input type="text" onChange={onChange} name={`replacements.${index}.serial1`} placeholder="С/н 1"     value={object.serial1}/>
                        <Input type="text" onChange={onChange} name={`replacements.${index}.pos1`}    placeholder="Позиция 1" value={object.pos1}/>
                        <Input type="text" onChange={onChange} name={`replacements.${index}.serial2`} placeholder="С/н 2"     value={object.serial2}/>
                        <Input type="text" onChange={onChange} name={`replacements.${index}.pos2`}    placeholder="Позиция 2" value={object.pos2}/>
                        {removeButton}
                    </div>}
                </ObjectField>;
        }
    };
}

export class SlotReplacements extends React.PureComponent {
    static propTypes = {
        slotReplacements: propTypes.array.isRequired,
        onChange: propTypes.func.isRequired
    };

    static defaultProps = {
        slotReplacements: [],
    };

    SLOT_POSITIONS = [
        <option key="1" value="1">1</option>,
        <option key="2" value="2">2</option>,
        <option key="3" value="3">3</option>,
        <option key="4" value="4">4</option>
    ];

    renderRow(onChange, object, index, onRemove) {
        return (
            <tr>
                <td>
                    <Input type="text" name={`slotReplacements.${index}.phSerial`} value={object.phSerial}
                           onChange={onChange} placeholder="С/н головы"/>
                </td>
                <td>
                    <Input type="text" name={`slotReplacements.${index}.phPosition`} value={object.phPosition}
                           onChange={onChange} placeholder="Позиция головы"/>
                </td>
                <td>
                    <Select name={`slotReplacements.${index}.slotPosition`}
                            value={object.slotPosition} onChange={onChange}>{this.SLOT_POSITIONS}</Select>
                </td>
                <td>
                    <Input type="text" name={`slotReplacements.${index}.slotUnmounted`} value={object.slotUnmounted}
                           onChange={onChange} placeholder="С/н снятого слота"/>
                </td>
                <td>
                    <Input type="text" name={`slotReplacements.${index}.slotMounted`} value={object.slotMounted}
                           onChange={onChange} placeholder="С/н установленного слота"/>
                </td>
                <td>
                    <Radio inline name={`slotReplacements.${index}.mountedSlotState`} value={SLOT_STATE.NEW}
                           checked={object.mountedSlotState === SLOT_STATE.NEW} onChange={onChange}>
                        Новый
                    </Radio>
                    <Radio inline name={`slotReplacements.${index}.mountedSlotState`} value={SLOT_STATE.USED}
                           checked={object.mountedSlotState === SLOT_STATE.USED} onChange={onChange}>
                        Б/у
                    </Radio>
                </td>
                <td>
                    <Button action onClick={onRemove} data-index={index}>
                        <Icon icon="minus"/>
                    </Button>
                </td>
            </tr>
        );
    }

    addNewSlotReplacementsItem = () => {
        return {
            phSerial: '',
            phPosition: '',
            slotPosition: '1',
            slotMounted: '',
            slotUnmounted: '',
            mountedSlotState: SLOT_STATE.USED
        };
    }

    render() {
        return (
            <ArrayField name="slotReplacements" array={this.props.slotReplacements} onChange={this.props.onChange} buildNewItem={this.addNewSlotReplacementsItem}>
                {({array, keys, onAdd, onRemove, onChange}) =>
                    <fieldset className="mb-6">
                        <legend className="h2">
                            Замены слотов&nbsp;
                            <Button action link small onClick={onAdd}>
                                <Icon icon="plus"/>
                            </Button>
                        </legend>

                        <table className="table">
                            <thead><tr>
                                <th>С/н ПГ</th>
                                <th>Позиция ПГ</th>
                                <th>№ слота</th>
                                <th>Снят слот</th>
                                <th>Установлен слот</th>
                                <th>Установлен</th>
                                <th/>
                            </tr></thead>
                            <tbody>{array.map((item, index) =>
                                <ObjectField object={item} onChange={onChange} dummy={index} key={keys[index]}>
                                    {({onChange, object}) => this.renderRow(onChange, object, index, onRemove)}
                                </ObjectField>
                            )}</tbody>
                        </table>
                        <Button primary className="mt-2" onClick={onAdd}>Добавить</Button>
                    </fieldset>}
            </ArrayField>
        );
    }
}