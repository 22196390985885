import React from "react";
import {Button, Loading, Toast} from "spectre-react";


export function ModalContainerLoading({close}) {
    return (
        <div className="modal-container">
            <div className="modal-header">
                <span className="btn btn-clear float-right" onClick={close}/>
                <div className="modal-title h5">Загрузка...</div>
            </div>
            <div className="modal-body">
                <Loading large />
            </div>
            <div className="modal-footer">
                <Button link onClick={close}>Закрыть</Button>
            </div>
        </div>
    );
}

export function ModalContainerError({error, close}) {
    return (
        <div className="modal-container">
            <div className="modal-header">
                <span className="btn btn-clear float-right" onClick={close}/>
                <div className="modal-title h5">Ошибка</div>
            </div>
            <div className="modal-body">
                <Toast>{error}</Toast>
            </div>
            <div className="modal-footer">
                <Button link onClick={close}>Закрыть</Button>
            </div>
        </div>
    );
}