import React from 'react';
import propTypes from 'prop-types';
import cx from 'classnames';
import {FormGroup, Label, Loading, Checkbox, Radio, Input, Toast, Button, Icon} from "spectre-react";
import {ModalContainerError, ModalContainerLoading} from "../shared/Modals";
import {SingleSelect} from "../shared/Select";
import {pouch} from "../../actions";
import {getReport, postReport, postBill, fetchBills} from "../../api";
import {BILL_STATE, BILL_STATE_LABEL, PAYMENT_TYPE, PAYMENT_TYPE_LABEL, formatDate2} from "../shared/sharedImport";


export default class extends React.PureComponent {
    getDefaultState() { return {active: false, loading: '', error: '', report: null} }
    state = this.getDefaultState();

    open = (reportId) => {
        this.setState({active: true, loading: true, error: ''});
        getReport(reportId)
            .then(report => this.setState({loading: false, report: {paymentType: BILL_STATE.ISSUED, ...report}}))
            .catch(error => this.setState({loading: false, error: error.message}));
    };

    close = () => this.setState(this.getDefaultState());

    renderContainer() {
        const {loading, error, report} = this.state;

        if (!report) {
            if (loading) return <ModalContainerLoading close={this.close}/>;
            if (error) return <ModalContainerError error={error} close={this.close}/>;
        } else {
            return <ModalPayment report={report} close={this.close}/>;
        }
    }

    render() {
        if (!this.state.active) return null;

        return (
            <div className="modal active" id="report-payment-modal">
                <span className="modal-overlay" onClick={this.close}/>
                {this.renderContainer()}
            </div>
        )
    }
}

function Footer({loading, error, disabled, submit, close}) {
    return (
        <div className="modal-footer">
            {error && <Toast error className="mb-2">{error}</Toast>}
            {submit && <Button primary loading={loading} disabled={disabled} onClick={submit}>Отправить</Button>}
            {close && <Button link className="ml-2" onClick={close}>Закрыть</Button>}
        </div>
    )
}

function PaymentTypeCheckboxes({paymentType, onSelect}) {
    return (
        <div className="modal-body">
            <Radio inline className="mr-2" name="paymentType" checked={paymentType === BILL_STATE.NOT_ISSUED} value={BILL_STATE.NOT_ISSUED} onChange={onSelect}>
                {BILL_STATE_LABEL.NOT_ISSUED}
            </Radio>
            <Radio inline className="mr-2" name="paymentType" checked={paymentType === PAYMENT_TYPE.GOODWILL} value={PAYMENT_TYPE.GOODWILL} onChange={onSelect}>
                {PAYMENT_TYPE_LABEL.GOODWILL}
            </Radio>
            <Radio inline className="mr-2" name="paymentType" checked={paymentType === PAYMENT_TYPE.CONTRACT} value={PAYMENT_TYPE.CONTRACT} onChange={onSelect}>
                {PAYMENT_TYPE_LABEL.CONTRACT}
            </Radio>
            <Radio inline className="mr-2" name="paymentType" checked={paymentType === PAYMENT_TYPE.WARRANTY} value={PAYMENT_TYPE.WARRANTY} onChange={onSelect}>
                {PAYMENT_TYPE_LABEL.WARRANTY}
            </Radio>
            <Radio inline name="paymentType" checked={paymentType === BILL_STATE.ISSUED} value={BILL_STATE.ISSUED} onChange={onSelect}>
                Счет
            </Radio>
        </div>
    )
}

class ModalPayment extends React.Component {
    static propTypes = {
        report: propTypes.object,
        close: propTypes.func
    };

    state = {
        loading: false, error: '',
        paymentType: this.props.report.paymentType
    };

    submit = async () => {
        try {
            this.setState({loading: true, error: ''});

            const report = {...this.props.report};
            report.paymentType = this.state.paymentType;
            delete report.billId;
            await postReport(report);

            this.props.close();
        } catch (error) {
            this.setState({loading: false, error: error.message})
        }
    };

    onChange = (ev) => this.setState({paymentType: ev.target.value});

    render() {
        const {loading, error, paymentType} = this.state;
        const {report, close} = this.props;

        return (
            <div className="modal-container modal-fullheight">
                <div className="modal-header">
                    <button type="button" className="btn btn-clear float-right" onClick={close}/>
                    <div className="modal-title h5">Вид оплаты</div>
                </div>

                <PaymentTypeCheckboxes paymentType={paymentType} onSelect={this.onChange}/>

                {paymentType === BILL_STATE.ISSUED
                    ? <ModalBill report={report} close={close}/>
                    : <Footer loading={loading} error={error} submit={this.submit} close={close}/>
                }
            </div>
        )
    }
}

class ModalBill extends React.PureComponent {
    static propTypes = {
        report: propTypes.object,
        close: propTypes.func,
    };

    state = {
        action: this.props.report.billId ? 'select' : 'unknown', // 'unknown', 'create', 'select'
    };

    onActionChange = (ev) => this.setState({action: ev.target.dataset.action});

    renderActions() {
        return (
            <React.Fragment>
                <div id="bill-action-choose" className="modal-body">
                    <Button primary data-action="create" onClick={this.onActionChange}>
                        <Icon icon="plus"/> Создать новый
                    </Button>
                    <Button primary data-action="select" onClick={this.onActionChange}>
                        <Icon icon="link"/> Привязать существующий
                    </Button>
                </div>
                <Footer disabled={true} close={this.props.close}/>
            </React.Fragment>
        )
    }

    render() {
        const {report, close} = this.props;
        switch (this.state.action) {
            case 'create': return <NewBillForm close={close} report={report}/>;
            case 'select': return <BillSelect close={close} report={report}/>;
            default: return this.renderActions();
        }
    }
}

class NewBillForm extends React.PureComponent {
    static propTypes = {
        report: propTypes.object,
        close: propTypes.func,
    };

    state = {
        loading: false, error: '',
        bill: {NUM_DOC: "", DATE: "", SUMMA: "", clientId: this.props.report.clientId},
    };

    onChange = (ev) => this.setState({bill: {...this.state.bill, [ev.target.name.slice(5)]: ev.target.value}});

    submit = async () => {
        try {
            this.setState({loading: true, error: ''});

            const bill = this.state.bill;
            const response = await postBill(bill);

            const report = {
                ...this.props.report,
                billId: response.id
            };
            delete report.paymentType;
            await postReport(report);

            this.props.close();
        } catch (error) {
            this.setState({loading: false, error: error.message})
        }
    };

    render() {
        const {loading, error, bill} = this.state;
        const isDisabled = !bill.NUM_DOC || !bill.DATE || !bill.SUMMA || !bill.clientId;

        return (
            <React.Fragment>
                <div className="modal-body">
                    <FormGroup>
                        <Label form htmlFor="bill.NUM_DOC">Номер счета</Label>
                        <Input type="text" name="bill.NUM_DOC" value={bill.NUM_DOC} onChange={this.onChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label form htmlFor="bill.DATE">Дата выставления</Label>
                        <Input type="date" required name="bill.DATE" value={bill.DATE} onChange={this.onChange}/>
                    </FormGroup>
                    <FormGroup>
                        <Label form htmlFor="bill.SUMMA">Сумма счета</Label>
                        <Input type="number" min="1" step="0.01" name="bill.SUMMA" value={bill.SUMMA} onChange={this.onChange}/>
                    </FormGroup>
                </div>

                <Footer error={error} loading={loading} close={this.props.close} disabled={isDisabled} submit={this.submit}/>
            </React.Fragment>
        )
    }
}

class BillSelect extends React.PureComponent {
    static propTypes = {
        close: propTypes.func,
        report: propTypes.object
    };

    state = {
        loading: true, error: '',
        bills: null,
        showAll: false,
        billId: this.props.report.billId
    };

    async componentDidMount() {
        try {
            this.setState({loading: true, error: ''});
            const clients = new Map((await pouch.query('clients/names')).rows.map(row => [row.key, row.value]));
            const bills = (await fetchBills()).map(item => {
                const {_id, numDoc: NUM_DOC, date: DATE, summa: SUMMA, clientId, state} = item;
                return {_id, NUM_DOC, DATE: new Date(DATE), SUMMA, state, clientId, client: clients.get(clientId)};
            });
            this.setState({loading: false, bills});
        } catch (error) {
            this.setState({loading: false, error: error.message});
        }
    }

    billToString(item) {
        return item ? `Счет № ${item.NUM_DOC} от ${formatDate2(item.DATE)} на ${item.SUMMA} р. (${item.client})` : '';
    }

    onChange = (ev) => {
        const value = ev.target.name === "showAll" ? ev.target.checked : ev.target.value._id;
        this.setState({[ev.target.name]: value});
    };

    submit = async () => {
        try {
            this.setState({loading: true, error: ''});

            const report = {...this.props.report};
            report.billId = this.state.billId;
            delete report.paymentType;
            await postReport(report);

            this.props.close();
        } catch (error) {
            this.setState({loading: false, error: error.message})
        }
    };

    render() {
        if (!this.state.bills) {
            if (this.state.loading) return <Loading />;
            if (this.state.error) return <Toast error>{this.state.error}</Toast>;
        }

        const {loading, error, billId, showAll} = this.state;
        const selectedItem = this.state.bills.find(bill => bill._id === billId);
        const clientId = this.props.report.clientId;
        const bills = showAll ? this.state.bills : this.state.bills.filter(bill => bill.clientId === clientId);

        return (
            <React.Fragment>
                <div id="bill-action-select" className="modal-body">
                    <SingleSelect items={bills} itemToString={this.billToString} name="billId" clearable={false}
                                  selectedItem={selectedItem} onChange={this.onChange} searchable={true}
                    />
                    <Checkbox name="showAll" checked={showAll} onChange={this.onChange}>Показать все счета</Checkbox>
                </div>

                <Footer loading={loading} error={error} close={this.props.close} submit={this.submit} disabled={!billId}/>
            </React.Fragment>
        )
    }
}
