import React from 'react';
import {
    startOfMonth, startOfQuarter, startOfYear,
    endOfMonth, endOfQuarter, endOfYear,
    addMonths, addQuarters, addYears,
    lightFormat, format,
    // min as getMinDate, max as getMaxDate,
} from "date-fns";
import ru from "date-fns/locale/ru";
import cx from "classnames";
import PropTypes from "prop-types";
import {Button, Select} from "spectre-react";


class TimeNavigation extends React.Component {
    static propTypes = {
        enabled: PropTypes.bool,
        defaultMode: PropTypes.oneOf(["month", "quarter", "year", "free"]),
        defaultStartDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        defaultEndDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        showSelect: PropTypes.bool,
        small: PropTypes.bool,
        narrow: PropTypes.bool,
        onChange: PropTypes.func,
        // minDate: PropTypes.instanceOf(Date),  // only for month, quarter and year
        // maxDate: PropTypes.instanceOf(Date),  // only for month, quarter and year
    }

    static defaultProps = {
        enabled: true,
        defaultMode: 'month',
        defaultStartDate: startOfMonth(new Date()),
        defaultEndDate: endOfMonth(new Date()),
        showSelect: true,
        small: false,
        narrow: false,
        // maxDate: endOfMonth(new Date()),
        // minDate: startOfMonth(subYears(new Date(), 5)),
    }

    constructor(props) {
        super(props);

        const availableModes = ['month', 'quarter', 'year', 'free'];
        if (!availableModes.includes(props.defaultMode)) {
            throw new Error("`defaultMode` must be 'month', 'quarter', 'year', 'free' or undefined");
        }

        let mode = props.defaultMode,
            startDate = new Date(props.defaultStartDate),
            endDate = new Date(props.defaultEndDate);

        if (mode === 'free') {
            // then defaultStartDate and defaultEndDate are handled by defaultProps
        } else if (mode === 'month') {
            // in all other modes the component considers only defaultStartDate
            startDate = startOfMonth(startDate); endDate = endOfMonth(startDate);
        } else if (mode === 'quarter') {
            startDate = startOfQuarter(startDate); endDate = endOfQuarter(startDate);
        } else if (mode === 'year') {
            startDate = startOfYear(startDate); endDate = endOfYear(startDate);
        }

        // let minDate = getMinDate([props.minDate, startDate]),
        //     maxDate = getMaxDate([props.maxDate, endDate]);

        // TODO: check if correctly handled by defaultProps
        // const enabled = props.enabled === undefined ? true : Boolean(props.enabled);

        this.state = {
            enabled: props.enabled, mode,
            startDate, endDate,
        }
    }

    onChange = () => {
        const {startDate, endDate, mode} = this.state;

        if (this.props.onChange)
            this.props.onChange({startDate, endDate, mode});
    }

    navigate = (value) => {
        let addFunc, endFunc;

        switch (this.state.mode) {
            case 'month':
                addFunc = addMonths; endFunc = endOfMonth; break;
            case 'quarter':
                addFunc = addQuarters; endFunc = endOfQuarter; break;
            case 'year':
                addFunc = addYears; endFunc = endOfYear; break;
            case 'free':
            default:
                return;
        }

        this.setState(({startDate}) => ({
            startDate: addFunc(startDate, value),
            endDate: endFunc(addFunc(startDate, value)),
        }), this.onChange);
    }

    changeMode = (ev) => {
        const mode = ev.target.value;
        let startFunc, endFunc;

        switch (mode) {
            case 'month':
                startFunc = startOfMonth; endFunc = endOfMonth; break;
            case 'quarter':
                startFunc = startOfQuarter; endFunc = endOfQuarter; break;
            case 'year':
                startFunc = startOfYear; endFunc = endOfYear; break;
            case 'free':
                this.setState({mode}); return;
        }

        const startDate = startFunc(this.state.startDate);
        const endDate = endFunc(startDate);
        this.setState({mode, startDate, endDate}, this.onChange);
    }

    navigateBack = (ev) => this.navigate(-1);

    navigateForward = (ev) => this.navigate(+1);

    getCurrentTimespanText() {
        const {mode, startDate} = this.state;

        if (mode === "month")
            return format(startDate, 'LLLL yyyy', {locale: ru});
        else if (mode === "quarter")
            return format(startDate, 'qqqq yyyy', {locale: ru}).replace('квартал', '/');
        else if (mode === "year")
            return format(startDate, 'yyyy', {locale: ru});
    }

    renderTextWithNavigation() {
        // this is month, quarter, year modes
        const small = this.props.small;

        return (
            <span className="time-nav__text">
                <Button action link small={small} onClick={this.navigateBack}>
                    <span className="material-icons">arrow_back</span>
                </Button>
                <span className="time-nav__text-face mx-2">{this.getCurrentTimespanText()}</span>
                <Button action link small={small} onClick={this.navigateForward}>
                    <span className="material-icons">arrow_forward</span>
                </Button>
            </span>
        )
    }

    changeStartDate = (ev) => {
        if (!ev.target.value)
            return;

        const startDate = ev.target.value ? new Date(ev.target.value) : null;
        this.setState({startDate}, this.onChange);
    }

    changeEndDate = (ev) => {
        if (!ev.target.value)
            return;

        const endDate = ev.target.value ? new Date(ev.target.value) : null;
        this.setState({endDate}, this.onChange);
    }

    renderDateInputs() {
        // this is free mode
        const startDate = lightFormat(this.state.startDate, 'yyyy-MM-dd');
        const endDate = lightFormat(this.state.endDate, 'yyyy-MM-dd');

        return (
            <span className="time-nav__inputs">
                <input type="date" value={startDate} onChange={this.changeStartDate}/>
                –
                <input type="date" value={endDate} onChange={this.changeEndDate}/>
            </span>
        )
    }

    render() {
        const mode = this.state.mode;
        const {small, narrow, showSelect} = this.props;

        const wrapperCls = cx("time-nav", {'time-nav-sm': small, 'time-nav-narrow': narrow});

        return (
            <div className={wrapperCls}>
                {showSelect && (
                    <Select small={small} value={mode} onChange={this.changeMode}>
                        <option value='month'>M</option>
                        <option value='quarter'>Q</option>
                        <option value='year'>Y</option>
                        <option value='free'>F</option>
                    </Select>
                )}
                {mode === 'free' && this.renderDateInputs()}
                {mode !== 'free' && this.renderTextWithNavigation()}
            </div>
        );
    }
}


export default TimeNavigation;