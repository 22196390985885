import React from "react";
import cx from "classnames";
import {Button, Loading, Toast} from "spectre-react";
import {pouch} from "../../actions";
import {setInnClientId, setPaymentClientId} from "../../api";
import {SingleSelect} from "../shared/Select";


class SetClientModal extends React.PureComponent {
    getDefaultState() {
        return {
            active: false,
            INN_PL: null, PL_NAME: null, paymentId: null,
            loading: true, error: '',
            clients: null, selectedItem: null,
        };
    }

    state = this.getDefaultState();

    fetchClients = async () => {
        try {
            const response = await pouch.query('clients/names');
            const clients = response.rows.map(row => ({_id: row.key, label: row.value}))
                .sort((a, b) => a.label.localeCompare(b.label));
            this.setState({loading: false, clients});
        } catch (error) {
            this.setState({loading: false, error: error.message});
        }
    };

    open = (INN_PL, PL_NAME, paymentId) => this.setState({active: true, INN_PL, PL_NAME, paymentId}, this.fetchClients);
    close = () => this.setState(this.getDefaultState());

    submit = () => {
        const {INN_PL, paymentId, selectedItem} = this.state;
        const clientId = selectedItem._id;
        this.setState({loading: true});

        const promise = INN_PL
            ? setInnClientId(INN_PL, selectedItem._id)
            : setPaymentClientId(paymentId, clientId);

        promise
            .then(this.close)
            .then(this.props.reloadData)
            .catch(error => this.setState({loading: false, error: error.message}))
    };

    onItemSelect = (ev) => this.setState({selectedItem: ev.target.value});

    renderBody() {
        if (this.state.loading)
            return (
                <div className="modal-body">
                    <Loading large />
                </div>
            );

        if (this.state.error)
            return (
                <div className="modal-body">
                    <Toast error>{this.state.error}</Toast>
                </div>
            );

        const {INN_PL, PL_NAME, clients} = this.state;
        return (
            <div className="modal-body">
                {INN_PL && <p>ИНН: {INN_PL}</p>}
                <p>Плательщик: {PL_NAME}</p>
                <SingleSelect items={clients} onChange={this.onItemSelect} name="selectedItem"
                              searchable={true} clearable={false}
                />
            </div>
        )
    }

    renderHeader() {
        const title = 'Указать клиента ' + (this.state.INN_PL ? 'для ИНН' : 'для платежа');

        return (
            <div className="modal-header">
                <span className="btn btn-clear float-right" onClick={this.close}/>
                <div className="modal-title h5">{title}</div>
            </div>
        )
    }

    renderFooter() {
        return (
            <div className="modal-footer">
                <Button primary disabled={!this.state.selectedItem || this.state.loading} className="mr-2" onClick={this.submit}>Сохранить</Button>
                <Button link onClick={this.close}>Закрыть</Button>
            </div>
        )
    }

    render() {
        if (!this.state.active)
            return null;

        return (
            <div id="inn-modal" className="modal active">
                <span className="modal-overlay" onClick={this.close}/>
                <div className="modal-container">
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderFooter()}
                </div>
            </div>
        )
    }
}

export default SetClientModal;