import React from 'react';
import {connect} from 'react-redux';
import cx from "classnames";
import {lightFormat, getYear, isValid, startOfQuarter, endOfQuarter} from 'date-fns';
import {FormGroup, Loading, Radio, Divider, Checkbox, Toast} from "spectre-react";
import WagesParamsDemonstration from "./WagesParamsDemonstration";
import TimeNavigation from "../shared/TimeNavigation";
import rubFmt from "../shared/summaFormatter";
import {getWagesEngineersTablePageData} from "../../api";
import {pluralize, roundToOne, roundToTwo} from "../shared/sharedImport";
import {setWagesEngineersTablePageFilter,} from "../../actions";


class WagesEngineersTablePage extends React.PureComponent {
    static propTypes = {};

    state = {
        isLoading: true,
        loadingError: undefined,

        engineerNames: {},

        // startDate: lightFormat(startOfQuarter(new Date()), 'yyyy-MM-dd'),
        // endDate: lightFormat(endOfQuarter(new Date()), 'yyyy-MM-dd'),
        //
        // displayType: "twoTables",
        // showStats: true,

        engineersPersonalStats: undefined,
        engineersTotalStats: undefined,
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const entries = nextProps.engineers.map(({_id, nameLF}) => [_id, nameLF]);
        const engineerNames = Object.fromEntries(entries);

        return {engineerNames};
    }

    componentDidMount() {
        this.fetchEngineersStats();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.pageFilters.startDate !== this.props.pageFilters.startDate ||
            prevProps.pageFilters.endDate !== this.props.pageFilters.endDate
        )
            this.fetchEngineersStats();
    }

    fetchEngineersStats = async () => {
        if (!this.areStartAndEndDateValid())
            return;

        try {
            this.setState({isLoading: true, loadingError: undefined});
            const {startDate, endDate} = this.props.pageFilters;
            const {engineersStats, totalData} = await getWagesEngineersTablePageData(startDate, endDate);
            this.setState({isLoading: false, engineersPersonalStats: engineersStats, engineersTotalStats: totalData});
        } catch (error) {
            console.error(error);
            this.setState({isLoading: false, loadingError: error});
        }
    };


    onTimespanChange = ({startDate, endDate, mode}) => {
        startDate = lightFormat(startDate, 'yyyy-MM-dd');
        endDate = lightFormat(endDate, 'yyyy-MM-dd');
        this.props.setPageFilters({startDate, endDate, timespanMode: mode});
    }

    onDisplayTypeChange = (ev) => {
        this.props.setPageFilters({displayType: ev.target.value});
    }

    onShowStatsChange = (ev) => { this.props.setPageFilters({showStats: ev.target.checked}); }


    areStartAndEndDateValid() {
        const {startDate, endDate} = this.props.pageFilters;

        if (!startDate || !endDate)
            return false;

        const start = new Date(startDate), end = new Date(endDate);

        if (!isValid(start) || !isValid(end))
            return false;

        return start <= end &&
            2010 <= getYear(start) && getYear(start) <= 2030 &&
            2010 <= getYear(end) && getYear(end) <= 2030;
    }


    renderStatsHeaderCells() {
        return (
            <>
                <th>В отпуске</th>
                <th>Занесено</th>
                <th>Занятость</th>
                <th>Рабочих</th>
                <th>На выездах</th>
                <th>В командировках</th>
                <th>В СПб</th>
                <th>В нерабочее</th>
                <th>Овертайм</th>
            </>
        )
    }

    renderStatsBodyCells(engineerId) {
        const data = this.state.engineersPersonalStats[engineerId];

        return (
            <>
                <td className="text-center">
                    {data.vacation.days} <span className="text-tiny text-italic">д.</span></td>
                <td className="text-center">
                    {data.total.reports} <span className="text-tiny text-italic">{pluralize(data.total.reports, 'репортов', 'репорт', 'репорта', 'репортов')}</span><br/>
                    {data.inTrips.reports} <span className="text-tiny text-italic">{pluralize(data.inTrips.reports, 'командировок', 'командировка', 'командировки', 'командировок')}</span></td>
                <td className="text-center">
                    {roundToTwo(data.total.daysCoeff)} <span className="text-tiny text-italic">по д.</span><br/>
                    {roundToTwo(data.total.hoursCoeff)} <span className="text-tiny text-italic">по ч.</span></td>
                <td className="text-center">
                    {roundToOne(data.period.workingDays)} <span className="text-tiny text-italic">д.</span><br/>
                    {roundToOne(data.period.workingHours)} <span className="text-tiny text-italic">ч.</span></td>
                <td className="text-center">
                    {roundToOne(data.total.days)} <span className="text-tiny text-italic">д.</span><br/>
                    {roundToOne(data.total.hours)} <span className="text-tiny text-italic">ч.</span></td>
                <td className="text-center">
                    {roundToOne(data.inTrips.days)} <span className="text-tiny text-italic">д.</span><br/>
                    {roundToOne(data.inTrips.hours)} <span className="text-tiny text-italic">ч.</span></td>
                <td className="text-center">
                    {roundToOne(data.homeCity.days)} <span className="text-tiny text-italic">д.</span><br/>
                    {roundToOne(data.homeCity.hours)} <span className="text-tiny text-italic">ч.</span></td>
                <td className="text-center">
                    {roundToOne(data.onWeekend.days)} <span className="text-tiny text-italic">д.</span><br/>
                    {roundToOne(data.onWeekend.hours)} <span className="text-tiny text-italic">ч.</span></td>
                <td className="text-center">
                    {roundToOne(data.overtime.days)} <span className="text-tiny text-italic">д.</span><br/>
                    {roundToOne(data.overtime.hours)} <span className="text-tiny text-italic">ч.</span></td>
            </>
        );
    }


    renderFirstTableRow(engineerId) {
        const data = this.state.engineersPersonalStats[engineerId];
        const name = this.state.engineerNames[engineerId];

        if (!data)
            return null;

        const {displayType, showStats} = this.props.pageFilters;

        const shortName = name.slice(0, name.indexOf(' ') + 2) + '.';
        const vacationPay = rubFmt(data.vacation.payAmount);
        const homePay = rubFmt(data.home.payAmount);
        const officePay = rubFmt(data.office.payAmount);
        const homeCityPay = rubFmt(data.homeCity.payAmount);
        const tripsPay = rubFmt(data.inTrips.payAmount);
        const travelPay = rubFmt(data.travel.payAmount);
        const totalPay = rubFmt(data.total.payAmount);
        const totalMeanPay = rubFmt(data.total.meanPayAmount);

        return (
            <tr key={engineerId} data-engineer-id={engineerId}>
                <td className="text-bold text-uppercase text-nowrap">{shortName}</td>
                <td className="text-center">{vacationPay}</td>
                <td className="text-center">{homePay}</td>
                <td className="text-center">{officePay}</td>
                <td className="text-right">{homeCityPay}</td>
                <td className="text-right">{tripsPay}</td>
                <td className="text-right">{travelPay}</td>
                <td className="text-right">{totalPay}</td>
                <td className="text-right">{totalMeanPay}</td>
                {displayType === "oneTable" && showStats && this.renderStatsBodyCells(engineerId)}
            </tr>
        )
    }

    renderFirstTableTotalRow() {
        const totalData = this.state.engineersTotalStats;
        const {displayType, showStats} = this.props.pageFilters;

        const homePayString = rubFmt(totalData.homePay);
        const officePayString = rubFmt(totalData.officePay);
        const homeCityPayString = rubFmt(totalData.homeCityPay);
        const tripsPayString = rubFmt(totalData.tripsPay);
        const travelPayString = rubFmt(totalData.travelPay);
        const vacationPayString = rubFmt(totalData.vacationPay);
        const totalPayString = rubFmt(totalData.totalPay);
        const totalMeanPayString = rubFmt(totalData.totalMeanPay);

        return (
            <tr className="bg-dawn-pink">
                <td>Итого:</td>
                <td className="text-center">{vacationPayString}</td>
                <td className="text-center">{homePayString}</td>
                <td className="text-center">{officePayString}</td>
                <td className="text-right">{homeCityPayString}</td>
                <td className="text-right">{tripsPayString}</td>
                <td className="text-right">{travelPayString}</td>
                <td className="text-right">{totalPayString}</td>
                <td className="text-right">{totalMeanPayString}</td>
                {displayType === "oneTable" && showStats && <><td/><td/><td/><td/><td/><td/><td/><td/></>}
            </tr>
        );
    }


    renderSecondTableRow(engineerId) {
        const data = this.state.engineersPersonalStats[engineerId];

        if (!data)
            return null;

        const name = this.state.engineerNames[engineerId];
        const shortName = name.slice(0, name.indexOf(' ') + 2) + '.';

        return (
            <tr key={engineerId} data-engineer-id={engineerId}>
                <td className="text-bold text-uppercase text-nowrap">{shortName}</td>
                {this.renderStatsBodyCells(engineerId)}
            </tr>
        );
    }


    renderParams() {
        const {startDate, endDate, displayType, showStats, timespanMode} = this.props.pageFilters;

        return (
            <div className="columns">
                <div className="column col-4 col-lg-12">
                    <FormGroup>
                        <TimeNavigation defaultMode={timespanMode} onChange={this.onTimespanChange} defaultStartDate={startDate} defaultEndDate={endDate} />
                    </FormGroup>
                </div>

                <div className="column col-2 col-lg-12"></div>

                <div className="column col-3 col-lg-12 d-flex align-end">
                    <FormGroup>
                        <Radio inline name="displayType" value="oneTable" onChange={this.onDisplayTypeChange} checked={displayType === "oneTable"} disabled={!showStats}>
                            Одна таблица
                        </Radio>
                        <Radio inline name="displayType" value="twoTables" onChange={this.onDisplayTypeChange} checked={displayType === "twoTables"} disabled={!showStats}>
                            Две таблицы
                        </Radio>
                    </FormGroup>
                </div>
                <div className="column col-3 col-lg-12 d-flex align-end">
                    <FormGroup>
                        <Checkbox name="showStats" onChange={this.onShowStatsChange} checked={showStats}>
                            Отобразить статистику
                        </Checkbox>
                    </FormGroup>
                </div>
            </div>
        )
    }

    render() {
        const {isLoading, loadingError} = this.state;
        const isFirstPageLoad = !this.state.engineersPersonalStats;

        if (isLoading && isFirstPageLoad)
            return <Loading large />;

        if (loadingError)
            return <Toast error>{loadingError.toString()}</Toast>;

        const {engineerNames} = this.state;
        const {showStats, displayType} = this.props.pageFilters;

        const engineers = Object.entries(engineerNames)
            .map(([_id, name]) => ({_id, name}))
            .sort((a, b) => a.name.localeCompare(b.name));

        const firstTableClassNames = cx({'table': true, 'table-striped': true, 'table-1600': displayType === "oneTable" && showStats});

        return (
            <div className="container" id="wages-engineers-table-page">
                <div className="columns">
                    <div className="column col-12">
                        <WagesParamsDemonstration />
                    </div>
                    <div className="column col-12">
                        <Divider />
                    </div>
                    <div className="column col-12">
                        {this.renderParams()}
                    </div>
                </div>

                <Divider />

                <table className={firstTableClassNames}>
                    <thead>
                        <tr className="text-tiny text-center">
                            <th>{isLoading && !isFirstPageLoad && <Loading />}</th>
                            <th>Отпускные</th>
                            <th>Дом</th>
                            <th>Офис</th>
                            <th>СПб</th>
                            <th>Командировки</th>
                            <th>Дорога (расч.)</th>
                            <th>Всего</th>
                            <th>Всего ср-мес</th>
                            {displayType === "oneTable" && showStats && this.renderStatsHeaderCells()}
                        </tr>
                    </thead>
                    <tbody>
                    {engineers.map(({_id}) => this.renderFirstTableRow(_id))}
                    {this.renderFirstTableTotalRow()}
                    </tbody>
                </table>

                <div className="divider my-4"/>

                {displayType === "twoTables" && showStats &&
                    <table className="table table-striped">
                        <thead>
                            <tr className="text-tiny text-center">
                                <th>{isLoading && !isFirstPageLoad && <Loading />}</th>
                                {this.renderStatsHeaderCells()}
                            </tr>
                        </thead>
                        <tbody>
                        {engineers.map(({_id: engineerId}) => this.renderSecondTableRow(engineerId))}
                        </tbody>
                    </table>
                }
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        engineers: state.engineers,
        pageFilters: state.wagesEngineersTablePage,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    setPageFilters: (state) => dispatch(setWagesEngineersTablePageFilter(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(WagesEngineersTablePage);
