import React from 'react';
import cx from 'classnames';
import {Button, FormGroup, Label, Radio, Toast} from "spectre-react";
import {ModalContainerError, ModalContainerLoading} from "../shared/Modals";
import {getReport, postReport} from '../../api';


export default class extends React.PureComponent {
    getDefaultState() { return {opened: false, loading: '', error: '', report: null} }
    state = this.getDefaultState();

    open = (reportId) => {
        this.setState({opened: true, loading: true, error: ''});
        getReport(reportId)
            .then(report => {
                if (!report.notes) report.notes = {comment: '', manScore: '', clScore: ''};
                this.setState({loading: false, report});
            })
            .catch(error => this.setState({loading: false, error: error.message}));
    };
    close = () => this.setState(this.getDefaultState());

    onChange = (ev) => {
        const notes = {...this.state.report.notes, [ev.target.name]: ev.target.value};
        const report = {...this.state.report, notes};
        this.setState({report, error: ''});
    };

    submit = () => {
        const notes = {...this.state.report.notes};
        if (!notes.comment) delete notes.comment;
        if (!notes.clScore) delete notes.clScore;
        if (!notes.manScore) delete notes.manScore;

        const report = {...this.state.report, notes};
        if (Object.keys(notes).length === 0) delete report.notes;

        this.setState({loading: true, error: ''});
        postReport(report)
            .then(this.close)
            .catch(error => this.setState({loading: false, error: error.message}))
    };

    renderContainer() {
        const {loading, error, report} = this.state;

        if (!report) {
            if (loading) return <ModalContainerLoading close={this.close}/>;
            if (error) return <ModalContainerError error={error} close={this.close}/>;
            return null;
        } else {
            return <ModalNotes loading={loading} error={error} notes={report.notes}
                                onChange={this.onChange} close={this.close} submit={this.submit}/>;
        }
    }

    render() {
        if (!this.state.opened) return null;

        return (
            <div className="modal active" id="report-notes-modal">
                <span className="modal-overlay" onClick={this.close}/>
                {this.renderContainer()}
            </div>
        )
    }
}


class ModalNotes extends React.Component {
    renderRadios = (name, value, onChange) => {
        return (
            <div className="col-7 col-sm-12">
                <Radio inline name={name} value="" checked={!value} onChange={onChange}>Нету</Radio>
                <Radio inline name={name} value="1" checked={value === "1"} onChange={onChange}>1</Radio>
                <Radio inline name={name} value="2" checked={value === "2"} onChange={onChange}>2</Radio>
                <Radio inline name={name} value="3" checked={value === "3"} onChange={onChange}>3</Radio>
                <Radio inline name={name} value="4" checked={value === "4"} onChange={onChange}>4</Radio>
                <Radio inline name={name} value="5" checked={value === "5"} onChange={onChange}>5</Radio>
            </div>
        )
    };

    render() {
        const {loading, error, notes, onChange, close, submit} = this.props;
        const {comment, clScore, manScore} = notes;

        return (
            <div className="modal-container modal-fullheight">
                <div className="modal-header">
                    <button type="button" className="btn btn-clear float-right" onClick={close}/>
                    <div className="modal-title h5">Заметки</div>
                </div>

                <div className="modal-body form-horizontal">
                    <FormGroup>
                        <div className="col-12">
                            <Label form>Комментарий</Label>
                        </div>
                        <div className="col-12">
                            <textarea className="form-input" name="comment" onChange={onChange} value={comment}/>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <div className="col-5 col-sm-12">
                            <Label form>Оценка работ клиентом</Label>
                        </div>

                        {this.renderRadios("clScore", clScore, onChange)}
                    </FormGroup>

                    <FormGroup>
                        <div className="col-5 col-sm-12">
                            <Label form>Оценка работ руководителем</Label>
                        </div>

                        {this.renderRadios("manScore", manScore, onChange)}
                    </FormGroup>
                </div>

                <div className="modal-footer">
                    {error && <Toast error className="mb-2">{error}</Toast>}

                    <Button primary loading={loading} onClick={submit}>Отправить</Button>
                    <Button link onClick={close}>Закрыть</Button>
                </div>
            </div>
        )
    }
}