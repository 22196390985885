import React from 'react';
import {Checkbox, Divider, FormGroup, Label, Loading, Radio, Input, Toast, Icon} from "spectre-react";
import {ENGINEER_CATEGORY, MACHINE_COEFF_GROUP_LABEL, WORK_TYPE_LABEL} from "../shared/sharedImport";
import {fetchWagesParamsLast} from "../../api";
import {connect} from "react-redux";


const Accordion = ({title, children}) => {
    return (
        <details className="accordion">
            <summary className="accordion-header c-hand">
                <Icon icon="arrow-right" className="mr-1"/> {title}
            </summary>
            <div className="accordion-body">
                {children}
            </div>
        </details>
    )
}


class WagesParamsDemonstration extends React.PureComponent {
    state = {
        fetchState: 0, fetchError: undefined,
        calcParams: undefined
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({fetchState: 1, fetchError: undefined});

        try {
            const {doc} = await fetchWagesParamsLast();
            this.setState({fetchState: 2, calcParams: doc.params});
        } catch (error) {
            console.error(error);
            this.setState({fetchState: 0, fetchError: error.message});
        }
    }

    renderGroup1 = () => {
        const calcParams = this.state.calcParams;

        return (
            <React.Fragment>
                <FormGroup>
                    <Label form small htmlFor="basePrice">Базовая цена</Label>
                    <Input readOnly small type="text" name="basePrice" id="basePrice" placeholder="1250р" defaultValue={calcParams.basePrice}/>
                </FormGroup>
                <Divider />
                <FormGroup>
                    <Label form small htmlFor="workFromHomeCoeff">Работа из дома</Label>
                    <Input readOnly small type="text" name="workFromHomeCoeff" id="workFromHomeCoeff" placeholder="0.8" defaultValue={calcParams.workFromHomeCoeff}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="workFromOfficeCoeff">Работа из офиса</Label>
                    <Input readOnly small type="text" name="workFromOfficeCoeff" id="workFromOfficeCoeff" placeholder="0.8" defaultValue={calcParams.workFromOfficeCoeff}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="workInHomeCityCoeff">Работа из СПб</Label>
                    <Input readOnly small type="text" name="workInHomeCityCoeff" id="workInHomeCityCoeff" placeholder="1" defaultValue={calcParams.workInHomeCityCoeff}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="workInOtherCityCoeff">Работа в командировках</Label>
                    <Input readOnly small type="text" name="workInOtherCityCoeff" id="workInOtherCityCoeff" placeholder="1.2" defaultValue={calcParams.workInOtherCityCoeff}/>
                </FormGroup>
            </React.Fragment>
        )
    }

    renderGroup2 = () => {
        const calcParams = this.state.calcParams;

        return (
            <React.Fragment>
                <FormGroup>
                    <Label form small htmlFor="workdayUpTo8Coeff">Рабочий день до 8 часов</Label>
                    <Input readOnly small type="text" name="workdayUpTo8Coeff" id="workdayUpTo8Coeff" placeholder="1" defaultValue={calcParams.workdayUpTo8Coeff}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="workdayMoreThan8Coeff">Рабочий день свыше 8 часов</Label>
                    <Input readOnly small type="text" name="workdayMoreThan8Coeff" id="workdayMoreThan8Coeff" placeholder="1.5" defaultValue={calcParams.workdayMoreThan8Coeff}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="weekendUpTo8Coeff">Нерабочий день до 8 часов</Label>
                    <Input readOnly small type="text" name="weekendUpTo8Coeff" id="weekendUpTo8Coeff" placeholder="1.5" defaultValue={calcParams.weekendUpTo8Coeff}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="weekendMoreThan8Coeff">Нерабочий день свыше 8 часов</Label>
                    <Input readOnly small type="text" name="weekendMoreThan8Coeff" id="weekendMoreThan8Coeff" placeholder="2" defaultValue={calcParams.weekendMoreThan8Coeff}/>
                </FormGroup>
                <Divider />
                <FormGroup>
                    <Label form small htmlFor="travelWorktimeCoeff">Дорога в рабочее время 10-19 | {"<="} 8</Label>
                    <Input readOnly small type="text" name="travelWorktimeCoeff" id="travelWorktimeCoeff" placeholder="1.2" defaultValue={calcParams.travelWorktimeCoeff}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="travelNonWorktimeCoeff">Дорога в нерабочее время | {">"} 8</Label>
                    <Input readOnly small type="text" name="travelNonWorktimeCoeff" id="travelNonWorktimeCoeff" placeholder="1.2" defaultValue={calcParams.travelNonWorktimeCoeff}/>
                </FormGroup>
            </React.Fragment>
        )
    }

    renderGroup3 = () => {
        const calcParams = this.state.calcParams;

        return (
            <React.Fragment>
                <FormGroup>
                    <Label form small htmlFor="machinesCoeffs.gammaPictocer">{MACHINE_COEFF_GROUP_LABEL.GAMMA__PICTOCER}</Label>
                    <Input readOnly small type="text" name="machinesCoeffs.gammaPictocer" id="machinesCoeffs.gammaPictocer" placeholder="1" defaultValue={calcParams.machinesCoeffs?.GAMMA__PICTOCER}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="machinesCoeffs.rhotexP5250HS">{MACHINE_COEFF_GROUP_LABEL.RHOTEX__P5_250_HS}</Label>
                    <Input readOnly small type="text" name="machinesCoeffs.rhotexP5250HS" id="machinesCoeffs.rhotexP5250HS" placeholder="1" defaultValue={calcParams.machinesCoeffs?.RHOTEX__P5_250_HS}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="machinesCoeffs.mutoh">{MACHINE_COEFF_GROUP_LABEL.MUTOH}</Label>
                    <Input readOnly small type="text" name="machinesCoeffs.mutoh" id="machinesCoeffs.mutoh" placeholder="1" defaultValue={calcParams.machinesCoeffs?.MUTOH}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="machinesCoeffs.rest">{MACHINE_COEFF_GROUP_LABEL.REST}</Label>
                    <Input readOnly small type="text" name="machinesCoeffs.rest" id="machinesCoeffs.rest" placeholder="1" defaultValue={calcParams.machinesCoeffs?.REST}/>
                </FormGroup>
            </React.Fragment>
        )
    }

    renderGroup4 = () => {
        const calcParams = this.state.calcParams;

        return (
            <React.Fragment>
                <FormGroup>
                    <Label form small htmlFor="workTypeCoeffs.INSTALL">{WORK_TYPE_LABEL.INSTALL}</Label>
                    <Input readOnly small type="text" name="workTypeCoeffs.INSTALL" id="workTypeCoeffs.INSTALL" placeholder="1" defaultValue={calcParams.workTypeCoeffs?.INSTALL}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="workTypeCoeffs.TEACH">{WORK_TYPE_LABEL.TEACH}</Label>
                    <Input readOnly small type="text" name="workTypeCoeffs.TEACH" id="workTypeCoeffs.TEACH" placeholder="1" defaultValue={calcParams.workTypeCoeffs?.TEACH}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="workTypeCoeffs.MAINTENANCE">{WORK_TYPE_LABEL.MAINTENANCE}</Label>
                    <Input readOnly small type="text" name="workTypeCoeffs.MAINTENANCE" id="workTypeCoeffs.MAINTENANCE" placeholder="1" defaultValue={calcParams.workTypeCoeffs?.MAINTENANCE}/>
                </FormGroup>
                <FormGroup>
                    <Label form small htmlFor="workTypeCoeffs.SLOT">{WORK_TYPE_LABEL.SLOT}</Label>
                    <Input readOnly small type="text" name="workTypeCoeffs.SLOT" id="workTypeCoeffs.SLOT" placeholder="1" defaultValue={calcParams.workTypeCoeffs?.SLOT}/>
                </FormGroup>
            </React.Fragment>
        )
    }

    renderGroup5 = () => {
        const calcParams = this.state.calcParams;
        const engineers = this.props.engineers;

        return (
            <React.Fragment>
                <div className="form-horizontal">
                    {engineers.map(engineer => {
                        return (
                            <FormGroup key={engineer._id}>
                                <div className="col-4 col-lg-12">
                                    <Label form small
                                           htmlFor={`engineerCoeffs.${engineer._id}`}>
                                        {engineer.nameLfp0}
                                    </Label>
                                </div>
                                <div className="col-8 col-lg-12 d-flex">
                                    <Input readOnly small className="form-inline col-3 mr-2" type="text" placeholder="1"
                                           name={`engineerCoeffs.${engineer._id}`}
                                           id={`engineerCoeffs.${engineer._id}`}
                                           defaultValue={calcParams.engineerCoeffs[engineer._id]}
                                    />
                                    <Radio inline small disabled value="SHIFT"
                                           name={`engineerPayrollMethods.${engineer._id}`}
                                           id={`engineerPayrollMethods.${engineer._id}.shift`}
                                           defaultChecked={calcParams.engineerPayrollMethods[engineer._id] === "SHIFT"}>
                                        см.
                                    </Radio>
                                    <Radio inline small disabled value="WORKTIME"
                                           name={`engineerPayrollMethods.${engineer._id}`}
                                           id={`engineerPayrollMethods.${engineer._id}.worktime`}
                                           defaultChecked={calcParams.engineerPayrollMethods[engineer._id] === "WORKTIME"}>
                                        10-19
                                    </Radio>
                                </div>
                            </FormGroup>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }

    renderForEngineers() {
        return (
            <form id="wages-params-form" className="columns">
                <div className="column col-4 col-lg-12">
                    <Accordion title="Коэффициенты 1">
                        {this.renderGroup1()}
                    </Accordion>
                </div>
                <div className="column col-4 col-lg-12">
                    <Accordion title="Коэффициенты 2">
                        {this.renderGroup2()}
                    </Accordion>
                </div>
                <div className="column col-4 col-lg-12">
                    <Accordion title="Коэффициенты 3">
                        {this.renderGroup3()}
                    </Accordion>
                </div>
            </form>
        )
    }

    renderForManagers() {
        const calcParams = this.state.calcParams;

        return (
            <form id="wages-params-form" className="columns">
                <div className="column col-2 col-lg-12">
                    <Accordion title="Коэффициенты 1">
                        {this.renderGroup1()}
                    </Accordion>
                </div>
                <div className="column col-3 col-lg-12">
                    <Accordion title="Коэффициенты 2">
                        {this.renderGroup2()}
                    </Accordion>
                </div>
                <div className="column col-2 col-lg-12">
                    <Accordion title="Коэффициенты 3">
                        <FormGroup>
                            <Divider />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox disabled name="machinesCoeffsEnabled" checked={calcParams.machinesCoeffsEnabled}>
                                Коэффициенты 3 включены
                            </Checkbox>
                        </FormGroup>
                        <FormGroup>
                            <Divider />
                        </FormGroup>
                        {this.renderGroup3()}
                    </Accordion>
                </div>
                <div className="column col-2 col-lg-12">
                    <Accordion title="Коэффициенты 4">
                        <FormGroup>
                            <Divider />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox disabled name="workTypeCoeffsEnabled" checked={calcParams.workTypeCoeffsEnabled}>
                                Коэффициенты 4 включены
                            </Checkbox>
                        </FormGroup>
                        <FormGroup>
                            <Divider />
                        </FormGroup>
                        {this.renderGroup4()}
                    </Accordion>
                </div>
                <div className="column col-3 col-lg-12">
                    <Accordion title="Коэффициенты 5">
                        <FormGroup>
                            <Divider />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox disabled name="engineerCoeffsEnabled" checked={calcParams.engineerCoeffsEnabled}>
                                Коэффициенты 5 включены
                            </Checkbox>
                        </FormGroup>
                        <FormGroup>
                            <Divider />
                        </FormGroup>
                        {this.renderGroup5()}
                    </Accordion>
                </div>
            </form>
        )
    }

    render() {
        const {fetchState, fetchError} = this.state;

        if (fetchError)
            return <Toast error>{fetchError}</Toast>;

        if (fetchState !== 2)
            return <Loading large />;

        if (this.props.currentUser.isManagement)
            return this.renderForManagers();
        else
            return this.renderForEngineers();
    }
}


const mapStateToProps = (state) => {
    return {
        engineers: state.engineersByCategory[ENGINEER_CATEGORY.ACTIVE],
        currentUser: state.currentUser,
    };
};


export default connect(mapStateToProps, null)(WagesParamsDemonstration);