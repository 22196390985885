import React from 'react';
import {connect} from 'react-redux';
import {
    addDays, addMonths, addYears,
    eachDayOfInterval, endOfMonth,
    endOfYear,
    getYear,
    isSameDay, isSameYear,
    lightFormat,
    parseISO, startOfDay,
    startOfMonth,
    startOfYear, subMonths, subYears
} from "date-fns";
import {getCalendarDayActions, getCalendarData} from "../api";
import {Calendar, CalendarControls} from './CalendarPage/YearCalendar';
import AffectingOverridesLog from "./CalendarPage/AffectingOverridesLog";
import OverridesLog from "./CalendarPage/OverridesLog";
import ManagerActions from "./CalendarPage/ManagerActions";
import StatusLegend from "./CalendarPage/StatusLegend";
import MonthCalendar from "./CalendarPage/MonthCalendar";
import EngineerActions from "./CalendarPage/EngineerActions";
import {transformDayStatusToCSSClass} from "./CalendarPage/shared";
import {ENGINEER_CATEGORY} from "./shared/sharedImport";
import DayContent from "./CalendarPage/DayContent";
import {Loading} from "spectre-react";

class CalendarPage extends React.Component {
    constructor(props) {
        super(props);

        // const customCSSclasses = {
        //     holidays: ['2023-04-25', '2023-05-01', '2023-06-02', '2023-08-15', '2023-11-01'],
        //     spring: {
        //         start: '2023-03-21',
        //         end: '2023-06-20'
        //     },
        //     summer: {
        //         start: '2023-06-21',
        //         end: '2023-09-22'
        //     },
        //     autumn: {
        //         start: '2023-09-23',
        //         end: '2023-12-21'
        //     },
        //     weekend: 'Sat,Sun',
        //     winter: day => isBefore(day, new Date(2023, 2, 21)) || isAfter(day, new Date(2023, 11, 21))
        // };

        // alternatively, customClasses can be a function accepting a moment object. For example:
        // day => (day.isBefore(moment([day.year(),2,21])) || day.isAfter(moment([day.year(),11,21]))) ? 'winter': 'summer'

        this.today = startOfDay(new Date());

        const selectedEngineer = props.selectEngineerItems.find(item => item._id === props.currentUser._id) || props.selectEngineerItems[0];
        // const selectedEngineer = props.selectEngineerItems[2];

        this.state = {
            isLoading: true,

            startDate: startOfMonth(addMonths(this.today,-1)),
            endDate: endOfMonth(this.today, -1),

            selectedDay: this.today,
            selectedRange: [this.today, addDays(this.today, 5)],
            selectRange: false,
            customCSSclasses: {},

            selectedEngineer: selectedEngineer,
            selectedCalendarType: 'MONTH',
            timeline: {},
            overrides: [],
            payAmountsByMonth: {},
            availableStatuses: [],
        };
    }

    componentDidMount = () => {
        this.fetchData()
            .then(() => this.fetchAvailableActions());
    }

    fetchData = async () => {
        try {
            if (!this.state.selectedEngineer)
                return;

            this.setState({isLoading: true});

            const startDate = lightFormat(this.state.startDate, 'yyyy-MM-dd');
            const endDate = lightFormat(this.state.endDate, 'yyyy-MM-dd');
            const selectedEngineer = this.state.selectedEngineer;
            const data = await getCalendarData(startDate, endDate, selectedEngineer._id);
            let {timeline, overrides, payAmountsByMonth} = data;

            const customCSSclasses = {
                'vacationPaid': [],
                'vacationUnpaid': [],
                'timeOffPaid': [],
                'timeOffUnpaid': [],
                'workFromHome': [],
                'workFromHomeNWD': [],
                'workFromOffice': [],
                'workFromOfficeNWD': [],
                'fieldWorkInHomeCity': [],
                'fieldWorkInHomeCityNWD': [],
                'fieldWorkInOtherCity': [],
                'fieldWorkInOtherCityNWD': [],
                'illness': [],
                'nonWorkDay': [],
                'badge': [],
                'today': day => isSameDay(day, this.today),
            };

            for (const day in timeline) {
                const dayProps = timeline[day];
                const status = dayProps.status;
                const cssClass = transformDayStatusToCSSClass(status);

                if (cssClass)
                    customCSSclasses[cssClass].push(day);
                if (dayProps.statusOverride)
                    customCSSclasses.badge.push(day);
                if (!dayProps.isWorkday)
                    customCSSclasses.nonWorkDay.push(day);

                dayProps.date = parseISO(day);
            }

            overrides = overrides.map(item => {
                const affectedDatesISO = item.affectedDates;
                const affectedDates = item.affectedDates.map(date => parseISO(date));
                const affectedDatesRU = affectedDates.map(date => lightFormat(date, 'dd.MM.yyyy'));
                const createdAt = parseISO(item.createdAt);
                const iconClassName = transformDayStatusToCSSClass(item.statusOverride);
                return { ...item, affectedDates, affectedDatesISO, affectedDatesRU, createdAt, iconClassName };
            })

            this.setState({customCSSclasses, timeline, overrides, payAmountsByMonth, isLoading: false});
        } catch (error) {
            console.error(error);
        }
    }

    fetchAvailableActions = async () => {
        const timeline = this.state.timeline;
        const selectedDay = lightFormat(this.state.selectedDay, 'yyyy-MM-dd');

        let days = [{
            date: selectedDay,
            status: timeline[selectedDay].statusOverride || timeline[selectedDay].defaultStatus
        }];

        if (this.state.selectRange) {
            const [start, end] = this.state.selectedRange;

            days = eachDayOfInterval({start, end});
            days = days.map(date => {
                date = lightFormat(date, 'yyyy-MM-dd');
                return ({
                    date: date,
                    status: timeline[date].statusOverride || timeline[date].defaultStatus
                });
            });
        }

        const selectedEngineerId = this.state.selectedEngineer._id;
        const availableStatuses = await getCalendarDayActions(selectedEngineerId, days);
        this.setState({availableStatuses});
    }

    changeCalendarType = (value) => {
        if (value === "YEAR") {
            this.setState({
                selectRange: false,
                // selectedRange: [this.today, addDays(this.today, 5)],
                selectedCalendarType: 'YEAR',
                startDate: startOfYear(this.state.startDate),
                endDate: endOfYear(this.state.startDate),
            }, this.fetchData);
        } else if (value === "MONTH") {
            const referenceDate = isSameYear(this.today, this.state.startDate) ? subMonths(this.today, 1) : this.state.startDate;
            this.setState({
                selectRange: false,
                // selectedRange: [this.today, addDays(this.today, 5)],
                selectedCalendarType: 'MONTH',
                startDate: startOfMonth(referenceDate),
                endDate: endOfMonth(addMonths(referenceDate, 1))
            }, this.fetchData);
        }
    };

    toggleSelectRange = () => this.setState(prevState => ({ selectRange: !prevState.selectRange }));

    changeEngineer = (ev) => this.setState({selectedEngineer: ev.target.value}, this.fetchData);

    // ***** YearCalendar ***** //

    onPrevYear = () => {
        const startDate = startOfYear(subYears(this.state.startDate, 1));
        const endDate = endOfYear(startDate);
        this.setState({startDate, endDate}, this.fetchData);
    }

    onNextYear = () => {
        const startDate = startOfYear(addYears(this.state.startDate, 1));
        const endDate = endOfYear(startDate);
        this.setState({startDate, endDate}, this.fetchData);
    }

    goToToday = () => {
        const today = this.today;
        const startDate = startOfYear(today), endDate = endOfYear(today);
        this.setState({ selectedDay: today, selectedRange: [today, addDays(today, 15)], startDate, endDate });
    }

    // ***** MonthCalendar ***** //

    onMonthChange = (date) => {
        const startDate = startOfMonth(date);
        const endDate = endOfMonth(addMonths(startDate, 1));
        this.setState({startDate, endDate}, this.fetchData);
    }

    // ***** //

    datePicked = (date) => this.setState({ selectedDay: date, selectedRange: [date, addDays(date, 5)]}, this.fetchAvailableActions);

    rangePicked = (start, end) => this.setState({ selectedRange: [start, end], selectedDay: start }, this.fetchAvailableActions);

    render() {
        const {
            timeline, payAmountsByMonth,
            startDate, endDate,
            selectedDay, selectRange, selectedRange,
            customCSSclasses,
            overrides, availableStatuses,
            selectedEngineer, selectedCalendarType,
            isLoading
        } = this.state;

        const { currentUser, selectEngineerItems } = this.props;
        const year = getYear(startDate);

        const selectedDayISO = lightFormat(selectedDay, 'yyyy-MM-dd');
        const selectedTimelineDay = selectedDay
            ? timeline[selectedDayISO]
            : null;

        return (
            <div id="calendar-page">
                {isLoading && (
                    <div className="loading-overlay">
                        <Loading large/>
                    </div>
                )}

                {selectedCalendarType === "YEAR" &&
                    <div id="year-calendar">
                        <CalendarControls
                            year={year}
                            showTodayButton={false}
                            onPrevYear={this.onPrevYear}
                            onNextYear={this.onNextYear}
                            goToToday={this.goToToday}
                        />
                        <Calendar
                            year={year}
                            showDaysOfWeek={true}
                            forceFullWeeks={false}
                            showWeekSeparators={true}
                            firstDayOfWeek={1}
                            selectedDay={selectedDay}
                            selectRange={selectRange}
                            selectedRange={selectedRange}
                            onPickDate={(date, classes) => this.datePicked(date, classes)}
                            onPickRange={(start, end) => this.rangePicked(start, end)}
                            customClasses={customCSSclasses}
                        />
                    </div>
                }

                {selectedCalendarType === "MONTH" &&
                    <MonthCalendar
                        yearDays={timeline}
                        payAmountsByMonth={payAmountsByMonth}
                        month={startDate}
                        onMonthChange={this.onMonthChange}
                        customClasses={customCSSclasses}
                        forceFullWeeks

                        selectedDay={selectedDay}
                        selectRange={selectRange}
                        selectedRange={selectedRange}
                        onPickDate={this.datePicked}
                        onPickRange={this.rangePicked}
                    />
                }

                <div className="columns mt-4">
                    <div className="column col-4">
                        <div className="h5 ml-2">Лог действий</div>
                            <AffectingOverridesLog overrides={overrides}
                                                   selectedRange={selectedRange}
                                                   selectedDay={selectedDay}
                                                   selectRange={selectRange}
                                                   onItemRemoved={this.componentDidMount}
                                                   showItemRemoveButtons={currentUser.isManagement}
                                                   key={`1-${selectedRange[0]}-${selectedRange[1]}`}
                            />

                            <OverridesLog overrides={overrides}
                                          onItemRemoved={this.componentDidMount}
                                          showItemRemoveButtons={currentUser.isManagement}
                                          key={`0-${selectedRange[0]}-${selectedRange[1]}`}
                            />
                    </div>

                    <div className="column col-3">
                        <div className="h5 ml-2">Действия</div>
                        {currentUser.isManagement &&
                            <ManagerActions selectRange={selectRange} selectedDay={selectedDay}
                                            selectedRange={selectedRange} toggleSelectRange={this.toggleSelectRange}
                                            availableStatuses={availableStatuses} onItemCreated={this.componentDidMount}
                                            selectedEngineer={selectedEngineer} selectEngineerItems={selectEngineerItems} onEngineerChange={this.changeEngineer}
                                            calendarType={selectedCalendarType} changeCalendarType={this.changeCalendarType}
                            />
                        }

                        {!currentUser.isManagement && currentUser.isEngineer &&
                            <EngineerActions selectRange={selectRange} selectedDay={selectedDay}
                                             selectedRange={selectedRange} toggleSelectRange={this.toggleSelectRange}
                                             availableStatuses={availableStatuses} onItemCreated={this.componentDidMount}
                                             selectedEngineer={selectedEngineer}
                                             calendarType={selectedCalendarType} changeCalendarType={this.changeCalendarType}
                            />
                        }
                    </div>

                    <div className="column col-5">
                        <div className="h5 ml-2">В этот день</div>
                        <DayContent timelineDay={selectedTimelineDay} showDetailedPayAmount={currentUser.isManagement} />

                        <div className="h5 ml-2 mt-4">Легенда</div>
                        <StatusLegend selectRange={selectRange} selectedRange={selectedRange} timeline={timeline}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const selectEngineerItems = state.engineersByCategory[ENGINEER_CATEGORY.ACTIVE]
        .map(({_id, nameLF}) => ({ _id: _id, label: nameLF }))
        .sort((a, b) => a.label.localeCompare(b.label));

    return {
        selectEngineerItems,
        currentUser: state.currentUser,
    }
};

export default connect(mapStateToProps, null)(CalendarPage);