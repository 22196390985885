import {
    SIGNED_IN,
    SIGNED_OUT,
    REPL_PROGRESS,
    FILTER_CHANGED,
    stopReplication,
    SET_WAGES_ENGINEERS_TABLE_PAGE_FILTER
} from './actions';
import {ENGINEER_CATEGORY} from "./components/shared/sharedImport";


export default (state, action) => {
    if (action.type === FILTER_CHANGED) {
        const {label, value} = action.payload;
        console.log('[reducer] CHANGE_REPORTS_FILTER', label, value);

        const index = state.filters.findIndex(filter => filter.label === label);
        const filters = state.filters.slice(0, index === -1 ? state.filters.length : index);

        if (label === "timespan") {
            if (value.from || value.to)
                filters.push({label, value})
        } else if (Array.isArray(value)) { // если фильтр multiple, то у него value будет [...]
            if (value.length) // если это value непусто (т.е. была добавлена опция, а не очищено)
                filters.push({label, value});
        } else { // если фильтр не multiple, то value будет не Array
            if (value) // react-select выдает null, когда фильтр очищают
                filters.push({label, value});
        }

        return {...state, filters};
    }

    if (action.type === SIGNED_IN) {
        let {user, engineers} = action.payload;

        const {_id, isSales, isLawyer, isEngineer, isManager, isDirector, isAdmin} = user;

        const currentUser = {
            _id,
            isSales, isLawyer, isEngineer, isManager, isDirector, isAdmin,
            isManagement: isManager || isDirector || isAdmin,
        };

        engineers = engineers.map(e => {
            const nameLFP = `${e.lastName} ${e.firstName} ${e.patronym}`;
            const nameLF = `${e.lastName} ${e.firstName}`;
            const nameLf = `${e.lastName} ${e.firstName[0]}.`;
            const nameLfp = `${e.lastName} ${e.firstName[0]}. ${e.patronym[0]}.`;
            const nameLfp0 = `${e.lastName} ${e.firstName[0]}${e.patronym[0]}`;
            return ({...e, nameLFP, nameLF, nameLf, nameLfp, nameLfp0});
        });

        const engineersByCategory = {
            [ENGINEER_CATEGORY.ACTIVE]: engineers.filter(e => e.category === ENGINEER_CATEGORY.ACTIVE),
            [ENGINEER_CATEGORY.INACTIVE]: engineers.filter(e => e.category === ENGINEER_CATEGORY.INACTIVE),
            [ENGINEER_CATEGORY.FOREIGN]: engineers.filter(e => e.category === ENGINEER_CATEGORY.FOREIGN)
        }

        const engineersById = Object.fromEntries(engineers.map(engineer => [engineer._id, engineer]));

        return {...state, currentUser, replProgress: 1, engineers, engineersById, engineersByCategory};
    }

    if (action.type === SIGNED_OUT) {
        stopReplication();
        return {...state, currentUser: null, replProgress: 0, engineers: null, engineersById: null};
    }

    if (action.type === REPL_PROGRESS)
        return {...state, replProgress: action.payload};

    if (action.type === SET_WAGES_ENGINEERS_TABLE_PAGE_FILTER) {
        return {
            ...state,
            wagesEngineersTablePage: {
                ...state.wagesEngineersTablePage,
                ...action.payload
            }
        }
    }

    return state;
};