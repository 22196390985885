import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {ArrayField, ObjectField, DateInput, TimeInput} from "../shared/Forms";
import {formatDate1, parseDate} from '../shared/sharedImport';
import {addDays, lightFormat, parseISO} from "date-fns";
import {Button, Checkbox, Icon, Input} from "spectre-react";

const THRESHOLD_TIME = '08:00';


class WorkHoursTable extends React.PureComponent {
    static propTypes = {
        engineerIds: PropTypes.arrayOf(PropTypes.string),
        workHours: PropTypes.arrayOf(PropTypes.shape({
            startDate: PropTypes.string,
            startTime: PropTypes.string,
            endDate: PropTypes.string,
            endTime: PropTypes.string,
            breakMinutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        })),
        onChange: PropTypes.func
    };

    componentDidMount() {
        if (this.props.workHours.length === 0) {
            const newItem = this.addWorkHoursItem();
            setTimeout(() => this.onChange({target: {name: 'workHours', value: [newItem]}}), 10);
        }
    }

    onChange = (ev) => {
        let items = ev.target.value;

        // keep at least one item
        if (ev.type === ArrayField.EVENT_TYPES.REMOVE && items.length === 0)
            return;

        this.props.onChange(ev);
    };

    addWorkHoursItem = () => {
        let workHours = this.props.workHours;
        let date = lightFormat(new Date(), 'yyyy-MM-dd');

        if (workHours.length > 0) {
            const lastItem = workHours[workHours.length - 1];

            if (lastItem.endDate && lastItem.endTime) {
                if (lastItem.endTime.localeCompare(THRESHOLD_TIME) < 0)
                    date = lastItem.endDate;
                else
                    date = lightFormat(addDays(parseISO(lastItem.endDate), 1), 'yyyy-MM-dd');
            }
        }

        const engineerIds = this.props.engineerIds;
        const engineerPresence = Object.fromEntries(engineerIds.map(engineerId => [engineerId, true]));
        return {startDate: date, startTime: '10:00', endDate: date, endTime: '19:00', breakMinutes: '60', engineerPresence};
    }

    render() {
        const engineerIds = this.props.engineerIds;

        return (
            <ArrayField name="workHours" array={this.props.workHours} onChange={this.onChange} buildNewItem={this.addWorkHoursItem}>
                {({array, keys, onAdd, onRemove, onChange, onInsert}) =>
                    <fieldset className="mb-6" id="work-hours-table">
                        <legend className="h2">
                            Проведение работ&nbsp;

                            <Button action link small className="ml-2" onClick={onAdd}>
                                <Icon icon="plus"/>
                            </Button>
                        </legend>

                        <div>
                            <div className="d-flex mb-3 text-bold text-uppercase">
                                <div>Начало работ</div>
                                <div className="mx-2">—</div>
                                <div>Окончание работ</div>
                                <div className="mx-2">—</div>
                                <div>Перерыв (в минутах)</div>
                                <div className="mx-2">—</div>
                                <div>Специалисты (если больше 1)</div>
                                <div className="mx-2">—</div>
                                <div>Удалить</div>
                            </div>
                            {array.map((item, index) => {
                                const showInsertButton = index < array.length - 1;
                                const key = keys[index] + Number(showInsertButton);

                                return <WorkHoursItem index={index} key={key} engineerIds={engineerIds}
                                                      item={item} onChange={onChange} onRemove={onRemove}
                                                      showInsertButton={showInsertButton} onInsert={onInsert}
                                />;
                            })}
                        </div>

                        <Button primary className="mt-2" onClick={onAdd}>Добавить</Button>
                    </fieldset>
                }
            </ArrayField>
        )
    }
}

class WorkHoursItem extends React.PureComponent {
    static propTypes = {
        engineersById: PropTypes.object,
        engineerIds: PropTypes.arrayOf(PropTypes.string),
        index: PropTypes.number,
        item: PropTypes.shape({
            startDate: PropTypes.string,
            startTime: PropTypes.string,
            endDate: PropTypes.string,
            endTime: PropTypes.string,
            breakMinutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        }),
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        onInsert: PropTypes.func,
        showInsertButton: PropTypes.bool,
        dummy: PropTypes.number
    }

    onChange = (ev) => {
        const item = ev.target.value;

        const {startTime, endTime} = ev.target.value;
        const startTimeValid = startTime && TimeInput.isValidValue(startTime);
        const endTimeValid = endTime && TimeInput.isValidValue(endTime);

        if (startTimeValid && endTimeValid && endTime.localeCompare(startTime) < 0)
            item.endDate = formatDate1(addDays(parseDate(item.startDate), 1));
        else
            item.endDate = item.startDate;

        this.props.onChange(ev);
    }

    onEngineerPresenceChange = (ev) => {
        const {name, checked} = ev.target;
        const [,indexStr,,engineerId] = name.split('.');
        const index = parseInt(indexStr, 10);

        const item = this.props.item;
        const itemWithChanges = {...item, engineerPresence: {...item.engineerPresence}};
        itemWithChanges.engineerPresence[engineerId] = checked;

        this.onChange({target: {name: `workHours.${index}`, value: itemWithChanges}});
    }

    render() {
        const {engineersById, engineerIds, index, item, onRemove, showInsertButton, onInsert} = this.props;

        return (
            <ObjectField object={item} onChange={this.onChange} dummy={index}>
                {({onChange, object}) =>
                    <React.Fragment>
                        <div className="d-flex work-hours-table-row">
                            <div className="flex-1">
                                <DateInput name={`workHours.${index}.startDate`} className="form-input input-sm" placeholder="Пример: 26.03.2015" value={object.startDate} onChange={onChange}/>
                            </div>
                            <div className="flex-1 ml-2">
                                <TimeInput name={`workHours.${index}.startTime`} className="form-input input-sm" placeholder="Пример: 10:00" value={object.startTime} onChange={onChange}/>
                            </div>
                            <div className="ml-2">—</div>
                            <div className="flex-1 ml-2">
                                <DateInput readOnly name={`workHours.${index}.endDate`} className="form-input input-sm" value={object.endDate} onChange={onChange}/>
                            </div>
                            <div className="flex-1 ml-2">
                                <TimeInput name={`workHours.${index}.endTime`} className="form-input input-sm" placeholder="Пример: 18:00" value={object.endTime} onChange={onChange}/>
                            </div>
                            <div className="ml-2">—</div>
                            <div className="flex-1 ml-2">
                                <Input small type="number" min="0" step="1" name={`workHours.${index}.breakMinutes`}
                                       placeholder="Пример: 60" value={object.breakMinutes} onChange={onChange}/>
                            </div>
                            {engineerIds.length > 1 && <div className="ml-2">—</div>}
                            {engineerIds.length > 1 && <div className="ml-2">
                                {engineerIds.map(engineerId => (
                                    <Checkbox inline className="my-0" key={engineerId}
                                              name={`workHours.${index}.engineerPresence.${engineerId}`}
                                              checked={item.engineerPresence[engineerId]}
                                              onChange={this.onEngineerPresenceChange}>
                                        {engineersById[engineerId].nameLfp0}
                                    </Checkbox>
                                ))}
                            </div>}
                            <div className="ml-2">—</div>
                            <div className="ml-2">
                                <Button action small onClick={onRemove} data-index={index}>
                                    <Icon icon="minus"/>
                                </Button>
                            </div>
                        </div>
                        {showInsertButton && <div className="btn-add-item" data-index-before={index + 1} onClick={onInsert}></div>}
                    </React.Fragment>
                }
            </ObjectField>
        )
    }
}
WorkHoursItem = connect(({engineersById}) => ({engineersById}), null)(WorkHoursItem);


export default WorkHoursTable;