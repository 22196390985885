import React from "react";
import propTypes from "prop-types";
import {pouch} from "../../actions";
import {Button, Checkbox, Icon} from "spectre-react";
import {fetchBill, fetchPayments, postLinks} from "../../api";
import {ModalContainerError, ModalContainerLoading} from "../shared/Modals";
import {SingleSelect} from "../shared/Select";
import {formatDate2} from '../shared/sharedImport';


async function fetchData(billId) {
    try {
        let {bill, payments, links} = await fetchBill(billId);

        const map = {};
        for (const link of links) {
            const paymentId = link._id.slice(link._id.indexOf(':') + 1);
            map[paymentId] = link;
        }

        const items = await fetchPayments({balance: 'positive'});
        for (const item of items) {
            const {_id, numDoc: NUM_DOC, datePl: DATE_PL, summa: SUMMA, clientId, plName: PL_NAME} = item;

            if (payments.findIndex(p => p._id === _id) === -1) {
                const payment = {_id, NUM_DOC, DATE_PL: new Date(DATE_PL), SUMMA, clientId, PL_NAME};
                payments.push(payment);

                map[_id] = {
                    type: "bill_payment",
                    _id: `${bill._id}:${_id}`,
                    billNumDoc: bill.NUM_DOC,
                    billDate: bill.DATE,
                    paymentNumDoc: NUM_DOC,
                    paymentDate: DATE_PL,
                    SUMMA: Math.min(SUMMA, bill.SUMMA),
                    _deleted: true
                }
            }
        }

        const clientNames = new Map((await pouch.query('clients/names')).rows.map(row => [row.key, row.value]));
        bill.DATE = new Date(bill.DATE);
        bill.clientName = clientNames.get(bill.clientId);

        for (const payment of payments) {
            payment.clientName = payment.clientId ? clientNames.get(payment.clientId) : payment.PL_NAME;
            payment.DATE_PL = new Date(payment.DATE_PL);
        }

        return {bill, payments, links: map};
    } catch (error) {
        return {error: error.message};
    }
}

export default class DataGetter extends React.PureComponent {
    static propTypes = {
        onSubmit: propTypes.func,
    };

    getDefaultState() {
        return {active: false, loading: false, error: '', bill: null, payments: null, links: null};
    }

    state = this.getDefaultState();

    open = async (billId) => {
        this.setState({loading: true, active: true});
        this.setState({loading: false, ...(await fetchData(billId))});
    };

    close = () => this.setState(this.getDefaultState());

    submit = () => {
        // we should update those having _rev and create *) not having _rev *) not marked as _deleted, i.e. newly added
        const links = Object.values(this.state.links).filter(link => link._rev || !link._deleted);
        postLinks(links)
            .then(this.close)
            .then(this.props.onSubmit)
            .catch(error => this.setState({error: error.message}));
    };

    onChange = (ev) => this.setState({[ev.target.name]: ev.target.value});

    renderContainer() {
        if (this.state.loading)
            return <ModalContainerLoading close={this.close}/>;

        if (this.state.error)
            return <ModalContainerError error={this.state.error} close={this.close}/>;

        const {bill, payments, links} = this.state;
        return <PaymentsFilter bill={bill} payments={payments} links={links} onChange={this.onChange} close={this.close} submit={this.submit}/>
    }

    render() {
        if (!this.state.active)
            return null;

        return (
            <div className="modal active" id="bill-edit-modal">
                <span className="modal-overlay" onClick={this.close}/>
                {this.renderContainer()}
            </div>
        )
    }
}

class PaymentsFilter extends React.PureComponent {
    static propTypes = {
        bill: propTypes.object,
        links: propTypes.object,
        payments: propTypes.arrayOf(propTypes.object),
        onChange: propTypes.func,
        close: propTypes.func,
        submit: propTypes.func
    };

    state = {showAll: false};

    onChange = (ev) => {
        if (ev.target.name === "payments.showAll")
            this.setState({showAll: ev.target.checked});
        else
            this.props.onChange(ev);
    };

    render() {
        let {bill, links, payments, close, submit} = this.props;
        const showAll = this.state.showAll;

        let taken = [], available = [];
        for (const payment of payments)
            (links[payment._id]._deleted ? available : taken).push(payment);
        if (!showAll)
            available = available.filter(payment => payment.clientId === bill.clientId);

        return <Container bill={bill} links={links} taken={taken} available={available} showAll={showAll} onChange={this.onChange} close={close} submit={submit}/>;
    }
}

class Container extends React.PureComponent {
    static propTypes = {
        bill: propTypes.object,
        links: propTypes.object,
        taken: propTypes.arrayOf(propTypes.object),
        available: propTypes.arrayOf(propTypes.object),
        showAll: propTypes.bool,
        onChange: propTypes.func,
        close: propTypes.func,
        submit: propTypes.func
    };

    onSummaChange = (ev) => {
        const paymentId = ev.target.name.split('.')[1];
        const links = {...this.props.links};
        links[paymentId] = {...links[paymentId], SUMMA: ev.target.value};
        this.props.onChange({target: {name: 'links', value: links}});
    };

    togglePayment = (paymentId) => {
        const links = {...this.props.links};
        const _deleted = !links[paymentId]._deleted;
        links[paymentId] = {...links[paymentId], _deleted};
        this.props.onChange({target: {name: 'links', value: links}});
    };

    removePayment = (ev) => this.togglePayment(ev.currentTarget.dataset.paymentId);
    addPayment = (ev) => this.togglePayment(ev.target.value._id);

    paymentToString(payment) {
        return payment ? `Пп № ${payment.NUM_DOC} от ${formatDate2(payment.DATE_PL)} на ${payment.SUMMA} р. (${payment.clientName})` : '';
    }

    render() {
        const {bill, links, taken, available, showAll} = this.props;
        const title = `Счет № ${bill.NUM_DOC} от ${formatDate2(bill.DATE)} на ${bill.SUMMA} р. (${bill.clientName})`;

        return (
            <div className="modal-container">
                <div className="modal-header">
                    <span className="btn btn-clear float-right" onClick={this.props.close}/>
                    <div className="modal-title h5">{title}</div>
                </div>
                <div className="modal-body">
                    <Table payments={taken} links={links} onSummaChange={this.onSummaChange} removePayment={this.removePayment}/>
                    <SingleSelect items={available} itemToString={this.paymentToString} name="available"
                                  selectedItem={null} onChange={this.addPayment} searchable={true}
                    />
                    <Checkbox name="payments.showAll" checked={showAll} onChange={this.props.onChange}>Показать все платежи</Checkbox>
                </div>
                <div className="modal-footer">
                    <Button primary className="mr-2" onClick={this.props.submit}>Отправить</Button>
                    <Button link onClick={this.props.close}>Закрыть</Button>
                </div>
            </div>
        );
    }
}

function Table({payments, links, onSummaChange, removePayment}) {
    if (payments.length === 0)
        return null;

    return (
        <table className="table mb-2">
            <thead><tr><th>№ пп</th><th>Дата</th><th>Сумма</th><th>Из нее</th><th/></tr></thead>
            <tbody>
            {payments.map(payment =>
                <tr key={payment._id}>
                    <td>{payment.NUM_DOC}</td>
                    <td>{formatDate2(payment.DATE_PL)}</td>
                    <td>{payment.SUMMA}</td>
                    <td>
                        <input type="number" name={`"links.${payment._id}.SUMMA`} value={links[payment._id].SUMMA}
                               onChange={onSummaChange} className="form-input input-sm"/>
                    </td>
                    <td>
                        <Button primary small data-payment-id={payment._id} onClick={removePayment}>
                            <Icon icon="cross"/>
                        </Button>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    );
}
