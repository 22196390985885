import React from "react";
import PropTypes from "prop-types";
import {FormGroup, Label} from "spectre-react";
import {pouch} from "../../actions";
import {groupBy} from "../shared/sharedImport";
import {SingleSelect} from "../shared/Select";


class Client extends React.PureComponent {
    static propTypes = {
        clientId: PropTypes.string,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
    };

    clients = [];

    async componentDidMount() {
        await this.fetchClients();
        this.forceUpdate();
    }

    async fetchClients() {
        const response = await pouch.query('clients/withMachines', {reduce: false, include_docs: true});

        this.clients = groupBy(response.rows.map(row => row.doc), doc => doc.clientId || doc._id)  // {clientId => [client, ...machines], ...}
            .map(entry => entry[1])  // [[client, ...machines], ...]
            .filter(group => group.length > 1)  // take only groups where count of machines > 1
            .map(([{_id, name}, ]) => ({_id, label: name}))  // get first document (client) from group and build select item
            .sort((a, b) => a.label.localeCompare(b.label));  // sort select items
    }

    onChange = (ev) => {
        const selectedClient = ev.target.value;

        this.props.onChange({target: [
            {name: 'clientId', value: selectedClient?._id || null},
            {name: 'machineId', value: null}
        ]});
    };

    render() {
        const clientId = this.props.clientId;
        const selectedItem = clientId ? this.clients.find(client => client._id === clientId) : null;

        return (
            <FormGroup>
                <Label form htmlFor="clientId">Клиент</Label>
                <SingleSelect items={this.clients} selectedItem={selectedItem}
                              clearable={true} searchable={true} disabled={this.props.disabled}
                              name="clientId" placeholder="Клиент" onChange={this.onChange}
                />
            </FormGroup>
        )
    }
}


export default Client;
