import React from 'react';
import {connect} from 'react-redux';
import {FormGroup, Label, Input, Toast, Button} from "spectre-react";
import {REPORTS_PATH} from '../paths';
import {checkAuth, signIn} from '../api';
import {signedIn, startReplication} from "../actions";


class SignInForm extends React.Component {
    state = {
        name: '',
        password: '',
        loading: false,
        error: ''
    };

    componentDidMount() {
        checkAuth().then(this.props.onSignIn).catch(console.error);
    }

    onChange = (ev) => this.setState({[ev.target.name]: ev.target.value, error: ''});

    handleSubmit = (ev) => {
        ev.preventDefault();
        this.setState({loading: true});

        const {name, password} = this.state;
        signIn(name, password)
            .then(this.props.onSignIn)
            .catch(error => this.setState({loading: false, error: error.message}));
    };

    render() {
        const {loading, name, password} = this.state;

        return (
            <form id="sign-in-form" onSubmit={this.handleSubmit}>
                <img src="/logo.svg" className="img-responsive mb-4" alt="Логотип"/>

                <FormGroup>
                    <Label form htmlFor="name">Логин</Label>
                    <Input type="text" id="name" name="name" value={name} onChange={this.onChange} autoFocus/>
                </FormGroup>

                <FormGroup>
                    <Label form htmlFor="password">Пароль</Label>
                    <Input type="password" id="password" name="password" value={password} onChange={this.onChange}/>
                </FormGroup>

                {this.state.error && <Toast className="mt-4">{this.state.error}</Toast>}
                <Button primary loading={loading} type="submit" className="mt-4">Войти</Button>
            </form>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    onSignIn: (payload) => {
        dispatch(signedIn(payload));
        dispatch(startReplication);

        const state = ownProps.location.state;
        const location = state ? state.from : REPORTS_PATH;
        ownProps.history.push(location);
    }
});

export default connect(null, mapDispatchToProps)(SignInForm);