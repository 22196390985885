import React from 'react';
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import * as paths from '../paths';
import {stopReplication} from "../actions";

import Navbar from './Navbar';
import AppLoadingScreen from "./AppLoadingScreen";
import SignInForm from './SignInForm';
import WagesEngineersTablePage from "./WagesEngineersTablePage";
import {NewClient, EditClient} from './ClientForms';
import ClientsList from './ClientsList';
import {NewMachine, EditMachine} from './MachineForms';
import MachinesList from './MachinesList';
import {NewReport, EditReport} from './ReportForms';
import ReportsList from './ReportsList';
import BillsList from "./BillsList/BillsList";
import PaymentsList from "./PaymentsList/PaymentsList";
import CalendarPage from "./CalendarPage";
import TransportEmailsPage from "./TransportEmailsPage";
import SpecialDocsPage from "./SpecialDocsPage";
import SpecialDocEditPage from "./SpecialDocEditPage";
import WagesParamsEditPage from "./WagesParamsEditPage";
import WagesAlgorithmExplanationPage from "./WagesAlgorithmExplanationPage";
import WagesParamsHistoryPage from "./WagesParamsHistoryPage";


const AuthenticatedRoute = ({component: Component, authenticated, currentUser, ...rest}) => (
    <Route {...rest} render={(props) => (
        authenticated === true
            ? <Component {...props} currentUser={currentUser} />
            : <Redirect to={{pathname: paths.SIGN_IN_PATH, state: {from: props.location}}}/>
    )} />
);

const ManagementRoute = ({component: Component, authenticated, currentUser, ...rest}) => (
    <Route {...rest} render={(props) => (
        authenticated === true && currentUser.isManagement
            ? <Component {...props} currentUser={currentUser} />
            : <Redirect to={{pathname: paths.SIGN_IN_PATH, state: {from: props.location}}}/>
    )} />
);


class App extends React.Component {
    componentDidMount() {
        window.addEventListener('unload', stopReplication);
    }

    componentWillUnmount() {
        window.removeEventListener('unload', stopReplication);
        stopReplication();
    }

    render() {
        const {replProgress, currentUser} = this.props;
        const authenticated = !!currentUser;

        if (replProgress instanceof Error) {
            return (
                <div className="text-center text-error">
                    {replProgress.message}
                </div>
            )
        }

        if (replProgress)
            return <AppLoadingScreen text={`${replProgress} %`} />;

        return (
            <Router>
                {authenticated && <Navbar currentUser={currentUser}/>}

                <Switch>
                    <Route exact path="/" component={() => <Redirect to={paths.SIGN_IN_PATH}/>}/>

                    <Route exact path={paths.SIGN_IN_PATH} component={
                        (props) => authenticated
                            ? <Redirect to={paths.REPORTS_PATH}/>
                            : <SignInForm {...props}/>
                    }/>

                    <ManagementRoute path={paths.WAGES_ENGINEERS_TABLE_PATH} component={WagesEngineersTablePage} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.CALENDAR_PATH} component={CalendarPage} currentUser={currentUser} authenticated={authenticated}/>

                    <ManagementRoute path={paths.BILLS_TABLE_PATH} component={BillsList} currentUser={currentUser} authenticated={authenticated}/>
                    <ManagementRoute path={paths.PAYMENTS_TABLE_PATH} component={PaymentsList} currentUser={currentUser} authenticated={authenticated}/>

                    <AuthenticatedRoute exact path={paths.REPORTS_PATH} component={ReportsList} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.NEW_REPORT_PATH} component={NewReport} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.EDIT_REPORT_PATH} component={EditReport} currentUser={currentUser} authenticated={authenticated}/>

                    <AuthenticatedRoute exact path={paths.MACHINES_PATH} component={MachinesList} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.NEW_MACHINE_PATH} component={NewMachine} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.EDIT_MACHINE_PATH} component={EditMachine} currentUser={currentUser} authenticated={authenticated}/>

                    <AuthenticatedRoute exact path={paths.CLIENTS_PATH} component={ClientsList} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.NEW_CLIENT_PATH} component={NewClient} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.EDIT_CLIENT_PATH} component={EditClient} currentUser={currentUser} authenticated={authenticated}/>

                    <AuthenticatedRoute exact path={paths.TRANSPORT_EMAILS_PATH} component={TransportEmailsPage} currentUser={currentUser} authenticated={authenticated}/>

                    <ManagementRoute exact path={paths.SPECIAL_DOCS_PATH} component={SpecialDocsPage} currentUser={currentUser} authenticated={authenticated}/>
                    <ManagementRoute exact path={paths.EDIT_SPECIAL_DOC_PATH} component={SpecialDocEditPage} currentUser={currentUser} authenticated={authenticated}/>
                    <ManagementRoute exact path={paths.EDIT_WAGES_PARAMS_PATH} component={WagesParamsEditPage} currentUser={currentUser} authenticated={authenticated}/>

                    <ManagementRoute exact path={paths.WAGES_PARAMS_HISTORY_PATH} component={WagesParamsHistoryPage} currentUser={currentUser} authenticated={authenticated}/>
                    <ManagementRoute exact path={paths.WAGES_ALGORITHM_PATH} component={WagesAlgorithmExplanationPage} currentUser={currentUser} authenticated={authenticated}/>

                    <AuthenticatedRoute exact component={() => <h1>404 Not Found</h1>} authenticated={authenticated}/>
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = ({replProgress, currentUser}) => ({replProgress, currentUser});

export default connect(mapStateToProps, null)(App);