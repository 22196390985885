import React from 'react';
import propTypes from 'prop-types';
import DayPicker from 'react-day-picker/lib/src/DayPicker';
import { isSameMonth, isDate } from 'react-day-picker/lib/src/DateUtils';
import { getModifiersForDay } from 'react-day-picker/lib/src/ModifiersUtils';
import { ESC, TAB } from 'react-day-picker/lib/src/keys';
import {dateLocale, date1to2, formatDate1, formatDate2, parseDate2} from "./sharedImport";


class DayPickerInput extends React.Component {
    static propTypes = {
        value: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(Date)]),
        inputProps: propTypes.object,
        placeholder: propTypes.string,

        format: propTypes.oneOfType([
            propTypes.string,
            propTypes.arrayOf(propTypes.string),
        ]),

        formatDate: propTypes.func,
        parseDate: propTypes.func,

        showOverlay: propTypes.bool,
        dayPickerProps: propTypes.object,
        hideOnDayClick: propTypes.bool,
        clickUnselectsDay: propTypes.bool,
        keepFocus: propTypes.bool,

        classNames: propTypes.shape({
            container: propTypes.string,
            overlayWrapper: propTypes.string,
            overlay: propTypes.string.isRequired,
        }),

        onDayChange: propTypes.func,
    };

    static defaultProps = {
        dayPickerProps: {},
        value: '',
        placeholder: 'DD.MM.YYYY',
        format: 'L',
        formatDate: formatDate2,
        parseDate: parseDate2,
        showOverlay: false,
        hideOnDayClick: true,
        clickUnselectsDay: false,
        keepFocus: true,
        inputProps: {},
        classNames: {
            container: 'DayPickerInput',
            overlayWrapper: 'DayPickerInput-OverlayWrapper',
            overlay: 'DayPickerInput-Overlay',
        },
    };

    input = null;

    daypicker = null;

    clickTimeout = null;

    hideTimeout = null;

    inputBlurTimeout = null;

    inputFocusTimeout = null;

    constructor(props) {
        super(props);

        this.state = this.getInitialStateFromProps(props);
        this.state.showOverlay = props.showOverlay;

        this.hideAfterDayClick = this.hideAfterDayClick.bind(this);
        this.handleInputClick = this.handleInputClick.bind(this);
        this.handleInputFocus = this.handleInputFocus.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
        this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.handleOverlayFocus = this.handleOverlayFocus.bind(this);
        this.handleOverlayBlur = this.handleOverlayBlur.bind(this);
    }

    componentDidUpdate(prevProps) {
        const newState = {};

        // Current props
        const { value, formatDate, format, dayPickerProps } = this.props;

        // Update the input value if the `value` prop has changed
        if (value !== prevProps.value) {
            if (isDate(value)) {
                newState.value = formatDate(value, format, dayPickerProps.locale);
            } else {
                newState.value = value;
            }
        }

        // Update the month if the months from props changed
        const prevMonth = prevProps.dayPickerProps.month;
        if (
            dayPickerProps.month &&
            dayPickerProps.month !== prevMonth &&
            !isSameMonth(dayPickerProps.month, prevMonth)
        ) {
            newState.month = dayPickerProps.month;
        }

        // Updated the selected days from props if they changed
        if (prevProps.dayPickerProps.selectedDays !== dayPickerProps.selectedDays) {
            newState.selectedDays = dayPickerProps.selectedDays;
        }

        if (Object.keys(newState).length > 0) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(newState);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.clickTimeout);
        clearTimeout(this.hideTimeout);
        clearTimeout(this.inputFocusTimeout);
        clearTimeout(this.inputBlurTimeout);
        clearTimeout(this.overlayBlurTimeout);
    }

    getInitialMonthFromProps(props) {
        const { dayPickerProps, format } = props;
        let day;
        if (props.value) {
            if (isDate(props.value)) {
                day = props.value;
            } else {
                day = props.parseDate(props.value, format, dayPickerProps.locale);
            }
        }
        return (
            dayPickerProps.initialMonth || dayPickerProps.month || day || new Date()
        );
    }

    getInitialStateFromProps(props) {
        const { dayPickerProps, formatDate, format } = props;
        let { value } = props;
        if (props.value && isDate(props.value)) {
            value = formatDate(props.value, format, dayPickerProps.locale);
        }
        return {
            value,
            month: this.getInitialMonthFromProps(props),
            selectedDays: dayPickerProps.selectedDays,
        };
    }

    getInput() {
        return this.input;
    }

    getDayPicker() {
        return this.daypicker;
    }

    /**
     * Update the component's state and fire the `onDayChange` event passing the
     * day's modifiers to it.
     *
     * @param {Date} day - Will be used for changing the month
     * @param {String} value - Input field value
     * @private
     */
    updateState(day, value, callback) {
        const { dayPickerProps, onDayChange } = this.props;
        this.setState({ month: day, value, typedValue: undefined }, () => {
            if (callback) {
                callback();
            }
            if (!onDayChange) {
                return;
            }
            const modifiersObj = {
                disabled: dayPickerProps.disabledDays,
                selected: dayPickerProps.selectedDays,
                ...dayPickerProps.modifiers,
            };
            const modifiers = getModifiersForDay(day, modifiersObj).reduce(
                (obj, modifier) => ({
                    ...obj,
                    [modifier]: true,
                }),
                {}
            );
            onDayChange(day, modifiers, this);
        });
    }

    /**
     * Show the Day Picker overlay.
     *
     * @memberof DayPickerInput
     */
    showDayPicker() {
        const { parseDate, format, dayPickerProps } = this.props;
        const { value, showOverlay } = this.state;
        if (showOverlay) {
            return;
        }
        // Reset the current displayed month when showing the overlay
        const month = value
            ? parseDate(value, format, dayPickerProps.locale) // Use the month in the input field
            : this.getInitialMonthFromProps(this.props); // Restore the month from the props
        this.setState(
            state => ({
                showOverlay: true,
                month: month || state.month,
            })
        );
    }

    /**
     * Hide the Day Picker overlay
     *
     * @memberof DayPickerInput
     */
    hideDayPicker() {
        if (this.state.showOverlay === false) return;
        this.setState({ showOverlay: false });
    }

    hideAfterDayClick() {
        if (!this.props.hideOnDayClick) return;
        this.hideTimeout = setTimeout(() => this.hideDayPicker(), 100);
    }

    handleInputClick(e) {
        this.showDayPicker();
        if (this.input && typeof this.input.focus === 'function' && this.input !== document.activeElement)
            this.input.focus();
    }

    handleInputFocus(e) {
        // this.showDayPicker();

        // Set `overlayHasFocus` after a timeout so the overlay can be hidden when
        // the input is blurred
        this.inputFocusTimeout = setTimeout(() => this.overlayHasFocus = false, 2);
    }

    // When the input is blurred, the overlay should disappear. However the input
    // is blurred also when the user interacts with the overlay (e.g. the overlay
    // get the focus by clicking it). In these cases, the overlay should not be
    // hidden. There are different approaches to avoid hiding the overlay when
    // this happens, but the only cross-browser hack we’ve found is to set all
    // these timeouts in code before changing `overlayHasFocus`.
    handleInputBlur(e) {
        this.inputBlurTimeout = setTimeout(() => {
            if (!this.overlayHasFocus)
                this.hideDayPicker();
        }, 1);
    }

    handleOverlayFocus(e) {
        e.preventDefault();
        this.overlayHasFocus = true;
        if (
            !this.props.keepFocus ||
            !this.input ||
            typeof this.input.focus !== 'function'
        ) {
            return;
        }
        this.input.focus();
    }

    handleOverlayBlur() {
        // We need to set a timeout otherwise IE11 will hide the overlay when
        // focusing it
        this.overlayBlurTimeout = setTimeout(() => {
            this.overlayHasFocus = false;
        }, 3);
    }

    handleInputChange(e) {
        const {dayPickerProps, format, inputProps, onDayChange, parseDate} = this.props;

        if (inputProps.onChange) {
            e.persist();
            inputProps.onChange(e);
        }

        const { value } = e.target;
        if (value.trim() === '') {
            this.setState({ value, typedValue: undefined });
            if (onDayChange) onDayChange(undefined, {}, this);
            return;
        }

        const day = parseDate(value, format, dayPickerProps.locale);
        if (!day) {
            // Day is invalid: we save the value in the typedValue state
            this.setState({ value, typedValue: value });
            if (onDayChange) onDayChange(undefined, {}, this);
            return;
        }
        this.updateState(day, value);
    }

    handleInputKeyDown(e) {
        if (e.keyCode === TAB)
            this.hideDayPicker();
        // else
        //     this.showDayPicker();
    }

    handleInputKeyUp(e) {
        if (e.keyCode === ESC)
            this.hideDayPicker();
        // else
        //     this.showDayPicker();
    }

    handleMonthChange(month) {
        this.setState({ month }, () => {
            if (this.props.dayPickerProps && this.props.dayPickerProps.onMonthChange)
                this.props.dayPickerProps.onMonthChange(month);
        });
    }

    handleDayClick(day, modifiers, e) {
        const {clickUnselectsDay, dayPickerProps, onDayChange, formatDate, format} = this.props;

        if (dayPickerProps.onDayClick)
            dayPickerProps.onDayClick(day, modifiers, e);

        // Do nothing if the day is disabled
        if (
            modifiers.disabled ||
            (dayPickerProps && dayPickerProps.classNames && modifiers[dayPickerProps.classNames.disabled])
        ) {
            return;
        }

        // If the clicked day is already selected, remove the clicked day
        // from the selected days and empty the field value
        if (modifiers.selected && clickUnselectsDay) {
            let { selectedDays } = this.state;
            if (Array.isArray(selectedDays)) {
                selectedDays = selectedDays.slice(0);
                const selectedDayIdx = selectedDays.indexOf(day);
                selectedDays.splice(selectedDayIdx, 1);
            } else if (selectedDays) {
                selectedDays = null;
            }
            this.setState(
                { value: '', typedValue: undefined, selectedDays },
                this.hideAfterDayClick
            );
            if (onDayChange)
                onDayChange(undefined, modifiers, this);
            return;
        }

        const value = formatDate(day, format, dayPickerProps.locale);
        this.setState({ value, typedValue: undefined, month: day }, () => {
            if (onDayChange)
                onDayChange(day, modifiers, this);
            this.hideAfterDayClick();
        });
    }

    renderOverlay() {
        const {classNames, dayPickerProps, parseDate, formatDate, format} = this.props;
        const {selectedDays, value} = this.state;
        let selectedDay;
        if (!selectedDays && value) {
            const day = parseDate(value, format, dayPickerProps.locale);
            if (day) {
                selectedDay = day;
            }
        } else if (selectedDays) {
            selectedDay = selectedDays;
        }
        let onTodayButtonClick;
        if (dayPickerProps.todayButton) {
            // Set the current day when clicking the today button
            onTodayButtonClick = () =>
                this.updateState(
                    new Date(),
                    formatDate(new Date(), format, dayPickerProps.locale),
                    this.hideAfterDayClick
                );
        }

        return (
            <div className={classNames.overlayWrapper}
                 tabIndex={0} // tabIndex is necessary to catch focus/blur events on Safari
                 onFocus={this.handleOverlayFocus}
                 onBlur={this.handleOverlayBlur}>
                <div className={classNames.overlay}>
                    <DayPicker
                        ref={el => (this.daypicker = el)}
                        onTodayButtonClick={onTodayButtonClick}
                        {...dateLocale}
                        {...dayPickerProps}
                        month={this.state.month}
                        selectedDays={selectedDay}
                        onDayClick={this.handleDayClick}
                        onMonthChange={this.handleMonthChange}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { inputProps } = this.props;
        const onIconClick = !inputProps.disabled && !inputProps.readOnly ? this.handleInputClick : undefined;

        return (
            <div className={`${this.props.classNames.container} has-icon-right`}>
                <input
                    ref={el => (this.input = el)}
                    placeholder={this.props.placeholder}
                    {...inputProps}
                    value={this.state.typedValue || this.state.value}
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    onBlur={this.handleInputBlur}
                    onKeyDown={this.handleInputKeyDown}
                    onKeyUp={this.handleInputKeyUp}
                />
                <i className="form-icon icon icon-apps" onClick={onIconClick}/>
                {this.state.showOverlay && this.renderOverlay()}
            </div>
        );
    }
}



export default class extends React.Component {
    static propTypes = {
        inputProps: propTypes.shape({
            name: propTypes.string.isRequired,
            id: propTypes.string,
            className: propTypes.string,
            disabled: propTypes.bool,
            readOnly: propTypes.bool,
        }),
        dayPickerProps: propTypes.object,
        placeholder: propTypes.string,
        value: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(Date)]), // '2019-02-23'
        onChange: propTypes.func
    };

    onChange = (day) => {
        if (!day && !this.props.value) return; // on every symbol you type, until proper date string entered, this fires with day = undefined
        this.props.onChange({target: {name: this.props.inputProps.name, value: day ? formatDate1(day) : null}});
    };

    showDayPicker() {
        this.daypickerinput.showDayPicker();
    }

    getInput() {
        return this.daypickerinput.getInput();
    }

    render() {
        const value = this.props.value ? (isDate(this.props.value) ? this.props.value : date1to2(this.props.value)) : '';

        return (
            <DayPickerInput
                ref={el => this.daypickerinput = el}
                placeholder={this.props.placeholder}
                inputProps={this.props.inputProps}
                dayPickerProps={this.props.dayPickerProps}
                value={value}
                onDayChange={this.onChange}
            />
        )
    }
};