import React from "react";
import PropTypes from "prop-types";
import {FormGroup, Label} from "spectre-react";
import {pouch} from "../../actions";
import {groupBy} from "../shared/sharedImport";
import {SingleSelect} from "../shared/Select";


class Machine extends React.PureComponent {
    static propTypes = {
        clientId: PropTypes.string,
        // ACHTUNG! YES, we store the machine OBJECT in the field named 'machineId'. machine.model is required in Replacements
        machineId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
    };

    machines = [];

    async componentDidMount() {
        await this.fetchMachines();
        this.forceUpdate();
    }

    async fetchMachines() {
        const response = await pouch.query('machines/byManufacturer', {include_docs: true});

        this.machines = response.rows.map(row => row.doc)  // get docs
            .filter(machine => machine.clientId)  // get only machines with clients
            .map(({_id, clientId, serial, model, manufacturer}) => ({_id, clientId, manufacturer, serial, model}))  // build select items
            .sort((a, b) => a.serial.localeCompare(b.serial));
    }

    onChange = (ev) => {
        const machine = ev.target.value;

        this.props.onChange({target: [
            {name: 'machineId', value: machine?._id},
            {name: 'clientId', value: machine?.clientId}
        ]});
    };

    buildItems() {
        const clientId = this.props.clientId;
        let machines = this.machines;

        if (this.props.clientId)
            machines = machines.filter(machine => machine.clientId === clientId);

        return groupBy(machines, machine => machine.manufacturer)
            .map(([label, items]) => ({label, items}))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    itemToString(machine) {
        return machine ? `${machine.serial} (${machine.model})` : '';
    }

    render() {
        const items = this.buildItems(), machineId = this.props.machineId;
        const selectedItem = machineId ? this.machines.find(machine => machine._id === machineId) : null;

        return (
            <FormGroup>
                <Label form htmlFor="machineId">Оборудование</Label>
                <SingleSelect items={items} selectedItem={selectedItem} itemToString={this.itemToString}
                              clearable={false} searchable={true} grouped={true} disabled={this.props.disabled}
                              name="machineId" placeholder="Оборудование" onChange={this.onChange}
                />
            </FormGroup>
        );
    }
}


export default Machine;