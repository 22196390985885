import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducer'
import {SET_WAGES_ENGINEERS_TABLE_PAGE_FILTER} from "./actions";
import {ENGINEER_CATEGORY} from "./components/shared/sharedImport";
import {endOfQuarter, lightFormat, startOfQuarter} from "date-fns";


const DEFAULT_STATE = {
    currentUser: null, // "serg"
    replProgress: 0, // on sign in = 1, then increases during replication up to 100, then (and also on sign out) = 0
    engineers: null, // [{_id: '...', firstName: '', lastName: '', patronym: '', category: ENGINEER_CATEGORY}]
    engineersByCategory: Object.fromEntries(Object.keys(ENGINEER_CATEGORY).map(category => [category, []])),
    engineersById: {},
    filters: [],

    wagesEngineersTablePage: {
        startDate: lightFormat(startOfQuarter(new Date()), 'yyyy-MM-dd'),
        endDate: lightFormat(endOfQuarter(new Date()), 'yyyy-MM-dd'),

        displayType: "twoTables",
        showStats: true,
        timespanMode: 'quarter',
    }
};

//     filters: [
//         {label: 'clients', value: []},
//         {label: 'machines', value: []},
//         {label: 'engineers', value: []},
//         {label: 'status', value: "1"},
//         {label: 'date', value: []},
//         {label: 'type': value: '1'},
//         {label: 'payment', value: "1"},
//         {label: 'replacements', value: true},
//         {label: 'words', value: ''}
//     ],


const localStorageMiddleware = ({getState}) => {
    return (next) => (action) => {
        const result = next(action);

        if (action.type === SET_WAGES_ENGINEERS_TABLE_PAGE_FILTER) {
            const values = getState().wagesEngineersTablePage;
            localStorage.setItem('wagesEngineersTablePage', JSON.stringify(values));
        }

        return result;
    };
};


const rehydrateStore = () => {
    const savedValues = localStorage.getItem('wagesEngineersTablePage')
        ? JSON.parse(localStorage.getItem('wagesEngineersTablePage'))
        : DEFAULT_STATE.wagesEngineersTablePage;

    return {...DEFAULT_STATE, wagesEngineersTablePage: savedValues};
};


const store = createStore(reducer, rehydrateStore(), applyMiddleware(thunk, localStorageMiddleware));

export default store;