import React from 'react';
import propTypes from 'prop-types';
import {SLOT_STATE} from "../shared/sharedImport";


class Preview extends React.Component {
    static propTypes = {
        slotReplacements: propTypes.array
    }

    getSlotClassName(mountedSlotState) {
        switch (mountedSlotState) {
            case SLOT_STATE.NEW: return 'text-success';
            case SLOT_STATE.USED: return 'text-error';
            default: return undefined;
        }
    }

    renderSlotPosition = (slotPosition, mountedSlotState) => {
        const className = this.getSlotClassName(mountedSlotState);
        return <span key={slotPosition} className={className}>{slotPosition}</span>;
    };

    render() {
        const slotReplacements = this.props.slotReplacements;

        let byPhSerial = {}

        for (const {phSerial, slotPosition, slotMounted, mountedSlotState} of slotReplacements) {
            if (!!slotMounted) {
                if (!(phSerial in byPhSerial))
                    byPhSerial[phSerial] = [];
                byPhSerial[phSerial].push({slotPosition, mountedSlotState});
            }
        }

        return Object.entries(byPhSerial)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map( ([ph, slots]) => {
                slots = slots
                    .sort((a, b) => a.slotPosition - b.slotPosition)
                    .map(({slotPosition, mountedSlotState}) => this.renderSlotPosition(slotPosition, mountedSlotState));

                return <div key={ph}>{ph} s{slots}</div>;
            });
    }
}

class Table extends React.Component {
    getSlotClass(code) {
        switch (code) {
            case 'C':
                return 'bg-aqua';
            case 'M':
                return 'bg-fuchsia white';
            case 'Y':
                return 'bg-yellow';
            case 'K':
                return 'bg-black white';
            case 'c':
                return 'bg-light-aqua black';
            case 'm':
                return 'bg-light-fuchsia black';
            default:
                return undefined;
        }
    }

    renderSlotStateLetter(mountedSlotState) {
        if (mountedSlotState === SLOT_STATE.NEW)
            return <span className="text-success">N</span>;
        else if (mountedSlotState === SLOT_STATE.USED)
            return <span className="text-error">U</span>;
        else
            return null;
    }

    renderTableRow(index, renderPh, slotsCount, item) {
        const {phSerial, phPosition, slotPosition, slotMounted, mountedSlotState} = item;

        const slotColor = phPosition && slotPosition <= phPosition.length && phPosition[slotPosition - 1];
        const className = this.getSlotClass(slotColor);

        const slotStateLetter = slotMounted ? this.renderSlotStateLetter(mountedSlotState) : null;

        return (
            <tr key={index}>
                <td>{index}</td>
                <td>{slotStateLetter} {slotMounted}</td>
                <td className={className}>{slotPosition}</td>
                {renderPh && <td rowSpan={slotsCount}>{phSerial} {phPosition}</td>}
            </tr>
        )
    }

    renderTableRows() {
        const byPhSerial = {};
        for (const item of this.props.slotReplacements) {
            if (!(item.phSerial in byPhSerial))
                byPhSerial[item.phSerial] = [];
            byPhSerial[item.phSerial].push(item);
        }

        const rows = [];
        let index = 0;
        for (const phSerial in byPhSerial) {
            const items = byPhSerial[phSerial];

            for (let i = 0; i < items.length; ++i) {
                const row = this.renderTableRow(++index, i === 0, items.length, items[i]);
                rows.push(row);
            }
        }

        return rows;
    }

    render() {
        const rows = this.renderTableRows();

        return (
            <table className="table replaced-slots-table">
                <thead>
                <tr>
                    <th/>
                    <th>Установлен слот</th>
                    <th>№ слота</th>
                    <th>№ головы</th>
                </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        )
    }
}

const SummaryMessage = ({slotReplacements, onClick}) => {
    const count = {[SLOT_STATE.NEW]: 0, [SLOT_STATE.USED]: 0, [SLOT_STATE.UNKNOWN]: 0};

    for (const item of slotReplacements)
        if (item.slotMounted)
            count[item.mountedSlotState] += 1;

    let string = "Установлено слотов: ";
    if (count[SLOT_STATE.NEW] > 0)
        string += `${count[SLOT_STATE.NEW]} новых`;
    if (count[SLOT_STATE.USED] > 0)
        string += `${count[SLOT_STATE.USED]} б/у`;
    if (count[SLOT_STATE.UNKNOWN] > 0)
        string += `${count[SLOT_STATE.UNKNOWN]} без статуса`;

    return (
        <div className="c-hand text-underline" onClick={onClick}>
            {string}
        </div>
    )
}

class SlotsTable extends React.Component {
    state = {
        opened: false
    }

    toggle = () => this.setState(({opened}) => ({opened: !opened}));

    render() {
        const slotReplacements = this.props.slotReplacements;
        const showPreview = !this.state.opened;
        const showTable = !showPreview;

        return (
            <div>
                <SummaryMessage slotReplacements={slotReplacements} onClick={this.toggle} />
                {showPreview && <Preview showTable={this.toggle} slotReplacements={slotReplacements} />}
                {showTable && <Table slotReplacements={slotReplacements} />}
            </div>
        )
    }

}

export default SlotsTable;