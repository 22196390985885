import React from 'react';
import {Redirect} from "react-router-dom";
import {Button, FormGroup, Loading, Toast} from "spectre-react";
import {postSpecialDoc, fetchSpecialDoc} from '../api';
import {SPECIAL_DOCS_PATH} from "../paths";


class SpecialDocEditPage extends React.Component {
    state = {
        fetchState: 0, fetchError: undefined,
        submitState: 0, submitError: undefined,
        doc: undefined,
    };

    componentDidMount() {
        this.fetchDoc();
    }

    async fetchDoc() {
        this.setState({fetchState: 1, fetchError: undefined, doc: undefined});

        try {
            const docId = this.props.match.params.docId;
            const doc = await fetchSpecialDoc(docId);

            this.setState({fetchState: 2, doc: JSON.stringify(doc, null, 2)});
        } catch (error) {
            console.error(error);
            this.setState({fetchState: 0, fetchError: error.message, doc: undefined});
        }
    }

    onChange = (ev) => this.setState({doc: ev.target.value, submitError: undefined});

    onSubmit = async () => {
        this.setState({submitState: 1, submitError: undefined});

        try {
            const docId = this.props.match.params.docId;
            const doc = JSON.parse(this.state.doc);
            await postSpecialDoc(docId, doc);

            this.setState({submitState: 2});
        } catch (error) {
            this.setState({submitState: 0, submitError: error.message})
        }
    };

    render() {
        const {doc, fetchState, fetchError, submitState, submitError} = this.state;

        if (submitState === 2) {
            alert("Изменения успешно внесены.");
            return <Redirect to={SPECIAL_DOCS_PATH} />;
        }

        if (fetchError)
            return <Toast error>{fetchError}</Toast>;

        if (fetchState !== 2)
            return <Loading large />;

        return (
            <div className="container grid-xl" id="special-doc-edit-page">
                <div className="h2">Редактирование документа</div>
                <FormGroup>
                    <textarea className="form-input" rows="25" value={doc} onChange={this.onChange}></textarea>
                </FormGroup>
                <div className="form-actions">
                    {submitError && <Toast error className="mb-2">{submitError}</Toast> }
                    <Button primary onClick={this.onSubmit}>Сохранить</Button>
                </div>
            </div>
        )
    }
}


export default SpecialDocEditPage;