import {DAY_STATUS} from "../shared/sharedImport";

export function transformDayStatusToCSSClass(dayStatus) {
    switch (dayStatus) {
        case DAY_STATUS.VACATION_PAID: return 'vacationPaid';
        case DAY_STATUS.VACATION_UNPAID: return 'vacationUnpaid';
        case DAY_STATUS.TIME_OFF_PAID: return 'timeOffPaid';
        case DAY_STATUS.TIME_OFF_UNPAID: return 'timeOffUnpaid';
        case DAY_STATUS.WORK_FROM_HOME: return 'workFromHome';
        case DAY_STATUS.WORK_FROM_HOME_NWD: return 'workFromHomeNWD';
        case DAY_STATUS.WORK_FROM_OFFICE: return 'workFromOffice';
        case DAY_STATUS.WORK_FROM_OFFICE_NWD: return 'workFromOfficeNWD';
        case DAY_STATUS.FIELD_WORK_HOME_CITY: return 'fieldWorkInHomeCity';
        case DAY_STATUS.FIELD_WORK_HOME_CITY_NWD: return 'fieldWorkInHomeCityNWD';
        case DAY_STATUS.FIELD_WORK_OTHER_CITY: return 'fieldWorkInOtherCity';
        case DAY_STATUS.FIELD_WORK_OTHER_CITY_NWD: return 'fieldWorkInOtherCityNWD';
        case DAY_STATUS.ILLNESS: return 'illness';
        case DAY_STATUS.EMPTY: return null;
        default: return undefined;
    }
}