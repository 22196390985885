import React from 'react';
import {Redirect} from "react-router-dom";
import PropTypes from 'prop-types';
import cx from "classnames";
import {FormGroup, Label, Input, Loading, Checkbox, Divider, Toast, Button, Icon} from "spectre-react";
import {CLIENTS_PATH} from "../paths";
import {pouch} from '../actions';
import {postClient, deleteClient} from '../api';
import {ArrayField, ObjectField, FormLocation} from "./shared/Forms";
import {ClientContactsPropType, LocationPropType} from "./shared/sharedImport";


export class NewClient extends React.Component {
    state = {
        submitState: 0, submitError: null,
        client: {
            type: "client",
            name: "",
            jurName: "",
            website: "",
            notes: "",
            bankDetails: "",
            durstRepair: {active: false, comment: ""},
            durstService: {active: false, refund: false, comment: ""},
            aristoService: {active: false, refund: false, comment: ""},
            contacts: [],
            contractPersons: [],
            location: {address: "", lat: "", lon: ""},
        }
    };

    onChange = (ev) => {
        const client = {...this.state.client};

        if (Array.isArray(ev.target))
            for (const target of ev.target)
                client[target.name] = target.value;
        else
            client[ev.target.name] = ev.target.value;

        this.setState({submitError: null, client});
    };

    onSubmit = async (client) => {
        this.setState({submitError: null, submitState: 1});

        try {
            await postClient(client);
            this.setState({submitError: null, submitState: 2});
        } catch (error) {
            this.setState({submitError: error.message, submitState: 0});
        }
    };

    render() {
        if (this.state.submitState === 2) {
            alert("Новый клиент успешно создан.");
            return <Redirect to={CLIENTS_PATH} />;
        }

        const {submitState, submitError, client} = this.state;

        return (
            <div>
                <h1>Новый клиент</h1>

                <ClientForm isSubmitting={submitState === 1} submitError={submitError}
                            client={client} onChange={this.onChange} onSubmit={this.onSubmit}
                />
            </div>
        )
    }
}

export class EditClient extends React.Component {
    _state = {
        fetchState: 0, fetchError: null,
        submitState: 0, submitError: null,
        destroyState: 0, destroyError: null,
        client: null
    };

    state = {...this._state};

    resetState(callback) {
        this.setState(this._state, callback);
    }

    componentDidMount() {
        this.fetchClient();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.clientId !== prevProps.match.params.clientId)
            this.resetState(this.componentDidMount);
    }

    async fetchClient() {
        const clientId = this.props.match.params.clientId;

        try {
            const client = await pouch.get(clientId);

            this.setState({fetchState: 2, fetchError: null, client});
        } catch (error) {
            console.error(error);
            this.setState({fetchState: 0, fetchError: error.message, client: null});
        }
    }

    onChange = (ev) => {
        const client = {...this.state.client};

        if (Array.isArray(ev.target))
            for (const target of ev.target)
                client[target.name] = target.value;
        else
            client[ev.target.name] = ev.target.value;

        this.setState({submitError: null, client});
    };

    onSubmit = async (client) => {
        this.setState({submitError: null, submitState: 1});

        try {
            await postClient(client);

            this.setState({submitError: null, submitState: 2});
        } catch (error) {
            this.setState({submitError: error.message, submitState: 0})
        }
    };

    onDestroy = async () => {
        if (!window.confirm('Точно удалить?'))
            return;

        this.setState({destroyError: null, destroyState: 1});

        const {_id, _rev} = this.state.client;

        try {
            await deleteClient(_id, _rev);

            this.setState({destroyError: null, destroyState: 2});
        } catch (error) {
            this.setState({destroyError: error.message, destroyState: 0})
        }
    };

    render() {
        if (this.state.submitState === 2) {
            alert("Изменения успешно внесены.");
            return <Redirect to={CLIENTS_PATH} />;
        }

        if (this.state.destroyState === 2) {
            alert("Клиент успешно удален.");
            return <Redirect to={CLIENTS_PATH} />;
        }

        const {client, fetchState, fetchError, submitState, submitError, destroyState, destroyError} = this.state;

        if (fetchState !== 2)
            return <Loading large />;

        if (fetchError)
            return <Toast error>{fetchError}</Toast>;

        return (
            <div>
                <h1>Редактирование клиента</h1>

                <ClientForm key={client?._id || 'whatever'}
                            isSubmitting={submitState === 1} submitError={submitError}
                            isDestroying={destroyState === 1} destroyError={destroyError}
                            onSubmit={this.onSubmit} onDestroy={this.onDestroy}
                            client={client} onChange={this.onChange}
                />
            </div>
        )
    }
}


class ClientForm extends React.PureComponent {
    static propTypes = {
        client: PropTypes.shape({
            type: PropTypes.oneOf(['client']).isRequired,
            _id: PropTypes.string,
            _rev: PropTypes.string,
            name: PropTypes.string.isRequired,
            jurName: PropTypes.string,
            website: PropTypes.string,
            notes: PropTypes.string,
            bankDetails: PropTypes.string,
            contacts: ClientContactsPropType,
            location: LocationPropType,
            durstService: PropTypes.shape({
                active: PropTypes.bool,
                refund: PropTypes.bool,
                comment: PropTypes.string
            }),
            durstRepair: PropTypes.shape({
                active: PropTypes.bool,
                comment: PropTypes.string
            }),
            aristoService: PropTypes.shape({
                active: PropTypes.bool,
                refund: PropTypes.bool,
                comment: PropTypes.string
            })
        }),
        isSubmitting: PropTypes.bool,
        submitError: PropTypes.string,
        isDestroying: PropTypes.bool,
        destroyError: PropTypes.string,
        onChange: PropTypes.func,
        onDestroy: PropTypes.func,
        onSubmit: PropTypes.func
    };

    componentDidMount() {
        if (this.props.client.contacts.length === 0) {
            const newItem = this.addNewContact();
            setTimeout(() => this.props.onChange({target: {name: 'contacts', value: [newItem]}}), 10);
        }
        if (this.props.client.contractPersons.length === 0) {
            const newItem = this.addNewContractPerson();
            setTimeout(() => this.props.onChange({target: {name: 'contractPersons', value: [newItem]}}), 50);
        }
    }

    addNewContact = () => {
        return {name: '', email: '', phone: ''};
    }

    addNewContractPerson = () => {
        return {name: '', email: '', phone: ''}
    }

    onSubmit = () => this.props.onSubmit(this.props.client);

    render() {
        const {client, isSubmitting, isDestroying, submitError, destroyError, onChange, onDestroy} = this.props;

        const isLoading = isSubmitting || isDestroying;
        const error = submitError || destroyError;

        return (
            <form id="client-form">
                <fieldset className="mb-6">
                    <legend className="h2">Общее</legend>

                    <div className='columns'>
                        <div className='column col-sm-12 col-6'>
                            <FormGroup>
                                <Label form htmlFor="name">Название</Label>
                                <Input type="text" id="name"    name="name"    value={client.name}    onChange={onChange} placeholder="Пример: Постер-Принт" />
                            </FormGroup>
                            <FormGroup>
                                <Label form htmlFor="jurName">Название юр. лица</Label>
                                <Input type="text" id="jurName" name="jurName" value={client.jurName} onChange={onChange} placeholder='Пример: ООО "Рога и Копыта"'/>
                            </FormGroup>
                            <FormGroup>
                                <Label form htmlFor="website">Веб-сайт</Label>
                                <Input type="url"  id="website" name="website" value={client.website} onChange={onChange} placeholder="Пример: https://roga-i-kopita.ru/" />
                            </FormGroup>
                            <FormGroup>
                                <Label form htmlFor="notes">Заметки</Label>
                                <Input type="text" id="notes"   name="notes"   value={client.notes}   onChange={onChange} />
                            </FormGroup>
                        </div>

                        <div className='column col-sm-12 col-6'>
                            <FormGroup>
                                <Label form htmlFor="bankDetails">Реквизиты</Label>
                                <textarea className="form-input" id="bankDetails" name="bankDetails" value={client.bankDetails} onChange={onChange} />
                            </FormGroup>
                        </div>
                    </div>
                </fieldset>

                <ArrayField name="contacts" array={client.contacts} onChange={onChange} buildNewItem={this.addNewContact}>
                    {props => <Contacts title="Контактные лица/представители" {...props}/>}
                </ArrayField>

                <ArrayField name="contractPersons" array={client.contractPersons} onChange={onChange} buildNewItem={this.addNewContractPerson}>
                    {props => <Contacts title="Лица, уполномоченные подписывать акт" {...props}/>}
                </ArrayField>

                <fieldset className="mb-6">
                    <legend className="h2">Контракты</legend>

                    <div className="columns">
                        <div className="column col-sm-12 col-6">
                            <h3>Durst</h3>

                            <ObjectField object={client.durstService} onChange={onChange}>
                                {({onChange, object}) =>
                                    <fieldset>
                                        <FormGroup>
                                            <Checkbox name="durstService.active" checked={object.active} onChange={onChange}>
                                                Сервисный рамочный контракт
                                            </Checkbox>
                                        </FormGroup>
                                        <FormGroup>
                                            <Checkbox name="durstService.refund" checked={object.refund} onChange={onChange}>
                                                Возмещение проезда и проживания
                                            </Checkbox>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label form htmlFor="durstService.comment">Комментарий (сервисный рамочный)</Label>
                                            <Input type="text" id="durstService.comment" name="durstService.comment" value={object.comment} onChange={onChange} />
                                        </FormGroup>
                                    </fieldset>}
                            </ObjectField>

                            <Divider />

                            <ObjectField object={client.durstRepair} onChange={onChange}>
                                {({onChange, object}) =>
                                    <fieldset>
                                        <FormGroup>
                                            <Checkbox name="durstRepair.active" checked={object.active} onChange={onChange}>
                                                Контракт на восстановление голов
                                            </Checkbox>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label form htmlFor="durstRepair.comment">Комментарий (восстановление голов)</Label>
                                            <Input type="text" id="durstRepair.comment" name="durstRepair.comment" value={object.comment} onChange={onChange} />
                                        </FormGroup>
                                    </fieldset>}
                            </ObjectField>
                        </div>

                        <div className="column col-sm-12 col-6">
                            <h3>Aristo</h3>

                            <ObjectField object={client.aristoService} onChange={onChange}>
                                {({onChange, object}) =>
                                    <fieldset>
                                        <FormGroup>
                                            <Checkbox name="aristoService.active" checked={object.active} onChange={onChange}>
                                                Сервисный рамочный контракт
                                            </Checkbox>
                                        </FormGroup>
                                        <FormGroup>
                                            <Checkbox name="aristoService.refund" checked={object.refund} onChange={onChange}>
                                                Возмещение проезда и проживания
                                            </Checkbox>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label form htmlFor="aristoService.comment">Комментарий (сервисный рамочный)</Label>
                                            <Input type="text" id="aristoService.comment" name="aristoService.comment" value={object.comment} onChange={onChange} />
                                        </FormGroup>
                                    </fieldset>}
                            </ObjectField>
                        </div>
                    </div>
                </fieldset>

                <FormLocation location={client.location} onChange={onChange} />

                <div className="form-actions">
                    {error && <Toast error className="mb-2">{error}</Toast>}

                    <div className="action-buttons">
                        {onDestroy && <Button error loading={isLoading} className="mr-2" onClick={onDestroy}>Удалить</Button>}
                        <Button success loading={isLoading} onClick={this.onSubmit}>Отправить</Button>
                    </div>
                </div>
            </form>
        );
    }
}

class Contacts extends React.PureComponent {
    render() {
        const {title, name, array, keys, onAdd, onRemove, onChange} = this.props;

        return (
            <fieldset className="mb-6">
                <legend className="h2">
                    {title}&nbsp;
                    <Button action link small onClick={onAdd}>
                        <Icon icon="plus"/>
                    </Button>
                </legend>

                <table className="table">
                    <thead><tr><th>ФИО</th><th>Телефон</th><th>Email</th><th/></tr></thead>
                    <tbody>
                    {array.map((contact, index) =>
                        <ObjectField key={keys[index]} object={contact} onChange={onChange} dummy={index}>
                            {({onChange, object}) =>
                                <tr>
                                    <td>
                                        <Input small type="text" name={`${name}.${index}.name`} placeholder="Пример: Бублик Е.С." value={object.name} onChange={onChange}/>
                                    </td>
                                    <td>
                                        <Input small type="tel" name={`${name}.${index}.phone`} placeholder="Пример: +7(965)111-22-33" value={object.phone} onChange={onChange}/>
                                    </td>
                                    <td>
                                        <Input small type="email" name={`${name}.${index}.email`} placeholder="Пример: bublik@example.com" value={object.email} onChange={onChange}/>
                                    </td>
                                    <td>
                                        <Button action onClick={onRemove} data-index={index}>
                                            <Icon icon="minus"/>
                                        </Button>
                                    </td>
                                </tr>
                            }
                        </ObjectField>
                    )}
                    </tbody>
                </table>

                <Button primary className="mt-2" onClick={onAdd}>Добавить</Button>
            </fieldset>
        )
    }
}
