import React from 'react';
import propTypes from 'prop-types';
import DayPickerInput from './DayPickerInput';


export default class DayPickerInputRange extends React.Component {
    static propTypes = {
        from: propTypes.string, // or undefined
        to: propTypes.string, // or undefined
        notBefore: propTypes.instanceOf(Date),
        notAfter: propTypes.instanceOf(Date),
        nameFrom: propTypes.string.isRequired,
        nameTo: propTypes.string.isRequired,
        onChange: propTypes.func
    };

    render() {
        const {notBefore, notAfter} = this.props;

        const from = this.props.from ? new Date(this.props.from) : undefined;
        const to = this.props.to ? new Date(this.props.to) : undefined;

        const modifiers = {start: from, end: to};

        const dayPickerProps1 = {
            selectedDays: [from, {from, to}],
            disabledDays: {after: to},
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => {this.to.showDayPicker(); this.to.getInput().focus()},
        };

        if (notBefore)
            dayPickerProps1.disabledDays.before = notBefore;
        if (notAfter && notAfter < dayPickerProps1.disabledDays.after)
            dayPickerProps1.disabledDays.after.after = notAfter;

        const dayPickerProps2 = {
            selectedDays: [from, {from, to}],
            disabledDays: {before: from},
            modifiers,
            month: from,
            fromMonth: from,
            numberOfMonths: 2,
        };

        if (notAfter)
            dayPickerProps2.disabledDays.after = notAfter;
        if (notBefore && dayPickerProps2.disabledDays.before < notBefore)
            dayPickerProps2.disabledDays.before = notBefore;

        const {idFrom, idTo, nameFrom, nameTo, className} = this.props;
        const inputProps1 = {id: idFrom, name: nameFrom, className};
        const inputProps2 = {id: idTo, name: nameTo, className};

        return (
            <div className="InputFromTo">
                <DayPickerInput
                    placeholder="Пример: 25.04.2015" value={from} onChange={this.props.onChange}
                    inputProps={inputProps1} dayPickerProps={dayPickerProps1}
                />&nbsp;—&nbsp;
                <DayPickerInput
                    ref={el => (this.to = el)}
                    placeholder="Пример: 25.04.2015" value={to} onChange={this.props.onChange}
                    inputProps={inputProps2} dayPickerProps={dayPickerProps2}
                />
            </div>
        );
    }
}