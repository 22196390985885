import React from "react";
import propTypes from "prop-types";
import cx from "classnames";
import {Button, FormGroup, Input, Label, Toast} from "spectre-react";
import {pouch} from "../../actions";
import {postPayment} from "../../api";
import {SingleSelect} from "../shared/Select";


class ClientSelect extends React.PureComponent {
    static propTypes = {
        clientId: propTypes.string,
        onChange: propTypes.func,
    };

    state = {
        options: [],
    };

    componentDidMount() {
        pouch.query('clients/names').then(response => {
            this.setState({options: response.rows
                .map(row => ({_id: row.key, label: row.value}))
                .sort((a, b) => a.label.localeCompare(b.label))});
        })
    }

    onChange = (ev) => this.props.onChange({target: {name: ev.target.name, value: ev.target.value._id}});

    render() {
        const clientId = this.props.clientId;
        const options = this.state.options;
        const selectedItem = options.find(option => option._id === clientId);

        return (
            <SingleSelect items={options} selectedItem={selectedItem} searchable={true} clearable={false}
                          name="payment.clientId" placeholder="Клиент" onChange={this.onChange}
            />
        );
    }
}

export default class extends React.PureComponent {
    static propTypes = {
        onSubmit: propTypes.func,
    };

    getEmptyPayment() {
        return {NUM_DOC: "", DATE_PL: "", SUMMA: "", clientId: null};
    };

    getDefaultState() {
        return {active: false, payment: null, loading: false, error: ''};
    }

    state = this.getDefaultState();

    open = () => this.setState({active: true, payment: this.getEmptyPayment()});
    close = () => this.setState(this.getDefaultState());

    onChange = (ev) => this.setState({payment: {...this.state.payment, [ev.target.name.slice(8)]: ev.target.value}});

    submit = () => {
        this.setState({loading: true, error: ''});
        postPayment(this.state.payment)
            .then(this.props.onSubmit)
            .catch(error => this.setState({loading: false, error: error.message}));
    };

    render() {
        if (!this.state.active)
            return null;

        const {loading, error, payment} = this.state;
        const disabled = !payment.clientId || !payment.NUM_DOC || !payment.DATE_PL || !payment.SUMMA;

        return (
            <div className="modal modal-sm active">
                <span className="modal-overlay" onClick={this.close}/>
                <div className="modal-container">
                    <div className="modal-header">
                        <span className="btn btn-clear float-right" onClick={this.close}/>
                        <div className="modal-title h5">Новый платеж</div>
                    </div>
                    <div className="modal-body">
                        <FormGroup>
                            <Label form htmlFor="payment.clientId">Клиент</Label>
                            <ClientSelect clientId={payment.clientId} onChange={this.onChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label form htmlFor="payment.NUM_DOC">Номер пп</Label>
                            <Input type="text" id="payment.NUM_DOC" name="payment.NUM_DOC"
                                   value={payment.NUM_DOC} onChange={this.onChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label form htmlFor="payment.DATE_PL">Дата платежа</Label>
                            <Input type="date" required id="payment.DATE_PL" name="payment.DATE_PL"
                                   value={payment.DATE_PL} onChange={this.onChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label form htmlFor="payment.SUMMA">Сумма</Label>
                            <Input type="number" id="payment.SUMMA" name="payment.SUMMA"
                                   min="1" step="0.01" value={payment.SUMMA} onChange={this.onChange}/>
                        </FormGroup>
                    </div>
                    {error &&
                    <div className="modal-body py-0">
                        <Toast error>{error}</Toast>
                    </div>
                    }
                    <div className="modal-footer">
                        <Button primary loading={loading} disabled={disabled} className="mr-2" onClick={this.submit}>Отправить</Button>
                        <Button link onClick={this.close}>Закрыть</Button>
                    </div>
                </div>
            </div>
        )
    }
}