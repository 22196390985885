import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {getDate} from "date-fns";

const propTypes = {
  classes: PropTypes.string,
  dayClicked: PropTypes.func.isRequired,
  dayHovered: PropTypes.func.isRequired,
  day: PropTypes.instanceOf(Date),
  title: PropTypes.string
};

const defaultProps = {
  classes: '',
  day: null,
  title: undefined
};

class Day extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onHover = this.onHover.bind(this);
  }

  onClick() {
    const { dayClicked, day } = this.props;
    dayClicked(day);
  }

  onHover() {
    const { dayHovered, day } = this.props;
    dayHovered(day);
  }

  render() {
    const { classes, day, title } = this.props;
    return (
      <td onClick={this.onClick} onMouseEnter={this.onHover} className={classes} title={title}>
        <span className="day-number">{day === null ? '' : getDate(day)}</span>
      </td>
    );
  }
}

Day.propTypes = propTypes;
Day.defaultProps = defaultProps;

export default Day;
