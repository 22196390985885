import React from "react";
import PropTypes from "prop-types";
import {FormGroup, Label} from "spectre-react";
import {MultiSelect} from "../shared/Select";
import {connect} from "react-redux";
import {ENGINEER_CATEGORY} from "../shared/sharedImport";


class Engineers extends React.Component {
    static propTypes = {
        engineers: PropTypes.arrayOf(PropTypes.shape({
            _id: PropTypes.string,
        })),
        engineersByCategory: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
            _id: PropTypes.string,
            nameLF: PropTypes.string,
        }))),
        engineerIds: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func
    };

    static engineerToString(obj) {
        return obj ? obj.nameLF : "";
    }

    onChange = (ev) => this.props.onChange({target: {
        name: ev.target.name,
        value: ev.target.value.map(engineer => engineer._id)
    }});

    render() {
        const groups = [
            {label: 'SignART', items: this.props.engineersByCategory[ENGINEER_CATEGORY.ACTIVE]},
            {label: 'Когда-то работали', items: this.props.engineersByCategory[ENGINEER_CATEGORY.INACTIVE]},
            {label: 'Прочие', items: this.props.engineersByCategory[ENGINEER_CATEGORY.FOREIGN]},
        ];
        const selectedItems = this.props.engineerIds.map(engineerId => this.props.engineers.find(engineer => engineer._id === engineerId));

        return (
            <FormGroup>
                <Label form htmlFor="engineerIds">Специалисты</Label>
                <MultiSelect items={groups} selectedItems={selectedItems} onChange={this.onChange}
                             clearable={true} searchable={true} grouped={true}
                             id="engineerIds" name="engineerIds"
                             placeholder="Специалисты" itemToString={Engineers.engineerToString}
                />
            </FormGroup>
        );
    }
}


function mapStateToProps(state) {
    return {
        engineers: state.engineers,
        engineersByCategory: state.engineersByCategory,
    }
}

export default connect(mapStateToProps, null)(Engineers);