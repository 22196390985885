import React from "react";
import PropTypes from "prop-types";
import {eachDayOfInterval, lightFormat} from "date-fns";
import {DAY_STATUS} from "../shared/sharedImport";


class StatusLegend extends React.PureComponent {
    static propTypes = {
        timeline: PropTypes.object,
        selectRange: PropTypes.bool.isRequired,
        selectedRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired
    }

    calculateStatusCounts() {
        if (!this.props.selectRange)
            return {};

        const statusCount = Object.fromEntries(Object.keys(DAY_STATUS).map(key => [key, 0]));
        statusCount['OVERRIDE'] = 0;

        const [start, end] = this.props.selectedRange;
        for (const date of eachDayOfInterval({start, end})) {
            const dateStr = lightFormat(date, 'yyyy-MM-dd');
            const dayProps = this.props.timeline[dateStr];
            const status = dayProps.statusOverride ?? dayProps.defaultStatus;
            statusCount[status] += 1;

            if (dayProps.statusOverride)
                statusCount.OVERRIDE += 1;
        }
        return statusCount
    }

    render() {
        const showCounts = this.props.selectRange;
        const statusCount = this.calculateStatusCounts();

        return (
            <ul id="legend" className='color-legend'>
                <li><span className="vacationUnpaid"/><span>Неоплачиваемый отпуск, неоплачиваемый отгул</span>{showCounts && ` — ${statusCount[DAY_STATUS.VACATION_UNPAID]}`}</li>
                <li><span className="vacationPaid"/><span>Оплачиваемый отпуск, оплачиваемый отгул, больничный</span>{showCounts && ` — ${statusCount[DAY_STATUS.VACATION_PAID]}`}</li>
                <li><span className="workFromHome"/><span>Работа из дома в рабочий день</span>{showCounts && ` — ${statusCount[DAY_STATUS.WORK_FROM_HOME]}`}</li>
                <li><span className="workFromHomeNWD"/><span>Работа из дома в нерабочий день</span>{showCounts && ` — ${statusCount[DAY_STATUS.WORK_FROM_HOME_NWD]}`}</li>
                <li><span className="workFromOffice"/><span>Работа из офиса в рабочий день</span>{showCounts && ` — ${statusCount[DAY_STATUS.WORK_FROM_OFFICE]}`}</li>
                <li><span className="workFromOfficeNWD"/><span>Работа из офиса в нерабочий день</span>{showCounts && ` — ${statusCount[DAY_STATUS.WORK_FROM_OFFICE_NWD]}`}</li>
                <li><span className="fieldWorkInHomeCity"/><span>Работа на выезде в СПб в рабочий день</span>{showCounts && ` — ${statusCount[DAY_STATUS.FIELD_WORK_HOME_CITY]}`}</li>
                <li><span className="fieldWorkInHomeCityNWD"/><span>Работа на выезде в СПб в нерабочий день</span>{showCounts && ` — ${statusCount[DAY_STATUS.FIELD_WORK_HOME_CITY_NWD]}`}</li>
                <li><span className="fieldWorkInOtherCity"/><span>Работа на выезде в командировке в рабочий день</span>{showCounts && ` — ${statusCount[DAY_STATUS.FIELD_WORK_OTHER_CITY]}`}</li>
                <li><span className="fieldWorkInOtherCityNWD"/><span>Работа на выезде в командировке в нерабочий день</span>{showCounts && ` — ${statusCount[DAY_STATUS.FIELD_WORK_OTHER_CITY_NWD]}`}</li>
                <li><span className="badge"/><span>Статус дня был изменен</span>{showCounts && ` — ${statusCount.OVERRIDE}`}</li>
                <li><span/><span>Нерабочий день + не было работ (но могла быть дорога)</span>{showCounts && ` — ${statusCount.EMPTY}`}</li>
            </ul>
        )
    }
}

export default StatusLegend;