export const SIGN_IN_PATH = '/sign_in';

export const BILLS_TABLE_PATH = '/bills';
export const PAYMENTS_TABLE_PATH = '/payments';

export const REPORTS_PATH = '/reports';
export const NEW_REPORT_PATH = '/reports/new';
export const EDIT_REPORT_PATH = '/reports/:reportId/edit';

export const CLIENTS_PATH = '/clients';
export const NEW_CLIENT_PATH = '/clients/new';
export const EDIT_CLIENT_PATH = '/clients/:clientId/edit';

export const MACHINES_PATH = '/machines';
export const NEW_MACHINE_PATH = '/machines/new';
export const EDIT_MACHINE_PATH = '/machines/:machineId/edit';

export const WAGES_ENGINEERS_TABLE_PATH = '/wages/engineers';
export const CALENDAR_PATH = '/calendar';

export const TRANSPORT_EMAILS_PATH = '/transport';

export const SPECIAL_DOCS_PATH = '/special_docs';
export const EDIT_SPECIAL_DOC_PATH = '/special_docs/:docId';
export const EDIT_WAGES_PARAMS_PATH = '/wages/params/edit';

export const WAGES_ALGORITHM_PATH = '/wages/algorithm';
export const WAGES_PARAMS_HISTORY_PATH = '/wages/params/history';


export function editReportPath(obj) {
    if (typeof obj === "object")
        return EDIT_REPORT_PATH.replace(':reportId', obj._id);
    else
        return EDIT_REPORT_PATH.replace(':reportId', obj);
}

export function editMachinePath(obj) {
    if (typeof obj === "object")
        return EDIT_MACHINE_PATH.replace(':machineId', obj._id);
    else
        return EDIT_MACHINE_PATH.replace(':machineId', obj);
}

export function editClientPath(obj) {
    if (typeof obj === "object")
        return EDIT_CLIENT_PATH.replace(':clientId', obj._id);
    else
        return EDIT_CLIENT_PATH.replace(':clientId', obj);
}

export function editSpecialDocPath(obj) {
    if (typeof obj === "object")
        return EDIT_SPECIAL_DOC_PATH.replace(':docId', obj._id);
    else
        return EDIT_SPECIAL_DOC_PATH.replace(':docId', obj);
}