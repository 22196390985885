import React from 'react';
import propTypes from 'prop-types';
import cx from 'classnames';
import Downshift from 'downshift';
import {Icon} from "spectre-react";


// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export class SimpleAutocomplete extends React.PureComponent {
    static propTypes = {
        items: propTypes.arrayOf(propTypes.string), // that means SIMPLE
        id: propTypes.string,
        name: propTypes.string.isRequired,
        value: propTypes.string.isRequired,
        placeholder: propTypes.string,
        onChange: propTypes.func.isRequired
    };

    static defaultProps = {items: [], value: ''};

    onChange = (value, stateAndHelpers) => {
        if (stateAndHelpers.isOpen || value)
            this.props.onChange({target: {name: this.props.name, value}});
    };

    render() {
        const {name, value, placeholder} = this.props;
        const id = this.props.id || name;

        const re = new RegExp(escapeRegexCharacters(value.trim()), 'i');
        const items = this.props.items.filter(item => re.test(item)).slice(0, 10);

        return (
            <Downshift selectedItem={null} onInputValueChange={this.onChange}>
                {({ getMenuProps, getInputProps, getItemProps, isOpen, highlightedIndex, openMenu }) =>
                    <div className="custom-select">
                        <input {...getInputProps({type: "text", id, className: 'form-input', name, value, placeholder, onFocus: openMenu})}/>
                        {isOpen && items.length > 0
                            ? <ul {...getMenuProps({className: "menu"})}>
                                {items.map((item, index) =>
                                    <li {...getItemProps({key: item, item, index, className: "menu-item"})}>
                                        <a className={cx({'active': highlightedIndex === index})}>{item}</a>
                                    </li>
                                )}
                            </ul>
                            : null}
                    </div>
                }
            </Downshift>
        );
    }
}

export class Autocomplete extends React.Component {
    static propTypes = {
        items: propTypes.arrayOf(propTypes.object),
        itemsGetter: propTypes.func,
        id: propTypes.string,
        name: propTypes.string.isRequired,
        value: propTypes.string,
        placeholder: propTypes.string,
        onChange: propTypes.func.isRequired,
        onSelect: propTypes.func,
        itemToString: propTypes.func
    };

    static defaultProps = {
        items: [],
        value: '',
        itemToString: (obj) => (obj ? obj.label : '')
    };

    state = {items: []};

    handleChange = (selectedItem) => {
        if (this.props.onSelect)
            this.props.onSelect({target: {name: this.props.name, value: selectedItem}});
        if (this.props.itemsGetter) // ie when state.items should change at all
            this.setState({items: []});
    };

    handleInputValueChange = async (value) => {
        if (value === this.props.value) return; // don't do double update in case handleChange already handles value change
        if (this.props.onChange)
            this.props.onChange({target: {name: this.props.name, value}});
        if (this.props.itemsGetter) {
            const items = await this.props.itemsGetter(escapeRegexCharacters(value.trim()));
            this.setState({items});
        }
    };

    stateReducer(state, changes) {
        switch (changes.type) {
            case Downshift.stateChangeTypes.blurInput: // TAB navigation
            case Downshift.stateChangeTypes.mouseUp: // click outside
            case Downshift.stateChangeTypes.keyDownEscape: // ESC press
                // because selectedItem={null} and itemToString(null) returns '', changes.inputValue is ''
                return {...changes, inputValue: state.inputValue};
            default:
                return changes;
        }
    };

    render() {
        const {placeholder, itemToString, name, value} = this.props;
        const id = this.props.id || name;
        const items = this.props.itemsGetter ? this.state.items : this.props.items;

        return (
            <Downshift stateReducer={this.stateReducer} selectedItem={null} itemToString={itemToString}
                       inputValue={value} onChange={this.handleChange} onInputValueChange={this.handleInputValueChange}>
            {({getMenuProps, getInputProps, getItemProps, isOpen, highlightedIndex}) =>
                <div className="custom-select">
                    <input {...getInputProps({type: "text", className: 'form-input', id, name, value, placeholder})}/>
                    {isOpen && items.length > 0 &&
                        <PlainSelectMenu matchingItems={items} getMenuProps={getMenuProps} getItemProps={getItemProps}
                              highlightedIndex={highlightedIndex} selectedItems={null} itemToString={itemToString}
                              multi={false}
                        />
                    }
                </div>}
            </Downshift>
        );
    }
}

export class SingleSelect extends React.PureComponent { // searchable, grouped
    static propTypes = {
        items: propTypes.arrayOf(propTypes.object),
        itemToString: propTypes.func,
        id: propTypes.string,
        name: propTypes.string.isRequired,
        className: propTypes.string,
        placeholder: propTypes.string,
        selectedItem: propTypes.object,
        onChange: propTypes.func.isRequired,
        searchable: propTypes.bool,
        clearable: propTypes.bool,
        grouped: propTypes.bool,
        disabled: propTypes.bool,
    };

    static defaultProps = {
        items: [],
        itemToString: (obj) => (obj ? obj.label : ''),
        selectedItem: null,
        searchable: false,
        clearable: true,
        grouped: false,
        disabled: false,
        className: 'form-input',
        placeholder: 'Select...'
    };

    state = {inputValue: ''};

    onChange = (selectedItem) => this.props.onChange({target: {name: this.props.name, value: selectedItem}});

    onStateChange = (changes, stateAndHelpers) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.clickItem:
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.keyDownEscape:
            case Downshift.stateChangeTypes.mouseUp:
                this.setState({inputValue: ''});
                return;
            case Downshift.stateChangeTypes.changeInput:
                this.setState({inputValue: stateAndHelpers.inputValue});
                return;
        }
    };

    stateReducer = (state, changes) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownEscape:
            case Downshift.stateChangeTypes.mouseUp:
                const selectedItem = state.selectedItem;
                const inputValue = this.props.itemToString(selectedItem);
                return {...changes, selectedItem, inputValue};
            default:
                return changes;
        }
    };

    getItems = () => {
        const escapedValue = escapeRegexCharacters(this.state.inputValue.trim());
        if (escapedValue === '') return this.props.items;

        const itemToString = this.props.itemToString;
        const re = new RegExp(escapedValue, 'i');

        if (this.props.grouped)
            return this.props.items
                .map(section => ({
                    label: section.label,
                    items: section.items.filter(item => re.test(itemToString(item)))
                }))
                .filter(section => section.items.length > 0);
        else
            return this.props.items.filter(item => re.test(itemToString(item)));
    };

    render() {
        const {itemToString, selectedItem, searchable, clearable, placeholder, className, name, disabled} = this.props;
        const id = this.props.id || name;
        const matchingItems = this.getItems();
        const Menu = this.props.grouped ? GroupedSelectMenu : PlainSelectMenu;

        return (
            <Downshift itemToString={itemToString} selectedItem={selectedItem}
                       stateReducer={this.stateReducer} onStateChange={this.onStateChange} onChange={this.onChange}
            >
            {({
                getMenuProps, getInputProps, getItemProps, isOpen, highlightedIndex, selectedItem,
                clearSelection, toggleMenu
            }) =>
                <div className="custom-select">
                    <div className="has-icon-right">
                        <input {...getInputProps({
                            id, className, onClick: toggleMenu, readOnly: !searchable,
                            disabled, name, placeholder
                        })}/>
                        {selectedItem && clearable
                            ? <Icon formIcon icon="cross" onClick={disabled ? undefined : clearSelection}/>
                            : <Icon formIcon icon={isOpen ? "arrow-up" : "arrow-down"}/>}
                    </div>

                    {isOpen && matchingItems.length > 0 &&
                        <Menu matchingItems={matchingItems} getMenuProps={getMenuProps} getItemProps={getItemProps}
                              highlightedIndex={highlightedIndex} selectedItems={selectedItem} itemToString={itemToString}
                              multi={false}
                        />
                    }
                </div>}
            </Downshift>
        );
    }
}

export class MultiSelect extends React.PureComponent {
    static propTypes = {
        items: propTypes.array,
        itemToString: propTypes.func,
        id: propTypes.string,
        name: propTypes.string.isRequired,
        placeholder: propTypes.string,
        selectedItems: propTypes.arrayOf(propTypes.object),
        onChange: propTypes.func.isRequired,
        searchable: propTypes.bool,
        clearable: propTypes.bool,
        grouped: propTypes.bool,
        disabled: propTypes.bool,
    };

    static defaultProps = {
        items: [],
        itemToString: (obj) => (obj ? obj.label : ''),
        selectedItems: [],
        searchable: false,
        clearable: true,
        grouped: false,
        disabled: false,
        placeholder: 'Select...'
    };

    stateReducer = (state, changes) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                return {...changes, inputValue: ''};
            default:
                return changes
        }
    };

    _onChange = (value) => this.props.onChange({target: {name: this.props.name, value}});

    handleSelection = (selectedItem) => {
        /*if (this.state.selectedItems.includes(selectedItem)) {*/
        const index = this.props.selectedItems.findIndex(i => i._id === selectedItem._id);
        if (index !== -1)
            this.removeItemAtIndex(index);
        else
            this.addItem(selectedItem);
    };

    removeItemAtIndex = (index) => {
        const selectedItems = this.props.selectedItems.slice();
        selectedItems.splice(index, 1);
        this._onChange(selectedItems);
    };
    addItem = (item) => this._onChange([...this.props.selectedItems, item]);

    onItemRemove = (ev) => {
        ev.stopPropagation();
        const index = parseInt(ev.target.dataset.index, 10);
        this.removeItemAtIndex(index);
    };

    clearSelectedItems = () => this._onChange([]);

    getItems = (inputValue) => {
        if (!inputValue) return this.props.items;
        const escapedValue = escapeRegexCharacters(inputValue.trim());
        if (escapedValue === '') return this.props.items;

        const re = new RegExp(escapedValue, 'i');
        const itemToString = this.props.itemToString;

        if (this.props.grouped)
            return this.props.items
                .map(section => ({
                    label: section.label,
                    items: section.items.filter(item => re.test(itemToString(item)))
                }))
                .filter(section => section.items.length > 0);
        else
            return this.props.items.filter(item => re.test(itemToString(item)));
    };

    renderChip = (item, index) => {
        return (
            <div key={item._id} className="chip">
                <button type="button" className="btn btn-clear" data-index={index} onClick={this.onItemRemove} />
                {this.props.itemToString(item)}
            </div>
        )
    };

    render() {
        const {placeholder, itemToString, name, clearable, searchable, disabled} = this.props;
        const id = this.props.id || name;
        const selectedItems = this.props.selectedItems;
        const Menu = this.props.grouped ? GroupedSelectMenu : PlainSelectMenu;

        return (
            <Downshift stateReducer={this.stateReducer} onChange={this.handleSelection}
                       itemToString={itemToString} selectedItem={null}>
            {({
                getMenuProps, getInputProps, getItemProps,
                isOpen, inputValue, highlightedIndex, toggleMenu
            }) => {
                const matchingItems = this.getItems(inputValue);

                return (
                    <div className="form-autocomplete custom-multiselect">
                        <div className={cx("form-autocomplete-input", "form-input", {'is-focused': isOpen})}>
                            {selectedItems.map(this.renderChip)}

                            <input {...getInputProps({
                                    onKeyDown: (ev) => {
                                        if (ev.key === 'Backspace' && !inputValue)
                                            this.removeItemAtIndex(-1);
                                    },
                                    onClick: toggleMenu,
                                    id, name, className: "form-input", readOnly: !searchable, disabled,
                                    placeholder: (selectedItems.length === 0) ? placeholder : '',
                                })}
                            />

                            {(selectedItems.length > 0) && clearable
                                ? <Icon formIcon icon="cross" onClick={disabled ? undefined : this.clearSelectedItems}/>
                                : <Icon formIcon icon={isOpen ? "arrow-up" : "arrow-down"}/>}
                        </div>

                        {isOpen && matchingItems.length > 0 &&
                            <Menu matchingItems={matchingItems} getMenuProps={getMenuProps} getItemProps={getItemProps}
                                  highlightedIndex={highlightedIndex} selectedItems={selectedItems} itemToString={itemToString}
                                  multi={true}
                            />}
                    </div>
                );
            }}
            </Downshift>
        )
    }
}

function GroupedSelectMenu({matchingItems, getMenuProps, getItemProps, highlightedIndex, selectedItems, itemToString, multi}) {
    return (
        <ul {...getMenuProps({className: "menu"})}>
            {matchingItems.reduce((result, section) => {
                result.sections.push(
                    <React.Fragment key={section.label}>
                        <li className="divider" data-content={section.label} key={section.label} />
                        {section.items.map((item) => {
                            const index = result.itemIndex++;
                            return (
                                <li {...getItemProps({key: item._id, item, index, className: "menu-item"})}>
                                    <a className={cx(
                                        {"active": highlightedIndex === index},
                                        {'text-bold': multi ? selectedItems.find(i => i._id === item._id) : (selectedItems && selectedItems._id === item._id)}
                                    )}>
                                        {itemToString(item)}
                                    </a>
                                </li>
                            )
                        })}
                    </React.Fragment>
                );
                return result;
            }, {sections: [], itemIndex: 0}).sections}
        </ul>
    );
}

function PlainSelectMenu({matchingItems, getMenuProps, getItemProps, highlightedIndex, selectedItems, itemToString, multi}) {
    return (
        <ul {...getMenuProps({className: "menu"})}>
            {matchingItems.map((item, index) => (
                <li {...getItemProps({key: item._id, item, index, className: "menu-item"})}>
                    <a className={cx(
                        {"active": highlightedIndex === index},
                        {'text-bold': multi ? selectedItems.find(i => i._id === item._id) : (selectedItems && selectedItems._id === item._id)}
                    )}>
                        {itemToString(item)}
                    </a>
                </li>
            ))}
        </ul>
    )
}
