import axios from 'axios';
import {SIGN_IN_PATH} from "./paths";
import {signedOut} from "./actions";
import store from './store';

axios.interceptors.response.use(
    function (response) { return response },
    function (error) {
        const currentPath = window.location.pathname;
        if (error.response && error.response.status === 401 && currentPath !== SIGN_IN_PATH)
            store.dispatch(signedOut());
        return Promise.reject(error);
    }
);


function returnResponseData(response) {
    return response.data;
}

function throwError(error) {
    if (error.response)
        throw new Error(`${error.response.data.reason} (${error.response.status})`);
    else
        throw error;
}

export function signIn(name, password) {
    return axios.post('/api/session', {name, password}).then(returnResponseData).catch(throwError);
}
export function signOut() {
    return axios.delete('/api/session').then(returnResponseData).catch(throwError);
}
export function checkAuth() {
    return axios.get('/api/session').then(returnResponseData).catch(throwError)
}

export function getReport(_id) {
    return axios.get(`/api/reports/${_id}`).then(returnResponseData).catch(throwError);
}
export function fetchReportsXLSX(ids) {
    return axios.post('/api/reports/xlsx', ids, {responseType: 'blob'})
        .then(response => {
            const url = window.URL.createObjectURL(response.data);
            downloadFile(url, `reports-${new Date().toISOString()}.xlsx`);
            window.URL.revokeObjectURL(url);
        })
        .catch(error => alert(error.message));
}

export function postReport(doc) {
    return axios.post('/api/reports', doc).then(returnResponseData).catch(throwError);
}
export function postClient(doc) {
    return axios.post('/api/clients', doc).then(returnResponseData).catch(throwError);
}
export function postMachine(doc) {
    return axios.post('/api/machines', doc).then(returnResponseData).catch(throwError);
}

export function deleteReport(_id, _rev) {
    return axios.delete(`/api/reports/${_id}/${_rev}`).then(returnResponseData).catch(throwError);
}
export function deleteClient(_id, _rev) {
    return axios.delete(`/api/clients/${_id}/${_rev}`).then(returnResponseData).catch(throwError);
}
export function deleteMachine(_id, _rev) {
    return axios.delete(`/api/machines/${_id}/${_rev}`).then(returnResponseData).catch(throwError);
}

function getSparePartAutocomplete(field, query) {
    if (!query) return [];
    return axios.get(`/api/spare_parts?f=${field}&q=${query}`).then(returnResponseData).catch(() => ([]));
}
export const getSparePartsByRef = getSparePartAutocomplete.bind(null, 'ref');
export const getSparePartsByDescription = getSparePartAutocomplete.bind(null, 'name');


function downloadFile(content, filename) {
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.setAttribute('href', content);
    link.setAttribute('download', filename);
    link.click();
    document.body.removeChild(link);
}
export function fetchFile(ev) {
    ev.preventDefault();

    const url = ev.currentTarget.href;
    const filename = ev.currentTarget.download;

    return axios.get(url, {responseType: 'blob'})
        .then(response => {
            const url = window.URL.createObjectURL(response.data);
            downloadFile(url, filename);
            window.URL.revokeObjectURL(url);
        })
        .catch(throwError);
}
export function fetchCSV(csvContent, filename) {
    return downloadFile(encodeURI(csvContent), filename);
}

export function uploadFile(data, options) {
    return axios.post('/api/files', data, options).then(data).catch(throwError);
}

// --------

export function uploadPaymentsDbf(data) {
    return axios.post('/api/money/payments/dbf', data).then(data).catch(throwError);
}

export function fetchBills(options) {
    const params = options || {balance: 'any'};
    return axios.get('/api/money/bills', {params}).then(returnResponseData).catch(throwError);
}
export function fetchBill(_id) {
    return axios.get(`/api/money/bills/${_id}`).then(returnResponseData).catch(throwError);
}
export function postBill(doc) {
    return axios.post(`/api/money/bills`, doc).then(returnResponseData).catch(throwError);
}
export function deleteBill(_id) {
    return axios.delete(`/api/money/bills/${_id}`).then(returnResponseData).catch(throwError);
}

export function fetchPayments(options) {
    const params = options || {balance: 'any'};
    return axios.get('/api/money/payments', {params}).then(returnResponseData).catch(throwError);
}
export function fetchPaymentsDescriptions() {
    return axios.get('/api/money/payments/descriptions').then(returnResponseData).catch(throwError);
}
export function fetchPayment(_id) {
    return axios.get(`/api/money/payments/${_id}`).then(returnResponseData).catch(throwError);
}
export function postPayment(doc) {
    return axios.post(`/api/money/payments`, doc).then(returnResponseData).catch(throwError);
}
export function hideOrDeletePayment(_id) {
    return axios.delete(`/api/money/payments/${_id}`).then(returnResponseData).catch(throwError);
}
export function restorePayment(_id) {
    return axios.post(`/api/money/payments/${_id}`).then(returnResponseData).catch(throwError);
}

export function setPaymentClientId(paymentId, clientId) {
    return axios.post(`/api/money/payments/${paymentId}/clientId`, {clientId}).then(returnResponseData).catch(throwError);
}

export function postLinks(links) {
    return axios.post(`/api/money/links`, links).then(returnResponseData).catch(throwError);
}

export function setInnClientId(inn, clientId) {
    return axios.post('/api/money/inn/setClientId', {inn, clientId}).then(returnResponseData).catch(throwError);
}

export function setInnIgnored(inn) {
    return axios.post('/api/money/inn/setIgnored', {inn}).then(returnResponseData).catch(throwError);
}

export function getBillStates() {
    return axios.get('/api/money/bills/states').then(returnResponseData).catch(throwError);
}

export function fetchWagesParamsLast() {
    return axios.get(`/api/wages/params/last`).then(returnResponseData).catch(throwError);
}
export function fetchWagesParamsAll() {
    return axios.get(`/api/wages/params/all`).then(returnResponseData).catch(throwError);
}
export function postWagesParams(data) {
    return axios.post('/api/wages/params', data).then(returnResponseData).catch(throwError);
}
export function deleteNextWagesParams() {
    return axios.delete('/api/wages/params/next').then(returnResponseData).catch(throwError);
}

export function getWagesEngineersTablePageData(startDate, endDate) {
    return axios.get(`/api/wages/engineers?start=${startDate}&end=${endDate}`).then(returnResponseData).catch(throwError);
}

export function getCalendarData(startDate, endDate, engineerId) {
    return axios.get(`/api/wages/calendar/cached?startDate=${startDate}&endDate=${endDate}&engineerId=${engineerId}`).then(returnResponseData).catch(throwError);
}
export function getCalendarDayActions(engineerId, days) {
    return axios.post(`/api/wages/calendar/actions`, {engineerId, days}).then(returnResponseData).catch(throwError);
}
export function createCalendarDayStatusOverride(data) {
    return axios.post(`/api/wages/calendar/overrides`, data).then(returnResponseData).catch(throwError);
}
export function removeCalendarDayStatusOverride(docId, docRev) {
    return axios.delete(`/api/wages/calendar/overrides/${docId}?rev=${docRev}`).then(returnResponseData).catch(throwError);
}

export function scanTransportInbox() {
    return axios.post(`/api/transport/scan`).then(returnResponseData).catch(throwError);
}
export function fetchTransportDocs(startDate, endDate) {
    return axios.get(`/api/transport/emails?startDate=${startDate}&endDate=${endDate}`).then(returnResponseData).catch(throwError);
}
export function deleteTransportDocAndTrashEmail(docId, docRev, emailUID) {
    return axios.delete(`/api/transport/emails/${emailUID}?docId=${docId}&docRev=${docRev}`).then(returnResponseData).catch(throwError);
}

export function fetchTransportTrips(startDate, endDate) {
    return axios.get(`/api/transport/trips?startDate=${startDate}&endDate=${endDate}`).then(returnResponseData).catch(throwError);
}

export function fetchTransportAddresses() {
    return axios.get('/api/transport/addresses').then(returnResponseData).catch(throwError);
}
export function addTransportAddress(email) {
    return axios.post(`/api/transport/addresses?action=add&email=${email}`).then(returnResponseData).catch(throwError);
}
export function confirmTransportAddress(email, token) {
    return axios.post(`/api/transport/addresses?action=confirm&email=${email}&token=${token}`).then(returnResponseData).catch(throwError);
}
export function removeTransportAddress(email) {
    return axios.delete(`/api/transport/addresses?email=${email}`).then(returnResponseData).catch(throwError);
}

export function fetchSpecialDocsList() {
    return axios.get(`/api/specialDocs`).then(returnResponseData).catch(throwError);
}
export function fetchSpecialDoc(docId) {
    return axios.get(`/api/specialDocs/${docId}`).then(returnResponseData).catch(throwError);
}
export function postSpecialDoc(docId, doc) {
    return axios.post(`/api/specialDocs/${docId}`, doc).then(returnResponseData).catch(throwError);
}