import React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Input, Loading, Select, Toast} from "spectre-react";
import {SingleSelect} from "../shared/Select";
import BillEditModal from "./BillEditModal";
import {fetchBills, deleteBill, fetchCSV} from "../../api";
import {pouch} from "../../actions";
import rubFmt from "../shared/summaFormatter";
import {BILL_STATE, BILL_STATE_LABEL, formatDate1, formatDate2} from "../shared/sharedImport";


const BILL_COLOR_CLASS = {
    [BILL_STATE.ERROR]: 'text-primary',
    [BILL_STATE.ISSUED]: 'text-error',
    [BILL_STATE.PART_PAY]: 'text-warning',
    [BILL_STATE.FULL_PAY]: 'text-success',
};


class BillsList extends React.PureComponent {
    state = {
        billsLoadState: 0, billsLoadError: undefined,
        bills: null,

        clientsLoadState: 0, clientsLoadError: undefined,  // 0 = not loaded, 1 = loading, 2 = loaded
        clients: null,

        filters: {client: null, NUM_DOC: '', DATE: '', SUMMA: '', state: '', sortType: 'smart'},

        displayedNum: 30,
    };

    SORT_FUNCTIONS = {
        'smart': (a, b) => a.state.localeCompare(b.state),
        'dateAsc': (a, b) => a.DATE - b.DATE,
        'dateDesc': (a, b) => b.DATE - a.DATE,
    };

    fetchBills = async () => {
        this.setState({
            billsLoadState: 1, clientsLoadState: 0,
            billsLoadError: undefined, clientsLoadError: undefined,
            bills: null, clients: null
        });

        try {
            const docs = await fetchBills();

            const bills = docs.map(({_id, numDoc: NUM_DOC, date: DATE, summa: SUMMA, clientId, state, createdDelay, fullPayDelay}) => {
                return {_id, NUM_DOC, DATE: new Date(DATE), SUMMA, clientId, state, createdDelay, fullPayDelay};
            });

            this.setState({billsLoadState: 2, bills}, this.fetchClients);
        } catch (error) {
            console.error(error);
            alert(error.message);
            this.setState({billsLoadState: 0, billsLoadError: error.message});
        }
    };

    async fetchClients() {
        this.setState({clientsLoadState: 1, clientsLoadError: undefined});

        try {
            let clients = (await pouch.query('clients/names')).rows;
            clients = clients.map(({key, value}) => [key, {_id: key, label: value, name: value}]); // 'label' is for SingleSelect without itemToString
            clients = new Map(clients);

            const clientIds = new Set();

            const bills = [...this.state.bills];
            for (const bill of bills) {
                clientIds.add(bill.clientId);
                bill.client = clients.get(bill.clientId);
            }

            const selectItems = Array.from(clientIds)
                .map(clientId => clients.get(clientId))
                .sort((a, b) => a.label.localeCompare(b.label));

            this.setState({clientsLoadState: 2, bills, clients: selectItems});
        } catch (error) {
            console.error(error);
            alert(error.message);
            this.setState({clientsLoadState: 0, clientsLoadError: error.message});
        }
    }

    componentDidMount() {
        // [document.body.scrollHeight - window.innerHeight, window.scrollY]
        window.addEventListener('scroll', this.onScroll);

        this.fetchBills();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        // TODO: check if this is really necessary
        if (!this.state.bills)
            return;

        if (
            document.body.scrollHeight - window.innerHeight - 400 < window.scrollY &&
            this.state.displayedNum < this.state.bills.length
        )
            this.setState({displayedNum: this.state.displayedNum + 30});
    };

    onFilterChange = (ev) => {
        const filters = {...this.state.filters, [ev.target.name.slice(8)]: ev.target.value};
        this.setState({filters, displayedNum: 30});
    };

    filter(bills, filters) {
        if (filters.client)
            bills = bills.filter(bill => bill.client._id === filters.client._id);
        if (filters.state)
            bills = bills.filter(item => item.state === filters.state);
        if (filters.NUM_DOC) {
            const re = new RegExp(filters.NUM_DOC, 'i');
            bills = bills.filter(item => re.test(item.NUM_DOC));
        }
        if (filters.DATE) {
            const re = new RegExp(filters.DATE, 'i');
            bills = bills.filter(item => re.test(formatDate2(item.DATE)));
        }
        if (filters.SUMMA) {
            const re = new RegExp(filters.SUMMA, 'i');
            bills = bills.filter(item => re.test(item.SUMMA.toString()));
        }
        return bills;
    }

    // ===========

    selectClient = (ev) => {
        const _id = ev.target.dataset.id;
        const client = this.state.clients.find(c => c._id === _id);
        this.onFilterChange({target: {name: 'filters.client', value: client}});
    };

    downloadCSV = () => {
        let output = "data:text/csv;charset=utf-8," + "Клиент;№ счета;Дата;Сумма;Статус\n";
        for (const {NUM_DOC, DATE, SUMMA, client, state} of this.state.bills)
            output += `${client.name};${NUM_DOC};${formatDate1(DATE)};${SUMMA};${BILL_STATE_LABEL[state]}\n`;
        fetchCSV(output, `bills-${new Date().toISOString()}.csv`);
    };

    deleteBill = (ev) => {
        const msg = "Счет будет удален, восстановить будет нельзя. Никакие платежи удалены не будут. Продолжаем?";

        if (window.confirm(msg))
            deleteBill(ev.currentTarget.dataset.id)
                .then(this.fetchBills)
                .catch(error => alert(error.message));
    };

    // ===========

    render() {
        if (this.state.billsLoadState !== 2)
            return <Loading large />;

        if (this.state.billsLoadError)
            return <Toast error>{this.state.billsLoadError}</Toast>;

        const {filters, clients, displayedNum} = this.state;
        const bills = this.filter(this.state.bills.slice(), filters)
            .sort(this.SORT_FUNCTIONS[filters.sortType])
            .slice(0, displayedNum);

        const isLoadingClients = (this.state.clientsLoadState === 1);

        return <Table filters={filters} bills={bills} clients={clients}
                      isLoadingClients={isLoadingClients} selectClient={this.selectClient} onFilterChange={this.onFilterChange}
                      reloadData={this.fetchBills} downloadCSV={this.downloadCSV} deleteBill={this.deleteBill} />;
    }
}

class Table extends React.PureComponent {
    static propTypes = {
        bills: PropTypes.array,
        clients: PropTypes.array,
        reloadData: PropTypes.func,
        deleteBill: PropTypes.func,
        downloadCSV: PropTypes.func,
        filters: PropTypes.object,
        selectClient: PropTypes.func,
        onFilterChange: PropTypes.func,
        isLoadingClients: PropTypes.bool,
    };

    editModal = React.createRef();
    openEditModal = (ev) => this.editModal.current.open(ev.currentTarget.dataset.id);

    renderRow = ({_id, NUM_DOC, DATE, SUMMA, state, client, createdDelay, fullPayDelay}) => (
        <div className="table-row" key={_id}>
            <div>
                {this.props.isLoadingClients && <Loading />}
                {client &&
                    <span data-id={client._id} onClick={this.props.selectClient}>
                        {client.name}
                    </span>
                }
            </div>
            <div>{NUM_DOC}</div>
            <div>
                {formatDate2(DATE)}
                <span className="text-gray float-right">{createdDelay}</span>
            </div>
            <div className="text-right">{rubFmt(SUMMA)}</div>
            <div>
                <span className={BILL_COLOR_CLASS[state]}>{BILL_STATE_LABEL[state]}</span>
                <Button primary small className="float-right" data-id={_id} onClick={this.props.deleteBill}>
                    <Icon icon="cross"/>
                </Button>
                <Button primary small className="mr-1 float-right" data-id={_id} onClick={this.openEditModal}>
                    <Icon icon="edit"/> Edit
                </Button>
                <span className="text-gray float-right mr-2">{fullPayDelay}</span>
            </div>
        </div>
    );

    render() {
        const {bills, filters, clients, reloadData, onFilterChange, downloadCSV} = this.props;

        return (
            <div id="bills-list">
                <BillEditModal ref={this.editModal} onSubmit={reloadData}/>

                <div id="list-actions">
                    <Button primary small className="mr-2" onClick={downloadCSV}>
                        <Icon icon="download"/> CSV
                    </Button>

                    <Button primary small className="mr-2" onClick={reloadData}>
                        <Icon icon="refresh"/> Обновить
                    </Button>

                    <span className="chip">
                        Сортировать по <Select small name="filters.sortType" value={filters.sortType} onChange={onFilterChange}>
                        <option value="smart">smart</option>
                        <option value="dateAsc">датам по возрастанию</option>
                        <option value="dateDesc">датам по убыванию</option>
                    </Select>
                    </span>
                </div>

                <div className="custom-table">
                    <div className="table-row table-head" key={0}>
                        <div>
                            <div>Клиент</div>
                            <SingleSelect items={clients} selectedItem={filters.client} onChange={onFilterChange}
                                          name="filters.client" className="form-input input-sm"
                                          clearable={true} searchable={true}/>
                        </div>
                        <div>
                            <div>№ счета</div>
                            <Input small type="text" name="filters.NUM_DOC" value={filters.NUM_DOC} onChange={onFilterChange}/>
                        </div>
                        <div>
                            <div>Дата</div>
                            <Input small type="text" name="filters.DATE" value={filters.DATE} onChange={onFilterChange}/>
                        </div>
                        <div>
                            <div>Сумма</div>
                            <Input small type="text" name="filters.SUMMA" value={filters.SUMMA} onChange={onFilterChange}/>
                        </div>
                        <div>
                            <div>Статус</div>
                            <Select small name="filters.state" value={filters.state} onChange={onFilterChange}>
                                <option value="">Любой</option>
                                <option key={BILL_STATE.ERROR} value={BILL_STATE.ERROR}>{BILL_STATE_LABEL.ERROR}</option>
                                <option key={BILL_STATE.ISSUED} value={BILL_STATE.ISSUED}>{BILL_STATE_LABEL.ISSUED}</option>
                                <option key={BILL_STATE.PART_PAY} value={BILL_STATE.PART_PAY}>{BILL_STATE_LABEL.PART_PAY}</option>
                                <option key={BILL_STATE.FULL_PAY} value={BILL_STATE.FULL_PAY}>{BILL_STATE_LABEL.FULL_PAY}</option>
                            </Select>
                        </div>
                    </div>
                    {bills.map(this.renderRow)}
                </div>
            </div>
        )
    }
}

export default BillsList;